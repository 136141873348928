import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { verifyPartnersAccessToken } from "../services/verifyAccessToken";
import LoadingPage from "./LoadingPage";

const VerifyPartner = ({ children }) => {
  
  let credentials = JSON.parse(localStorage?.getItem("techcsrf"));

  const [isAuthenticate, setIsAuthenticate] = useState(false);

  const handleAccessVerification = async (accessToken) => {
    try {
      let response = await verifyPartnersAccessToken(accessToken);
      console.log(response);
      if (response.status === 200) {
        setIsAuthenticate(true);
      } else {
        setIsAuthenticate(false);
      }
    } catch (err) {
      setIsAuthenticate(false);
    }
  };

  useEffect(() => {
    if (credentials === undefined || credentials === null) {
        setIsAuthenticate(true)
    } else {
      handleAccessVerification(credentials?.accessToken);
    }
  }, []);

  return <>{isAuthenticate ? children : null}</>;
};

export default VerifyPartner;
