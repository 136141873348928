import React from 'react';
import HeroSection from '../../components/HeroSection/HeroSection';
import CourseCard from '../../components/CourseCard/CourseCard';

const MicrosoftSecurity = () => {
    const title = "Microsoft Security"
    const crumbs = [
        { name: "Our Services", link: "/our-services" },
        { name: "Microsoft Security" }
    ]

    return (
        <div className="">
            <HeroSection title={title} crumbs={crumbs} />

            <div className="position-relative d-none d-lg-flex">
                <div className="d-flex text-white justify-content-center mt-3 position-absolute partnersSearch">
                    <div className="d-flex justify-content-between bg-white newsletterInputBox shadow w-100">
                        <input type="email" className="ms-2 w-75 color-blue py-2 ps-3 newsletterInput" placeholder="Search" />
                        <button className="newsletterSubmit px-3 t-12 d-flex align-items-center justify-content-center">
                            <i className="submitTextIcon text-white  bi bi-search"></i>
                        </button>
                    </div>
                    <button className="newsletterSubmit shadow bg-white mx-2 px-3 t-12 d-flex align-items-center justify-content-center">
                        <i className="filterIcon color-blue bi bi-filter"></i>
                    </button>
                </div>
            </div>

            <div className="main-container">
                <div className="row my-2">
                    <p className="h2">Course List</p>
                </div>
                <div className="row">
                    {
                        Array.from({ length: 10 }, (_, i) => i + 1)?.map(el => <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <CourseCard index={el} />
                        </div>)
                    }
                </div>

                <div className='d-flex justify-content-center'>
                    <button className="service_btn color-blue bg-white border px-3 py-2 mt-2 border-primary"><i className=" service_btn_icon bi bi-plus me-2" />Load More</button>
                </div>
            </div>

        </ div>
    );
};

export default MicrosoftSecurity;