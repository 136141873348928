import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { verifyAccessToken } from "../services/verifyAccessToken";
import LoadingPage from "./LoadingPage";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let credentials = JSON.parse(localStorage?.getItem("techcsrf"));

  const [isAuthenticate, setIsAuthenticate] = useState(false);

  const handleAccessVerification = async (accessToken) => {
    try {
      let response = await verifyAccessToken(accessToken);
      if (response.status === 200) {
        setIsAuthenticate(true);
      } else {
        navigate("/signin", { replace: true });
      }
    } catch (err) {
      navigate("/signin", { replace: true });
    }
  };

  useEffect(() => {
    if (credentials === undefined || credentials === null) {
      navigate("/signin", { replace: true });
    } else {
      handleAccessVerification(credentials?.accessToken);
    }
  }, []);

  return <>{isAuthenticate ? children : <LoadingPage />}</>;
};

export default ProtectedRoute;
