import React from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Header from '../Header/Header';
import "./HeroSection.css"

const HeroSection = ({ title, crumbs }) => {

    return (
        <div className="heroSection">
            <Header />
            {window.location.pathname === "/profile" ? null : < Breadcrumb title={title} crumbs={crumbs} />}
        </div>
    );
};

export default HeroSection;