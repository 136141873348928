import { apiUrls } from "../../../api/apiUrls";
import http from "../../../services/axios/axiosInstance";
import {
  handleApiErr,
  handleLoding,
  handlePartnerCourse,
} from "./partnerCourseSlice";
import { toast } from "react-toastify";

export const readPartnerCourse = (partnerCourseId) => async (dispatch) => {
  try {
    dispatch(handleLoding("loading"));
    let config = {
      method: "get",
      url: `${apiUrls.readPartnerCourse}?partnerCourseId=${partnerCourseId}`,
    };
    let response = await http(config);
    console.log(response.data);
    dispatch(handlePartnerCourse(response?.data?.result));
    dispatch(handleLoding("idle"));
  } catch (err) {
    dispatch(handleApiErr("Error While Fetching Partner Course"));
  }
};

export const partnerCourseApplication =
  (partnerApplicationData) => async (dispatch) => {
    try {
      dispatch(handleLoding("loading"));
      let localStorageData = JSON.parse(localStorage.getItem("techcsrf"))
      let accessToken = localStorageData.accessToken
      let config = {
        method: "post",
        url: apiUrls.partnerCourseApplication,
        headers: {
          "authorization": `Bearer ${accessToken}`
      },
        data: partnerApplicationData,
      };

      let response = await http(config);
      if (response.status=== 200) {
        toast("Application Submitted Sucessfully");
      }
      dispatch(handleLoding("idle"));
    } catch (err) {
      console.log(err)
      dispatch(handleApiErr("Error While Fetching Partner Course"));
      toast("Error While Submitting Application");
    }
  };
