import { Navigate, useLocation } from "react-router-dom";

const AuthRedirectRoute = ({ children }) => {
  const location = useLocation();
  let credentials = JSON.parse(localStorage?.getItem("techcsrf"));
  
  return (
    <>
      {credentials === undefined || credentials === null ? (
        children
      ) : (
        <Navigate to="/" state={{ from: location }} replace />
      )}
    </>
  );
};

export default AuthRedirectRoute;
