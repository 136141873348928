import * as yup from "yup";

export const SignUpStepOneSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export const SignUpStepTwoSchema = yup.object({
  userEmail: yup.string().email().required(),
  userPassword: yup.string().required(),
  userConfirmPassword: yup.string().oneOf([yup.ref('userPassword'), null], 'Passwords must match'),
});

export const SignUpStepThreeSchema = yup.object({
  organizationName: yup.string().required(),
  country: yup.string().required(),
});

export const signUpStepOneValidation = async (formData) => {
  return new Promise(async (resolve, reject) => {
    SignUpStepOneSchema.validate(formData, { abortEarly: false })
      .then(() => {
        resolve(formData);
      })
      .catch((err) => {
        const errors = err.inner.reduce((acc, error) => {
          return {
            ...acc,
            [error.path]: true,
          };
        }, {});

        reject(errors);
      });
  });
};

export const signUpStepTwoValidation = (formData) => {
  return new Promise((resolve, reject) => {
    SignUpStepTwoSchema.validate(formData, { abortEarly: false })
      .then(() => {
        resolve(formData);
      })
      .catch((err) => {
        const errors = err.inner.reduce((acc, error) => {
          return {
            ...acc,
            [error.path]: true,
          };
        }, {});

        reject(errors);
      });
  });
};

export const signUpStepThreeValidation = (formData) => {
  return new Promise((resolve, reject) => {
    SignUpStepThreeSchema.validate(formData, { abortEarly: false })
    .then(() => {
      resolve(formData);
    })
    .catch((err) => {
      const errors = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: true,
        };
      }, {});

      reject(errors);
    });
  });
};
