import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { verifyAccessToken } from "../services/verifyAccessToken";
import LoadingPage from "./LoadingPage";

const PaymentProtectedRoute = ({ children }) => {
  let credentials = JSON.parse(localStorage?.getItem("techcsrf"));

  const [isAuthenticate, setIsAuthenticate] = useState("verifying");

  const handleAccessVerification = async (accessToken) => {
    try {
      let response = await verifyAccessToken(accessToken);
      if (response.status === 200) {
        setIsAuthenticate("isLoggedIn");
      } else {
        setIsAuthenticate("needLogIn");
      }
    } catch (err) {
      setIsAuthenticate("needLogIn");
    }
  };

  useEffect(() => {
    if (credentials === undefined || credentials === null) {
      setIsAuthenticate("needLogIn");
    } else {
      handleAccessVerification(credentials?.accessToken);
    }
  }, []);

  return (
    <>
      {isAuthenticate === "isLoggedIn" ? (
        children
      ) : isAuthenticate === "verifying" ? (
        <LoadingPage />
      ) : (
        <>
          <div style={{textAlign:"center", fontWeight:"600", margin:"30px"}}>You Need To Login For Payment</div>
        </>
      )}
    </>
  );
};

export default PaymentProtectedRoute;
