import Accordion from "react-bootstrap/Accordion";
import "./AppAccordian.css";

const AppAccordian = ({ accHed, accBdy }) => {
  console.log( accBdy);
  console.log(accHed)
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{accHed}</Accordion.Header>
          {typeof accBdy === "object" ? (
            <Accordion.Body>
              {accBdy.map((ele) => {
                return (
                  <>
                    <p>{ele}</p>
                  </>
                );
              })}
            </Accordion.Body>
          ) : (
            <Accordion.Body>{accBdy}</Accordion.Body>
          )}
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default AppAccordian;
