import {authApiUrls} from "../api/authApiUrls"

export const getMicrosoftUrl = (userType) => {
  
  const rootUrl =
    authApiUrls.microsoftAuthRoot;

  const options = {
    client_id: "f07c8171-82cc-4601-9930-dc843b39b1fa",
    response_type: "code",
    redirect_uri: authApiUrls.microsoftAuthRedirectSignUpUri,
    response_mode: "query",
    scope: "user.read",
  };

  let url = `${rootUrl}?client_id=${options.client_id}&response_type=${options.response_type}&redirect_uri=${options.redirect_uri}&response_mode=${options.response_mode}&scope=${options.scope}&state=${userType}`;

  return url;
};

export const getMicrosoftSignInUrl = (userType) => {
  const rootUrl =
  authApiUrls.microsoftAuthRoot;

  const options = {
    client_id: "f07c8171-82cc-4601-9930-dc843b39b1fa",
    response_type: "code",
    redirect_uri: authApiUrls.microsoftAuthRedirectSignInUri,
    response_mode: "query",
    scope: "user.read",
  };

  let url = `${rootUrl}?client_id=${options.client_id}&response_type=${options.response_type}&redirect_uri=${options.redirect_uri}&response_mode=${options.response_mode}&scope=${options.scope}&state=${userType}`;

  return url;
};
