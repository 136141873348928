import "./CourseCarousel.css";

const CourseCarousel = ({ corPosProp, children }) => {
  return (
    <>
      <div className="corousel">
        <div
          className="innerCorousel"
          style={{ transform: `translateX(-${corPosProp}%)` }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default CourseCarousel;
