import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { verifyPartnersAccessToken } from "../services/verifyAccessToken";
import LoadingPage from "./LoadingPage";

const PartnerProtectedRoute = ({ children }) => {
 
  const navigate = useNavigate();
  let credentials = JSON.parse(localStorage?.getItem("techcsrf"));

  const [isAuthenticate, setIsAuthenticate] = useState(false);

  const handleAccessVerification = async (accessToken) => {
    try {
      let response = await verifyPartnersAccessToken(accessToken);
      console.log(response)
      if (response.status === 200) {
        setIsAuthenticate(true);
      }else{
        navigate("/partners")
      }
    } catch (err) {
        navigate("/partners")
    }
  };

  useEffect(() => {
    if (credentials === undefined || credentials === null) {
      navigate("/signin", { replace: true });
    } else {
      handleAccessVerification(credentials?.accessToken);
    }
  }, []);

  return <>{isAuthenticate ? children : <LoadingPage />}</>;
};

export default PartnerProtectedRoute;
