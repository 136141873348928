import { toast } from "react-toastify";
import { apiUrls } from "../../../api/apiUrls";
import http from "../../../services/axios/axiosInstance";
import { handleApiError, handleLoding, handleIsAuth } from "./authSlice";

export const manualSignUp =
  (signupStepOneData, signupStepTwoData, signupStepThreeData, userCategory) =>
  async (dispatch) => {
    try {
      dispatch(handleLoding("loading"));
      let data = {
        userEmail: signupStepTwoData?.userEmail,
        userPassword: signupStepTwoData?.userPassword,
        userType: userCategory?.name,
        country: signupStepThreeData?.country,
        organizationName: signupStepThreeData?.organizationName,
        firstName: signupStepOneData?.firstName,
        lastName: signupStepOneData?.lastName,
      };
      let config = {
        method: "post",
        url: apiUrls.manualSignUp,
        data,
      };
      await http(config);
      //  let apiResponse =
      // let authObj = {
      //   accessToken: apiResponse?.data?.accessToken,
      //   email: apiResponse?.data?.email,
      //   userType: apiResponse?.data?.userType,
      //   userId: apiResponse?.data?.userId,
      // };
      // console.log();
      // localStorage.setItem("techcsrf", JSON.stringify(authObj));
      // dispatch(handleLoding("idle"));
      // dispatch(handleIsAuth(true));

      toast.success(
        "Successfully Registered Please Verify Email Using Link Sent On Email",
        { autoClose: 3000 }
      );
      dispatch(handleLoding("idle"));
    } catch (err) {
      console.log(err);
      handleApiError(err.response.data.error);
      // console.log(err.response.data.error, "aaaaa");
      toast.success(err.response.data.error.message, { autoClose: 3000 });
      // toast.success("Error While Registering", { autoClose: 3000 });
      dispatch(handleLoding("idle"));
    }
  };

export const manualSignIn =
  (userEmail, userPassword, userType) => async (disaptch) => {
    try {
      disaptch(handleLoding("loading"));
      let data = {
        userEmail,
        userPassword,
        userType,
      };
      let config = {
        method: "post",
        url: apiUrls.manualSignIn,
        data,
      };
      let apiResponse = await http(config);
      let authObj = {
        accessToken: apiResponse?.data?.accessToken,
        email: apiResponse?.data?.email,
        userType: apiResponse?.data?.userType,
        userId: apiResponse?.data?.userId,
      };
      console.log(apiResponse);
      localStorage.setItem("techcsrf", JSON.stringify(authObj));
      disaptch(handleLoding("idle"));
      disaptch(handleIsAuth(true));
      toast.success("Sucessfully Logged In", { autoClose: 3000 });
    } catch (err) {
      console.log(err);
      disaptch(handleLoding("idle"));
      toast.success(err.response.data.error.message, { autoClose: 3000 });
    }
  };

export const verifyIsPartner = () => async (dispatch) => {
  try {
    let localStorageData = JSON.parse(localStorage.getItem("techcsrf"));
    let accessToken = localStorageData.accessToken;
    let data = {
      accessToken,
    };
    let config = {
      method: "post",
      url: "auth/verifyPartnersAccessToken",
      data,
    };
    let response = await http(config);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const resetUserPassword = (userPassword) => async (dispatch) => {
  try {
    let localStorageData = JSON.parse(localStorage.getItem("techcsrf"));
    let accessToken = localStorageData.accessToken;
    let data = {
      userPassword,
    };
    let config = {
      method: "post",
      url: apiUrls.resetUserPassword,
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      data,
    };
    let response = await http(config);
    if (response.status === 200) {
      window.location.href = "/";
      toast.success("Sucessfully Updated", { autoClose: 3000 });
      localStorage.removeItem("techcsrf");
    }
  } catch (err) {
    toast.error("Something Went Wrong");
  }
};

export const forgotUserPassword =
  (userPassword, accessToken) => async (dispatch) => {
    try {
      let data = {
        userPassword,
      };
      let config = {
        method: "post",
        url: apiUrls.resetUserPassword,
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data,
      };
      let response = await http(config);
      if (response.status === 200) {
        toast.success("Sucessfully Updated Password Please Login");
        window.location.href = "/";
      }
    } catch (err) {
      toast.error("Something Went Wrong");
    }
  };

export const sendForgotPasswordLink = (userEmail) => async (dispatch) => {
  try {
    dispatch(handleLoding("loading"));
    let data = {
      userEmail,
    };
    let config = {
      method: "post",
      url: apiUrls.forgetUserPassword,
      data,
    };
    let response = await http(config);
    if (response.status === 200) {
      toast.success("Sucessfully Sent Password Reset Link");
    }
    dispatch(handleLoding("idle"));
  } catch (err) {
    toast.error("Something Went Wrong");
    dispatch(handleLoding("idle"));
  }
};
