import DeleteIc from "../../assets/svgs/DeleteIc";
import "./AppTable.css";
import CoursePurchased from "./AppTableComponents/CoursePurchased/CoursePurchased";
import Quantity from "./AppTableComponents/Quantity/Quantity";
import Schedule from "./AppTableComponents/Schedule/Schedule";
import { destroyCourseCart } from "../../redux/slices/courseCart/courseCartActions";
import { useDispatch } from "react-redux";
import useLocalStorge from "../../hooks/useLocalStorage";
import { handleActiveCourse } from "../../redux/slices/course/courseSlice";
import { Link, useNavigate } from "react-router-dom";

const AppTable = ({ tableName, cartCourses }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const destroyCourseFromCart = (courseId) => {
    dispatch(destroyCourseCart(courseId));
  };

  const activeCourse = (courseId, courseType) => {
    // dispatch(handleActiveCourse(cardData));
    navigate(`/our-services/course/course-description?courseId=${courseId}&courseType=${courseType}`)
  };
  return (
    <>
      <div className="app-table-con">
        <table className="app-table">
          <tr className="table-hd">
            <th className="app-table-hd">COURSE NAME</th>
            <th className="app-table-hd">QUANTITY</th>
            <th className="app-table-hd">PRICE</th>

            {tableName === "cart" ? (
              <th className="app-table-hd">REMOVE</th>
            ) : null}
            {/* {tableName === "studyCalender" ? (
              <th className="app-table-hd">DURATION</th>
            ) : null}
            {tableName === "studyCalender" ? (
              <th className="app-table-hd col-ed">SCHEDULE</th>
            ) : null} */}
          </tr>
          {cartCourses.length > 0
            ? cartCourses?.map((ele) => {
                console.log(ele);
                return (
                  <>
                    {/* <Link
                      to={
                        `/our-services/course/course-description?courseId=${ele.courseId}&courseType=${ele.course_type}`
                      } */}
                      {/* // onClick={() => activeCourse(ele)} */}
                       
                     
                    {/* > */}
                      <tr  className="table-dt">
                        <td onClick={() => tableName === "studyCalender" ? activeCourse(ele?.courseId, ele.course_type) : null} className="app-table-dt col-st">
                          <CoursePurchased courseName={ele.course_name} />
                        </td>
                        <td className="app-table-dt">
                          <Quantity
                            courseId={ele.courseId}
                            courseQuantity={ele.quantity}
                            courseData={ele}
                            tableName={tableName}
                          />
                        </td>

                        <td className="app-table-dt col-price col-tx-ct">
                        {ele.course_mrp - ((ele?.course_mrp/100) * ele?.course_discount).toFixed(2)}
                        </td>
                        {tableName === "cart" ? (
                          <td
                            onClick={() => destroyCourseFromCart(ele.id)}
                            className="app-table-dt col-tx-ct"
                          >
                            <DeleteIc />
                          </td>
                        ) : null}
                        {tableName === "studyCalender"
                          ? // <td className="app-table-dt col-tx-ct">6 Months</td>
                            null
                          : null}
                        {tableName === "studyCalender"
                          ? // <td className="app-table-dt col-tx-ct col-ed">
                            //   <Schedule />
                            // </td>
                            null
                          : null}
                      </tr>
                    {/* </Link> */}
                  </>
                );
              })
            : null}
        </table>
      </div>
    </>
  );
};

export default AppTable;
