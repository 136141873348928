import translate from "translate";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    padding: 0,
    border: "none",
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
    zIndex: 9999,
    height: "100vh",
    width: "100vw",
  },
};
export const customStylesForDeleteCss = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "22vw",
    padding: 0,
    border: "none",
    textAlign: "center",
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
    zIndex: 9999,
    height: "100vh",
    width: "100vw",
  },
};

export const changeLanguageText = async (str, lang) =>
  await translate(str, lang);


// export const changeLangauge = async (str, lang) =>  {
//   let langRes = await translate(str, lang)
//   return langRes
//   console.log(langRes, "langRes")
// }