import {
  handleCartCourse,
  handleCartCourseErr,
  handleLoading,
  handleCartCourseNo,
} from "./courseCartSlice";
import http from "../../../services/axios/axiosInstance";
import httpAuth from "../../../services/axios/axiosAuthInstance";
import { useNavigate } from "react-router-dom";

import { apiUrls } from "../../../api/apiUrls";
import { toast } from "react-toastify";

export const addCourseCart = (userId, courseId) => async (dispatch) => {
  try {
    dispatch(handleLoading("loading"));
    let data = {
      userId,
      courseId,
    };
    let config = {
      method: "post",
      url: `${apiUrls.courseCart}`,
      data,
    };
    await httpAuth(config);
    dispatch(readCourseCart(userId));
    dispatch(handleLoading("idle"));
    toast.success("Sucessfully Added To Cart", { autoClose: 3000 });
  } catch (err) {
    console.log(err);
    dispatch(handleCartCourseErr("Error While Adding Cart Courses"));
    toast.error("Error While Adding To Cart", { autoClose: 3000 });
  }
};

export const readCourseCart = () => async (dispatch) => {
  try {
    let cartData = localStorage.getItem("technoCart");
    if (cartData) {
      let parsedCartData = JSON.parse(cartData);
      dispatch(handleCartCourse(parsedCartData));
      dispatch(handleCartCourseNo(parsedCartData.length));
      dispatch(handleLoading("idle"));
    }
  } catch (err) {
    toast.error("Error While Fetching Cart Courses", { autoClose: 3000 });
  }
};

export const deleteCourseCart = (courseData) => async (dispatch) => {
  try {
    const courseCartLocal = JSON.parse(localStorage.getItem("technoCart"));

    if (courseCartLocal) {
      let courseCart = courseCartLocal;
      let flag = 0;
      let courseCartDataObj = { ...courseData };
      courseCart.forEach((ele, idx) => {
        if (ele?.id === courseCartDataObj?.id) {
          if (ele.quantity > 1) {
            courseCart[idx].quantity = courseCart[idx].quantity - 1;
          } else {
            flag = 1;
          }
        }
      });

      if (flag === 1) {
        courseCart = courseCartLocal.filter(
          (ele) => ele.id !== courseCartDataObj.id
        );
      }
      toast.success("Sucessfully Removed From Cart", { autoClose: 3000 });
      localStorage.setItem("technoCart", JSON.stringify(courseCart));
      dispatch(readCourseCart());
    }
  } catch (err) {
    console.log(err);
    toast.error("Error While Adding To Cart", { autoClose: 3000 });
  }
};

export const destroyCourseCart = (courseId) => async (dispatch) => {
  try {
    const courseCartLocal = localStorage.getItem("technoCart");
    console.log(courseId);
    if (courseCartLocal) {
      let courseCart = JSON.parse(courseCartLocal);
      let filteredCourseCart = courseCart.filter((ele) => {
        return ele.id !== courseId;
      });

      localStorage.setItem("technoCart", JSON.stringify(filteredCourseCart));
      dispatch(readCourseCart());
    }

    toast.success("Sucessfully Removed From Cart", { autoClose: 3000 });
  } catch (err) {
    dispatch(handleCartCourseErr("Error While Deleting Cart Courses"));
    toast.error("Error While Removing From Cart", { autoClose: 3000 });
  }
};

export const addCourseToCartLocalStorage = (courseData) => async (dispatch) => {
  try {
    dispatch(handleLoading("loading"));
    const courseCart = localStorage.getItem("technoCart");
    let courseCartDataObj = { ...courseData };
    if (!courseCart) {
      courseCartDataObj.quantity = 1;
      let cartData = [courseCartDataObj];
      localStorage.setItem("technoCart", JSON.stringify(cartData));
    } else {
      let parsedCourseCart = JSON.parse(courseCart);
      let flag = 0;
      parsedCourseCart.forEach((ele) => {
        if (ele.id === courseCartDataObj.id) {
          ele.quantity = ele.quantity + 1;
          flag = 1;
        }
      });

      if (flag === 0) {
        courseCartDataObj.quantity = 1;
        parsedCourseCart.push(courseCartDataObj);
      }

      toast.success("Sucessfully Added To Cart", { autoClose: 3000 });
      localStorage.setItem("technoCart", JSON.stringify(parsedCourseCart));
      dispatch(readCourseCart());
    }
    dispatch(handleLoading("idle"));
  } catch (err) {
    console.log(err);
    toast.error("Error While Adding To Cart", { autoClose: 3000 });
    dispatch(handleLoading("idle"));
  }
};
