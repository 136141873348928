const PromIc = () => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.58436 18.4502L2.60541 16.4712C1.7982 15.664 1.7982 14.336 2.60541 13.5288L4.58436 11.5498C4.92287 11.2113 5.19628 10.5473 5.19628 10.0786V7.27938C5.19628 6.13366 6.13369 5.19628 7.27941 5.19628H10.0786C10.5473 5.19628 11.2113 4.92291 11.5498 4.5844L13.5288 2.60541C14.336 1.7982 15.664 1.7982 16.4712 2.60541L18.4502 4.5844C18.7887 4.92291 19.4527 5.19628 19.9214 5.19628H22.7206C23.8663 5.19628 24.8037 6.13366 24.8037 7.27938V10.0786C24.8037 10.5473 25.0771 11.2113 25.4156 11.5498L27.3946 13.5288C28.2018 14.336 28.2018 15.664 27.3946 16.4712L25.4156 18.4502C25.0771 18.7887 24.8037 19.4527 24.8037 19.9214V22.7205C24.8037 23.8662 23.8663 24.8037 22.7206 24.8037H19.9214C19.4527 24.8037 18.7887 25.0771 18.4502 25.4156L16.4712 27.3946C15.664 28.2018 14.336 28.2018 13.5288 27.3946L11.5498 25.4156C11.2113 25.0771 10.5473 24.8037 10.0786 24.8037H7.27941C6.13369 24.8037 5.19628 23.8662 5.19628 22.7205V19.9214C5.19628 19.4397 4.92287 18.7757 4.58436 18.4502Z"
          stroke="#292D32"
          stroke-width="2.06507"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.1094 18.8938L18.9211 11.082"
          stroke="#292D32"
          stroke-width="2.06507"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.2631 18.2422H18.2751"
          stroke="#292D32"
          stroke-width="2.06507"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.7513 11.7324H11.7634"
          stroke="#292D32"
          stroke-width="2.06507"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default PromIc;
