import {
  handleApiErr,
  handleCourseCategory,
  handleLoding,
  handleCourseCategoryDataArr
} from "./courseCategorySlice";
import http from "../../../services/axios/axiosInstance";
import { apiUrls } from "../../../api/apiUrls";

const makeCategoryDataObj = (courseCategory) => {
  let categoryDataArr = [
    {
      id: 1,
      name: "All Categories",
      value: "All Categories",
      label: "All Categories",
    },
  ];

  courseCategory?.forEach((ele, idx) => {
    let categoryObj = {
      id: idx + 2,
      name: ele.category_name,
      value: ele.id,
      label: ele.category_name,
    };
    categoryDataArr.push(categoryObj)
  });
  return categoryDataArr
};

export const readCourseCategoryData = () => async (dispatch) => {
  try {
    dispatch(handleLoding("loading"));
    let config = {
      method: "get",
      url: apiUrls.readCourseCategoryData,
    };
    let response = await http(config);
    dispatch(handleCourseCategory(response?.data?.data));
    let categoryDataArr = makeCategoryDataObj(response?.data?.data)
    dispatch(handleCourseCategoryDataArr(categoryDataArr))
    dispatch(handleLoding("idle"));
  } catch (err) {
    dispatch(handleApiErr(`Error While Fetching Course Category Data`));
  }
};
