import React, { useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import PromotionImage from "../../assets/images/Partners/Promotion-1.svg";
import Img1 from "../../assets/images/Homepage/key2.png";
import KeyImg4 from "../../assets/images/Homepage/key4.png";
import KeyImg3 from "../../assets/images/Homepage/key3.png";
import "./Promotion.css";
import { changeLanguageText } from "../../services/helper";
import { useSelector } from "react-redux";
import http from "../../services/axios/axiosInstance";
import { toast } from "react-toastify";

const PromotionPage = () => {
  const title = "Promotion";
  const crumbs = [{ name: "Promotion", link: "/promotion" }];

  const activeLanguage = useSelector((state) => state.languageState.lang);
  const [pageData, setPageData] = useState({});
  const [promotion, setPromotion] = useState({});

  const getTranslatedData = async () => {
    setPageData({
      promotion: await changeLanguageText("Promotion", activeLanguage),
      lorem1: promotion?.promotion_name
        ? await changeLanguageText(promotion?.promotion_name, activeLanguage)
        : "",
      lorem2: promotion?.promotion_description
        ? await changeLanguageText(
            promotion?.promotion_description,
            activeLanguage
          )
        : "",
      terms: await changeLanguageText("Terms of Use", activeLanguage),
      term1: promotion?.terms_of_use[0]
        ? await changeLanguageText(promotion?.terms_of_use[0], activeLanguage)
        : "",
      term2: promotion?.terms_of_use[1]
        ? await changeLanguageText(promotion?.terms_of_use[1], activeLanguage)
        : "",
      term3: promotion?.terms_of_use[2]
        ? await changeLanguageText(promotion?.terms_of_use[2], activeLanguage)
        : "",
      loremDesc: await changeLanguageText(
        "Lorem Ipsum Dolor Sit Amet",
        activeLanguage
      ),
      select: await changeLanguageText("Select Country", activeLanguage),
      selct: await changeLanguageText("Select", activeLanguage),
      india: await changeLanguageText("India", activeLanguage),
      usa: await changeLanguageText("USA", activeLanguage),
      handsDesc: await changeLanguageText(
        "Hands on labs are virtual labs curated to enable your learning process. As a part of our training programs, Hands-on-labs provide you with much-needed firsthand experience in Microsoft cloud technologies in a virtual environment. Get step-by-step instructions and understand the key concepts to the core with practical training.",
        activeLanguage
      ),
      learnMore: await changeLanguageText("Learn More", activeLanguage),
      handson: await changeLanguageText("Hands On Lab", activeLanguage),
      datanotfound: await changeLanguageText("Data Not Found", activeLanguage),
      exploremore: await changeLanguageText("Explore More", activeLanguage),
      competitive: await changeLanguageText("Competitive", activeLanguage),
      challange: await changeLanguageText(
        "Challenge Experience",
        activeLanguage
      ),
      experience1: await changeLanguageText(
        "With Challenge Experiences you get to solve business challenges and evaluate your progress. We allow you to apply what you have learned and assess how far you have advanced. With a top-down approach to learning, Challenge Experience is the perfect platform for professionals to push the boundaries of skill acquisition.",
        activeLanguage
      ),
    });
  };

  useEffect(() => {
    getTranslatedData();
  }, [activeLanguage, promotion]);

  const readPromotionData = async () => {
    try {
      let config = {
        method: "get",
        url: "active-promotion",
      };
      const response = await http(config);
      if (response.data.data.length > 0) {
        setPromotion(response.data.data[0]);
      } else {
      }
    } catch (err) {
      toast.error("Error While Fetching Promotion");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    readPromotionData();
  }, []);

  return (
    <>
      {console.log(promotion)}
      <HeroSection
        title={pageData?.promotion}
        crumbs={[{ name: pageData.promotion, link: "/promotion" }]}
      />

      <div className="main-container">
        <div className="row  ">
          {/* left panel */}
          <div className="col-lg-6 h-100">
            <div className="mt-5 position-relative">
              <h2 className="h2-tag-promotion">{pageData?.lorem1}</h2>
              <p className="promotion-page-p-tag">{pageData?.lorem2}</p>
            </div>
            <div className="mt-5 position-relative">
              <h2 className="h2-tag-promotion">{pageData?.terms}</h2>
              {/* <div className='yerms-of-use-promortion'> */}
              <div className="mt-4 ">
                <img className="images-inside-tou me-3" src={Img1} />{" "}
                <span className="promotion-page-p-tag">{pageData?.term1}</span>
              </div>
              <div className="mt-2 ">
                <img className="images-inside-tou me-3" src={KeyImg4} />{" "}
                <span className="promotion-page-p-tag">{pageData?.term2}</span>
              </div>
              <div className="mt-2 ">
                <img className="images-inside-tou me-3" src={KeyImg3} />{" "}
                <span className="promotion-page-p-tag">{pageData?.term3}</span>
              </div>
              {/* </div> */}
            </div>
            {/* <div className="mt-5 position-relative">
                            <h2 className='h2-tag-promotion'>FAQ</h2>
                            <p className="promotion-page-p-tag">Lorem ipsum dolor sit amet consectetur.Gravida mauris adipiscing  </p>
                        </div>
                        <div id="accordion4">
                            <div className="border row shadow-sm mb-3 p-4 bg-white">
                                <div
                                    className="color-blue col-10 bg-white text-left font-weight-bold border-none d-flex "
                                    id="headingOne"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. ?
                                </div>
                                <div
                                    className="col-2 bg-white font-weight-bold border-none d-flex justify-content-end "
                                    id="headingOne"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    <i className="bi bi-arrow-down-circle-fill color-blue"></i>
                                </div>

                                <div
                                    id="collapseOne"
                                    className="collapse text-muted col-10 text-left mt-2"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion4"
                                >
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                                    accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </div>
                        <div id="accordion5">
                            <div className="border row shadow-sm mb-3 p-4 bg-white">
                                <div
                                    className="color-blue col-10 bg-white text-left font-weight-bold border-none d-flex "
                                    id="headingTwo"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                >
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. ?
                                </div>
                                <div
                                    className="col-2 bg-white font-weight-bold border-none d-flex justify-content-end "
                                    id="headingTwo"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                >
                                    <i className="bi bi-arrow-down-circle-fill color-blue"></i>
                                </div>

                                <div
                                    id="collapseTwo"
                                    className="collapse text-muted col-10 text-left mt-2"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion5"
                                >
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                                    accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </div>
                        <div id="accordion6">
                            <div className="border row shadow-sm mb-3 p-4 bg-white">
                                <div
                                    className="color-blue col-10 bg-white text-left font-weight-bold border-none d-flex "
                                    id="headingThree"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="true"
                                    aria-controls="collapseThree"
                                >
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. ?
                                </div>
                                <div
                                    className="col-2 bg-white font-weight-bold border-none d-flex justify-content-end "
                                    id="headingThree"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="true"
                                    aria-controls="collapseThree"
                                >
                                    <i className="bi bi-arrow-down-circle-fill color-blue"></i>
                                </div>

                                <div
                                    id="collapseThree"
                                    className="collapse text-muted col-10 text-left mt-2"
                                    aria-labelledby="headingThree"
                                    data-parent="#accordion6"
                                >
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                                    accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </div>
                        <div id="accordion7">
                            <div className="border row shadow-sm mb-3 p-4 bg-white">
                                <div
                                    className="color-blue col-10 bg-white text-left font-weight-bold border-none d-flex "
                                    id="headingFour"
                                    data-toggle="collapse"
                                    data-target="#collapseFour"
                                    aria-expanded="true"
                                    aria-controls="collapseFour"
                                >
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. ?
                                </div>
                                <div
                                    className="col-2 bg-white font-weight-bold border-none d-flex justify-content-end "
                                    id="headingFour"
                                    data-toggle="collapse"
                                    data-target="#collapseFour"
                                    aria-expanded="true"
                                    aria-controls="collapseFour"
                                >
                                    <i className="bi bi-arrow-down-circle-fill color-blue"></i>
                                </div>

                                <div
                                    id="collapseFour"
                                    className="collapse text-muted col-10 text-left mt-2"
                                    aria-labelledby="headingFour"
                                    data-parent="#accordion7"
                                >
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                                    accusamus terry richardson ad squid.
                                </div>
                            </div>
                        </div> */}
          </div>

          {/* right panel */}
          <div className="col-lg-6 p-3 h-100">
            <div className="row px-2 p-lg-5 position-relative  ">
              <img
                src={
              
                  promotion.promotion_banner ||  PromotionImage
                }
                className="objectFitCover p-0 shadow"
                id="partnersImage2"
                alt="Technofocus partners page"
              />
              {/* {promotion.promotion_banner ? (
                <>
                  <img
                    src={promotion.promotion_banner}
                    className="objectFitCover p-0 shadow"
                    id="partnersImage2"
                    alt="Technofocus partners page"
                  />
                </>
              ) : (
                <>
                  <img
                    src={PromotionImage}
                    className="objectFitCover p-0 shadow"
                    id="partnersImage2"
                    alt="Technofocus partners page"
                  />
                </>
              )} */}
              {/* <div className='border-country'>
                                <h2 className='h2-tag-promotion pb-4'>{pageData?.select}</h2>


                                <select className="classic">
                                    <option>{pageData?.selct}</option>
                                    <option>{pageData?.india}</option>
                                    <option>{pageData?.usa}</option>
                                </select>


                                <button className='check-availibility'>Check Availability</button>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionPage;
