import { authApiUrls } from "../api/authApiUrls";

export const getLinkedinOAuthUrl = (userType) => {
  const rootUrl = authApiUrls.linkedAuthRoot;
  console.log(userType);

  const options = {
    redirect_uri: authApiUrls.linkedinAuthRedirectSignUpUri,
    client_id: "86exlw9sjpiwso",
    response_type: "code",
  };
  let url = `${rootUrl}?response_type=${options.response_type}&client_id=${options.client_id}&redirect_uri=${options.redirect_uri}&state=${userType}&scope=r_liteprofile%20r_emailaddress`;

  return url;
};

export const getLinkedinSignInUrl = (userType) => {
  const rootUrl = authApiUrls.linkedAuthRoot;

  const options = {
    redirect_uri: authApiUrls.linkedinAuthRedirectSignInUri,
    client_id: "86exlw9sjpiwso",
    response_type: "code",
  };

  let url = `${rootUrl}?response_type=${options.response_type}&client_id=${options.client_id}&redirect_uri=${options.redirect_uri}&state=${userType}&scope=r_liteprofile%20r_emailaddress`;

  return url;
};
