const LevelIc = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#F1F1F1" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.8125 32.5625H32.5625V13.875H27.8125V32.5625ZM20.625 32.5625H25.375V19.625H20.625V32.5625ZM13.4375 32.5625H18.1875V23.9375H13.4375V32.5625ZM31.125 31.125H29.25V15.3125H31.125V31.125Z"
          fill="#1B449C"
        />
      </svg>
    </>
  );
};

export default LevelIc