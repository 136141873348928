import "./PartnerApplicationForm.css";

const PartnerApplicationForm = () => {
  return (
    <div className="partner-app-form-wrap">
      <div className="partner-app-form-hd">Application Form</div>
      <label className="partner-app-form-lb">Name</label>
      <input className="partner-app-form-in"></input>
      <label className="partner-app-form-lb">Email Address</label>
      <input className="partner-app-form-in"></input>
      <label className="partner-app-form-lb"> Additional Credentials (MCT, MVP)</label>
      <input className="partner-app-form-in"></input>
      <label className="partner-app-form-lb">LinkedIn Profile</label>
      <input className="partner-app-form-in"></input>
      <label className="partner-app-form-lb">Message</label>
      <input className="partner-app-form-in"></input>
      <button className="partner-app-form-btn">Submit</button>
    </div>
  );
};

export default PartnerApplicationForm;
