import React, { useState, useEffect } from "react";
import "./ChallengeExperience.css";
import HeroSection from "../../components/HeroSection/HeroSection";
import ChallengeExperienceCard from "../../components/ChallengeExperienceCard/ChallengeExperienceCard";
import Categories from "../../components/Categories/Categories";
import PrimaryCategories from "../../components/PrimaryCategories/PrimaryCategories";
import { useDispatch, useSelector } from "react-redux";
import {
  readCourseData,
  readCourseSearchData,
} from "../../redux/slices/course/courseSliceActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { changeLanguageText } from "../../services/helper";

const ChallengeExperience = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const challengeExperince = useSelector(
    (state) => state.courseState.challengeExperince
  );

  const title = "Challenge Experience";
  const crumbs = [
    { name: "Our Services", link: "/our-services" },
    { name: "Challenge Experience" },
  ];

  const categoryId = queryParameters.get("categoryId");
  const categoryName = queryParameters.get("categoryName");
  const searchQuery = queryParameters.get("searchQuery");

  const [courseSearchQuery, setCourseSearchQuery] = useState();

  const handleSearchQuery = (e) => {
    setCourseSearchQuery(e.target.value);
  };

  const handleCourseCategories = (courseCategoryObj) => {
    if (courseCategoryObj.courseType === "All Categories") {
      navigate(`/our-services/challenge-experience`, { replace: true });
    } else {
      navigate(
        `/our-services/challenge-experience?categoryId=${courseCategoryObj.activeCategory.value}&categoryName=${courseCategoryObj.activeCategory.name}`,
        { replace: true }
      );
    }
    window.location.reload();
  };

  const handleSearchCourse = () => {
    console.log("some");
    if (categoryId) {
      navigate(
        `/our-services/challenge-experience?categoryId=${categoryId}&categoryName=${categoryName}&searchQuery=${courseSearchQuery}`,
        { replace: true }
      );
    } else {
      navigate(
        `/our-services/challenge-experience?searchQuery=${courseSearchQuery}`,
        { replace: true }
      );
    }

    window.location.reload();
  };

  const activeLanguage = useSelector((state) => state.languageState.lang)
  const [pageData, setPageData] = useState({})

  const getTranslatedData = async () => {
    setPageData({
      ourservice: await changeLanguageText("Our Services", activeLanguage),
      title: await changeLanguageText("Challenge Experience", activeLanguage),
      search: await changeLanguageText("Search", activeLanguage),
      lablisting: await changeLanguageText("Challenge Experience Listings", activeLanguage),
      datanotfound: await changeLanguageText("Data Not Found", activeLanguage),
      loadMore: await changeLanguageText(" Load More", activeLanguage),
      span: await changeLanguageText(" We follow a systematic course outline to enable better learning.", activeLanguage),


    })
  }

  useEffect(() => {
    getTranslatedData()

  }, [activeLanguage]);


  useEffect(() => {
    if (searchQuery && categoryId && categoryId !== "All Categories") {
      dispatch(
        readCourseSearchData("Challenge_Experience", categoryId, searchQuery)
      );
    } else if (searchQuery) {
      dispatch(readCourseSearchData("Challenge_Experience", null, searchQuery));
    } else {
      dispatch(readCourseData("Challenge_Experience", categoryId));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="HandsBg">
      <HeroSection title={pageData?.title} crumbs={[{ name: pageData?.ourservice, link: "/our-services" }, { name: pageData?.title }]} />

      <div className="position-relative d-none d-lg-flex">
        <div className="d-flex text-white justify-content-center mt-3 position-absolute partnersSearch">
          <div className="d-flex justify-content-between bg-white newsletterInputBox shadow w-100">
            <input
              type="email"
              className="ms-2 w-75 color-blue py-2 ps-3 newsletterInput"
              placeholder={pageData?.search}
              onChange={handleSearchQuery}
            />
            <button
              onClick={handleSearchCourse}
              className="newsletterSubmit px-3 t-12 d-flex align-items-center justify-content-center"
            >
              {/* <span className="submitText text-white">Submit</span> */}
              <i className="submitTextIcon text-white  bi bi-search"></i>
            </button>
          </div>
          <button className="newsletterSubmit shadow bg-white mx-2 px-3 t-12 d-flex align-items-center justify-content-center">
            <i className="filterIcon color-blue bi bi-filter"></i>
          </button>
        </div>
      </div>

      <div className="homepage_section2">
        <div className="row my-2">
          <p className="h2">{pageData?.lablisting}</p>
        </div>

        <PrimaryCategories
          handleCourseCategories={handleCourseCategories}
          courseType="Challenge_Experience"
        />

        <div className="row mt-4">
          {challengeExperince.length > 0 ? (
            challengeExperince?.map((el, idx) => (
              <div style={{width: "321px"}} className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <ChallengeExperienceCard cardData={el} index={idx} />
              </div>
            ))
          ) : (
            <div>{pageData?.datanotfound}</div>
          )}
        </div>

        {/* <div className="d-flex justify-content-center">
          <button className="service_btn color-blue bg-white border px-3 py-2 mt-2 border-primary">
            <i className=" service_btn_icon bi bi-plus me-2" />
           {pageData?.loadMore}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ChallengeExperience;
