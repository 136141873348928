import React, { useEffect, useState } from "react";
import "./PartnerCourseForm.css";
import HeroSection from "../../components/HeroSection/HeroSection";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import http from "../../services/axios/axiosInstance";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  readPartnerCourse,
  partnerCourseApplication,
} from "../../redux/slices/partnerCourse/partnerCourseAction";
import partners1 from "../../assets/images/PartnerCourseForm/partners1.svg"
import partners2 from "../../assets/images/PartnerCourseForm/partners2.svg"
import partners3 from "../../assets/images/PartnerCourseForm/partners3.svg"
import CourseModules from "../PowerPlatform/CourseModules/CourseModules";


const PartnerCourseForm = () => {
  const dispatch = useDispatch();
  const title = "Implementing a hybrid cloud with Azure Stack HCI";
  const crumbs = [{ name: "Partners", link: "/partners" }, { name: "Azure" }];
  const queryParameters = new URLSearchParams(window.location.search);
  const partnerCourseId = queryParameters.get("partnerCourseId");

  let partnerCourse = useSelector(
    (state) => state.partnerCourseState.partnerCourse[0]
  );
  // console.log("partnerCourse",partnerCourse)
  const [tab, setTab] = useState("about");

  const contactUsSchema = yup
    .object({
      name: yup.string().required(),
      emailAddress: yup.string().required().email(),
      additionalCredentials: yup.string().required(),
      linkedinProfile: yup.string().required(),
      message: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactUsSchema),
  });

  const onSubmit = async (applicationFormData) => {
    applicationFormData.partnerCourseId = partnerCourseId;
    dispatch(partnerCourseApplication(applicationFormData));
  };

  useEffect(() => {
    dispatch(readPartnerCourse(partnerCourseId));
  }, []);

  return (
    <>
    {console.log(partnerCourse)}
      <HeroSection title={partnerCourse?.course_name} crumbs={crumbs} />

      <div className="main-container mt-4">
        <div className="row">
          {/* left panel */}
          <div className="col-lg-6">
            <div className="d-flex shadow-sm categories my-2 w-50  justify-content-between   bg-tabset">
              <span
                className={`${tab === "about" ? "categories_active" : ""
                  } category_menu`}
                onClick={() => setTab("about")}
              >
                About
              </span>
              <span
                className={`${tab === "modules" ? "categories_active" : ""
                  } category_menu`}
                onClick={() => setTab("modules")}
              >
                Modules
              </span>
            </div>

            {tab === "about"? <>
            <div className="mt-5">
              <h2>Brief</h2>
              <p className="text-muted">{partnerCourse?.course_about}</p>
            </div>

            <div className="mt-5">
              <h2>Schedule</h2>
              <i className="bi bi-calendar text-muted"></i>
              <span className="mx-3 text-muted">{partnerCourse?.start_date}</span>
            </div>

            <div className="mt-5">
              <h2>Mode Of Delivery</h2>
              <i className="bi bi-globe text-muted"></i>
              <span className="mx-3 text-muted">
                {partnerCourse?.course_mode}
              </span>
            </div>

            <div className="mt-5">
              <h2>Skills Required</h2>
              <div className="d-flex justify-content-start  align-items-center w-30 ">
                <img className="me-3" src={partners1} />
                <div className="text-muted">{partnerCourse?.required_course_skills[0]}</div>
              </div>
              <div className="d-flex justify-content-start  align-items-center w-30  mt-2">
                <img className="me-3" src={partners2} />
                <div className="text-muted">{partnerCourse?.required_course_skills[1]}</div>
              </div><div className="d-flex justify-content-start  align-items-center w-30 mt-2">
                <img className="me-3" src={partners3} />
                <div className="text-muted">{partnerCourse?.required_course_skills[2]}</div>
              </div>
              </div>
            </>: <CourseModules courseInfo={partnerCourse}/>}


              {/* <div className="text-muted">Azure Develoepr</div>
              <div className="text-muted">Data Analytics</div> */}
        
          </div>

          {/* right panel */}
          <div className="col-lg-6 p-3 partnercourseForm">
            <div className="row px-2 p-lg-5 position-relative">
              <div className="p-4 w-90 application-right-page  ">
                <p className="application-form-page text-center fs-25">Application Form</p>
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control p-4 form-inputs-inside"
                    placeholder="Enter name"
                    {...register("name")}
                  />
                  <div className="error-messages">{errors.name?.message}</div>
                </div>
                <div class="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control p-4 form-inputs-inside"
                    placeholder="Enter email"
                    {...register("emailAddress")}
                  />
                  <div className="error-messages">
                    {errors.emailAddress?.message}
                  </div>
                </div>
                <div class="form-group">
                  <label>Additional Credentials (MCT, MVP)</label>
                  <input
                    type="email"
                    name="additional"
                    className="form-control p-4 form-inputs-inside"
                    placeholder="MVP"
                    {...register("additionalCredentials")}
                  />
                  <div className="error-messages">
                    {errors.additionalCredentials?.message}
                  </div>
                </div>
                <div class="form-group">
                  <label>LinkedIn Profile</label>
                  <input
                    type="email"
                    name="linkedIn"
                    className="form-control p-4 form-inputs-inside"
                    placeholder="@abcdef1234"
                    {...register("linkedinProfile")}
                  />
                  <div className="error-messages">
                    {errors.linkedinProfile?.message}
                  </div>
                </div>
                <div class="form-group">
                  <label>Message</label>
                  <input
                    type="email"
                    name="message"
                    className="form-control p-4 form-inputs-inside"
                    placeholder="Write your message here"
                    {...register("message")}
                  />
                  <div className="error-messages">
                    {errors.message?.message}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary bg-blue px-5 mt-4"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerCourseForm;
