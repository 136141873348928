import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lodingApi: "idle",
  userProfile: {},
  apiError: "",
};

const userProfile = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    handleLoding: (state, { payload }) => {
      state.lodingApi = payload;
    },
    handleUserProfile: (state, { payload }) => {
      state.userProfile = payload;
    },
    handleApiError: (state, { payload }) => {
      state.apiError = payload;
    },
  },
});

export const { handleLoding, handleApiError, handleUserProfile } =
  userProfile.actions;
export default userProfile.reducer;
