import { useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import PowerImg from "../../assets/images/powerPlatform/PowerImg.png";
import "./PowerPlatform.css";
import Categories from "../../components/Categories/Categories";
import CalenderIc from "../../assets/svgs/CalenderIc";
import WebIc from "../../assets/svgs/WebIc";
import AppAccordian from "../../components/AppAccordian/AppAccordian";
import BuyCourses from "./BuyCourse/BuyCourse";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  readOneCourseData,
  readUpcommingCourse,
  readUpcommingOneCourseData,
} from "../../redux/slices/course/courseSliceActions";
import CourseModules from "./CourseModules/CourseModules";
import { changeLanguageText } from "../../services/helper";
import { Spinner } from "react-bootstrap";

const PowerPlatform = () => {
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams();

  const [courseModules, setCourseModules] = useState(true);
  const courseInfo = useSelector((state) => state.courseState.activeCourse);

  const courseId = queryParameters.get("courseId");
  const courseType = queryParameters.get("courseType");
  const courseState = queryParameters.get("courseState");
  const crumbs = [
    { name: "Our Services", link: "/our-services" },
    {
      name: `${
        courseInfo?.course_type === "COURSE"
          ? "Course"
          : courseInfo?.course_type === "HANDS_ON_LAB"
          ? "Hands On Labs"
          : "Challenge Experience"
      }`,
      link: `${
        courseInfo?.course_type === "COURSE"
          ? "/our-services/course"
          : courseInfo?.course_type === "HANDS_ON_LAB"
          ? "/our-services/hands-on-labs"
          : "/our-services/challenge-experience"
      }`,
    },
    {
      name: courseInfo?.category?.category_name,
      link: `${
        courseInfo?.course_type === "COURSE"
          ? `/our-services/cloud-training/course?categoryId=${courseInfo?.category?.id}&categoryName=${courseInfo?.category?.category_name}`
          : courseInfo?.course_type === "HANDS_ON_LAB"
          ? `/our-services/hands-on-labs?categoryId=${courseInfo?.category?.id}&categoryName=${courseInfo?.category?.category_name}`
          : `/our-services/challenge-experience?categoryId=${courseInfo?.category?.id}&categoryName=${courseInfo?.category?.category_name}`
      }`,
    },
  ];
  const categoryData = [
    { id: 1, name: "About", value: "About", label: "About" },
    { id: 2, name: "Modules", value: "Modules", label: "Modules" },
  ];

  const handleCategoryData = (cateData) => {
    // console.log(cateData)
    if (cateData.name === "Modules") {
      setCourseModules(true);
    } else {
      setCourseModules(false);
    }
  };

  const activeLanguage = useSelector((state) => state.languageState.lang);
  const [pageData, setPageData] = useState({});

  const getTranslatedData = async () => {
    setPageData({
      about: await changeLanguageText("About", activeLanguage),
      duration: await changeLanguageText("Total Duration", activeLanguage),
      schedule: await changeLanguageText("Schedule", activeLanguage),
      mode: await changeLanguageText("Mode of Delivery", activeLanguage),
      outlone: await changeLanguageText("Course Outline", activeLanguage),
      span: await changeLanguageText(
        " We follow a systematic course outline to enable better learning.",
        activeLanguage
      ),
    });
  };

  useEffect(() => {
    getTranslatedData();
  }, [activeLanguage]);

  useEffect(() => {
    if (courseState === "upcomming") {
      dispatch(readUpcommingOneCourseData(courseId));
    } else {
      dispatch(readOneCourseData(courseType, courseId));
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="power-con">
        <div className="power-hd">
          <HeroSection title={courseInfo.course_name} crumbs={crumbs} />
          <div className="power-hd-img">
            {courseInfo?.course_large_banner ? (
              <>
                <img
                  alt="courseImage"
                  style={{ borderRadius: "15px" }}
                  className="power-platform-img"
                  src={courseInfo?.course_large_banner}
                />
              </>
            ) : (
              <>
                <img
                  className="power-platform-img"
                  alt="pow-img"
                  src={PowerImg}
                />
              </>
            )}

            <div className="category-con category-con-img">
              <Categories
                categoriesData={categoryData}
                handleCategoryData={handleCategoryData}
              />
            </div>
          </div>
        </div>
        <div className="power-wrap">
          <div className="course-about">
            <div className="course-about-lf">
              <div className="category-con category-con-display ">
                <Categories
                  categoriesData={categoryData}
                  handleCategoryData={handleCategoryData}
                />
              </div>
              {courseModules ? (
                <CourseModules courseInfo={courseInfo} />
              ) : (
                <>
                  <div className="about-con ">
                    <div className="about-title">{pageData?.about}</div>
                    <div className="about-content">
                      {courseInfo?.course_about}
                    </div>
                  </div>
                  <div className="about-con">
                    <div className="about-title">{pageData?.duration}</div>
                    <div className="about-content">
                      <CalenderIc />
                      &nbsp;<span>{courseInfo?.course_duration}</span>
                    </div>
                  </div>
                  {courseInfo.course_on_request === false ? (
                    <div className="about-con">
                      <div className="about-title">{pageData?.schedule}</div>
                      <div className="about-content">
                        <CalenderIc />
                        &nbsp;<span>{courseInfo?.course_schedule}</span>
                      </div>
                    </div>
                  ) : null}

                  <div className="about-con">
                    <div className="about-title">{pageData?.mode}</div>
                    <div className="about-content">
                      <WebIc />
                      &nbsp;<span>{courseInfo?.course_mode}</span>
                    </div>
                  </div>
                  <div className="about-con">
                    <div className="about-title">{pageData?.outlone}</div>
                    <div className="about-content">
                      <span>{pageData?.span}</span>
                    </div>
                    <div className="accordian-con">
                      {courseInfo?.course_outlines?.length > 0 && courseInfo
                        ? courseInfo?.course_outlines.map((ele) => {
                            console.log(ele);
                            return (
                              <>
                                <div className="accordian-wrap">
                                  <AppAccordian
                                    accHed={ele?.cousrseOutlineKey?.trim()}
                                    accBdy={ele?.courseOutlineValue}
                                  />
                                </div>
                              </>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="course-about-rt">
              {courseInfo && <BuyCourses courseData={courseInfo} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PowerPlatform;
