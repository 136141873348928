import axios from "axios";
import { applicationEnv } from "../../config/appConfig";

const getBaseUrl = () => {
  let url;
  if (applicationEnv === "Development") {
    url = "http://localhost:4003/api/v1/";
  }
  if (applicationEnv === "Staging") {
    url = "https://techno-api.moshimoshi.cloud/api/v1/";
  }
  if(applicationEnv === "Production"){
    url = " https://techno-api.eastus.cloudapp.azure.com/api/v1/"
  }
  return url;
};

const getAccessToken = () => {
  let localStorageData = JSON.parse(localStorage.getItem("techcsrf"));
  if (localStorageData) {
    return localStorageData.accessToken;
  } else {
    return null;
  }
};

export default axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});
