const LinkedinLogo = () => {
  return (
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 73 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_8_8577)">
          <path
            d="M44.9101 44.4495H41.354V38.8804C41.354 37.5525 41.3303 35.8429 39.5045 35.8429C37.6524 35.8429 37.369 37.2899 37.369 38.7838V44.4491H33.8131V32.997H37.2268V34.562H37.2746C37.6162 33.9779 38.1099 33.4973 38.703 33.1715C39.2962 32.8458 39.9666 32.687 40.6428 32.7121C44.2471 32.7121 44.9116 35.0828 44.9116 38.167L44.9101 44.4495ZM29.8006 31.4316C28.6608 31.4318 27.7367 30.508 27.7366 29.3683C27.7364 28.2286 28.6601 27.3045 29.7998 27.3043C30.9395 27.304 31.8636 28.2278 31.8638 29.3675C31.8639 29.9148 31.6466 30.4398 31.2597 30.8269C30.8727 31.214 30.3479 31.4315 29.8006 31.4316ZM31.5786 44.4496H28.0188V32.997H31.5785V44.4495L31.5786 44.4496ZM46.6829 24.0017H26.232C25.2654 23.9909 24.4728 24.7652 24.4609 25.7317V46.2679C24.4724 47.235 25.2649 48.01 26.2319 47.9999H46.6829C47.6519 48.0119 48.4475 47.2368 48.4609 46.2679V25.7301C48.4472 24.7617 47.6514 23.9875 46.6829 24.0002"
            fill="#0A66C2"
          />
        </g>
        <rect
          x="1.26094"
          y="0.8"
          width="70.4"
          height="70.4"
          rx="7.2"
          stroke="#D6D6D6"
          stroke-width="1.6"
        />
        <defs>
          <clipPath id="clip0_8_8577">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(24.4609 24)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default LinkedinLogo;
