import {apiUrls} from "../api/apiUrls";
import http from "../services/axios/axiosInstance"

export const verifyAccessToken = (accessToken) => {
    return new Promise(async (resolve, reject) => {
        try{
            let data = {
                accessToken
              }
              let config = {
                method: "post",
                url: apiUrls.verifyClientAccessToken,
                data
              }
              let response = await http(config);
              resolve(response)
        }catch(err){
            reject(err)
        }
    })
}

export const verifyPartnersAccessToken = (accessToken) => {
    return new Promise(async (resolve, reject) => {
        try{
            let data = {
                accessToken
              }
              let config = {
                method: "post",
                url: apiUrls.verifyPartnersAccessToken,
                data
              }
              let response = await http(config);
              resolve(response)
        }catch(err){
            reject(err)
        }
    })
}