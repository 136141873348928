import React, { useEffect, useState } from "react";
import partnerapplyimg from "../../assets/images/Partners/partnerapplyimg.svg";
import triangle from "../../assets/images/Partners/triangle.svg";
import "./Partners.css";
import { apiUrls } from "../../api/apiUrls";
import http from "../../services/axios/axiosInstance";
import { useSelector } from "react-redux";
import { changeLanguageText } from "../../services/helper";

function CalendarComp() {
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [selectedCourse, setSelectedCourse] = useState();

  const weekdays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const monthArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const daysInMonth = new Date(year, month, 0).getDate();
  const startDay = new Date(year, month - 1, 1).getDay();

  const calendarRows = [];
  let date = ["26-5-2023", "16-5-2023", "29-5-2023", "25-5-2023", "19-5-2023"];
  const [calenderdata, setCalenderData] = useState([]);

  const getCalenderDate = async () => {
    try {
      let config = {
        method: "get",
        url: `${apiUrls.partnerCourse}`,
      };
      let res = await http(config);
      // console.log(res)
      // console.log("DATA", data.result)
      setCalenderData(res?.data?.result);
    } catch (err) {
      console.log("err", err);
    }
  };

  let days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  let index = 0;
  for (let i = 0; i < 6; i++) {
    let row = [];
    for (let j = 0; j < 7; j++) {
      if (i === 0 && j < startDay - 1) {
        row.push("");
      } else if (index < days.length) {
        row.push(days[index]);
        index++;
      }
    }
    calendarRows.push(row);
  }

  const handleMonthDown = () => {
    if (month == 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const handleMonthUp = () => {
    if (month == 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const checkDateArr = (el) => {
    let found = false;
    for (let i = 0; i < calenderdata?.length; i++) {
      let asd = calenderdata?.[i]?.start_date.split("-");

      let sd = asd
        .map((ele) => {
          return parseInt(ele);
        })
        .join("-");
        console.log(sd, el, "ffffff")
      if (sd === el) found = true;
    }
    return found;
  };
  const activeLanguage = useSelector((state) => state.languageState.lang);
  const [pageData, setPageData] = useState({});

  const getTranslatedData = async () => {
    setPageData({
      deepdive: await changeLanguageText(
        "A Deep Dive into Cost Accounting in Dynamics 365 Finance",
        activeLanguage
      ),
      applynow: await changeLanguageText("Apply Now", activeLanguage),
    });
  };

  useEffect(() => {
    getCalenderDate();
    getTranslatedData();
  }, [activeLanguage]);

  useEffect(() => {
    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    setMonth(month);
    setYear(year);
    getCalenderDate();
  }, []);

  // console.log("ca---", calenderdata)
  return (
    <div className="calender-boddy">
      <h2 className="back-colour-year ">
        {`${monthArr[month - 1]},${year}`}
        <div>
          <span onClick={handleMonthUp} className="  px-4">
            <i class="bi bi-caret-up-fill"></i>
          </span>
          <span onClick={handleMonthDown}>
            <i class="bi bi-caret-down-fill"></i>
          </span>
        </div>
      </h2>
      <div className="p-4 bg-white shadow calendarBox">
        <table className="background-colour-table">
          <thead>
            <tr>
              {weekdays.map((day, j) => (
                <th
                  className={`${
                    j === 5 || j === 6 ? "text-danger" : ""
                  } text-center  `}
                  key={day}
                >
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="inside-part">
            {calendarRows.map((row, i) => (
              <tr key={i}>
                {row.map((day, j) => (
                  <td
                    id={`${month}-${day}-${year}`}
                    className={`${
                      j === 5 || j === 6 ? "text-danger" : ""
                    } text-end py-4  font-weight-bold position-relative availableDateParent`}
                    onClick={(e) => {}}
                  >
                    <span
                      className={`position-absolute absolute-center ${
                        checkDateArr(`${month}-${day}-${year}`)
                          ? "availableDate"
                          : ""
                      }`}
                    >
                      {day}
                    </span>
                    {checkDateArr(`${month}-${day}-${year}`) ? (
                      <div className="position-absolute applyButton">
                        <img className="trainagle-applybutton" src={triangle} />
                        <img src={partnerapplyimg} />
                        <div className="title-apply-now">
                          {pageData?.deepdive}
                        </div>
                        {/* <div className="button-apply-now">
                          {pageData?.applynow}
                        </div> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CalendarComp;
