import AppAccordian from "../../../components/AppAccordian/AppAccordian";
import "./CourseModules.css"

const CourseModules = ({courseInfo}) => {
    return(
        <>
            <div className="course-modules-con">
            {courseInfo?.course_modules?.length > 0 && courseInfo
                        ? courseInfo?.course_modules.map((ele) => {
                            return (
                              <>
                                <div className="accordian-wrap">
                                  <AppAccordian
                                    accHed={ele?.courseModuleKey?.trim()}
                                    accBdy={ele?.courseModuleValue}
                                  />
                                </div>
                              </>
                            );
                          })
                        : null}
            </div>
        </>
    )
}

export default CourseModules