import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./PrimaryCategories.css";
import { useDispatch, useSelector } from "react-redux";
import { readCourseCategoryData } from "../../redux/slices/courseCategory/courseCategoryAction";
import arrowours from "../../assets/images/OurServices/arrowours.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const PrimaryCategories = ({
  courseType,
  handleCourseCategories,
  showArrow,
  handleCategoryRedirect,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedId = searchParams.get("categoryId");

  const data = useSelector(
    (state) => state.courseCategoryState.courseCategoryDataArr
  );
  const courseCategoryData = useSelector(
    (state) => state.courseCategoryState.courseCategory
  );

  const [activeCategory, setActiveCategory] = useState();

  // const handleChangePage = (id, cateName) => {
  //   // alert("WHICH PAGE YOU WANNA GO")
  //   console.log(id, cateName);
  //   navigate(
  //     `/our-services/cloud-training/course?categoryId=${id}&categoryName=${cateName}`
  //   );

  //   console.log(courseType);
  // };

  useEffect(() => {
    dispatch(readCourseCategoryData());
  }, []);

  //   useEffect(() => {

  //   },[activeCategory, courseType, handleCourseCategories])

  useEffect(() => {
    if (selectedId) {
      const selectedCategory = data?.find((el) => el.value === selectedId);
      setActiveCategory(selectedCategory);
    } else {
      setActiveCategory(data?.[0]);
    }
  }, [data]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#1b449c",
      color: "white",
      borderRadius: "100px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#FFFFFF",
      fontWeight: "bold",
      padding: "0 1rem",
    }),
    dropdownIndicator: (styles) => ({ ...styles, color: "#FFFFFF" }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div>
      <div className="d-none d-lg-flex categories my-2 bg-light justify-content-between">
        {data?.map((el, idx) => (
          <span
            className={`${
              el?.id === activeCategory?.id ? "categories_active" : ""
            } category_menu`}
            key={el?.id}
            onClick={() => {
              setActiveCategory(el);
              handleCourseCategories({ courseType, activeCategory: el });
            }}
          >
            <div  className={showArrow ? "box-our-service ps-4" : "box-our-ser ps-4"}>
              <div style={{cursor:"pointer"}}>{el?.name}</div>

              {el?.id === activeCategory?.id && showArrow ? (
                <img
                  className="arrow-our-service"
                  src={arrowours}
                  onClick={() =>
                    handleCategoryRedirect(
                      courseCategoryData[idx - 1]?.id,
                      courseCategoryData[idx - 1]?.category_name
                    )
                  }
                />
              ) : (
                ""
              )}
            </div>
          </span>
        ))}
      </div>

      <div className="d-lg-none my-2" style={{ zIndex: 9999 }}>
        <Select
          className="d-flex"
          value={activeCategory}
          onChange={(el) => {
            setActiveCategory(el);
            handleCourseCategories({ courseType, activeCategory: el });
          }}
          options={data}
          styles={colourStyles}
          menuPortalTarget={document.body}
          components={{ IndicatorSeparator: () => null }}
        />
      </div>
    </div>
  );
};

export default PrimaryCategories;
