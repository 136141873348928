export const getDefaultLang = () => {
    const lang = localStorage?.getItem("technoLang")
    if(lang && lang !== "null") {
        return lang
    } else {
        return "en"
    }
}

export const capitalizeFirstLetter = (string) =>  {
    return string.charAt(0).toUpperCase() + string.slice(1);
}