const DeleteIcMb = () => {
  return (
    <>
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.14747 4.79509H6.59895V10.2727H7.14747V4.79509ZM0.559608 0.884925H3.52941V0.605627C3.52941 0.595861 3.52941 0.587072 3.53033 0.577307C3.54234 0.298985 3.73534 0.0646096 3.99298 0.011875C4.06039 -0.00179688 4.12226 0.000156251 4.1906 0.000156251H5.90082C5.92391 0.000156251 5.94607 -0.000820315 5.96823 0.00210938C6.11414 0.00992191 6.24711 0.0763284 6.34315 0.178868C6.43827 0.279454 6.49922 0.416173 6.50845 0.567541C6.50937 0.585119 6.5103 0.603674 6.5103 0.621252V0.884925H9.5115C9.55213 0.884925 9.58076 0.885902 9.62139 0.892738C9.82547 0.927894 9.97968 1.10075 9.99723 1.3195C9.99908 1.34293 10 1.3654 10 1.38883V2.41813C10 2.56169 9.89011 2.6779 9.75344 2.6779H0.24656C0.10989 2.67888 0 2.56169 0 2.41813V2.41227V1.4445C0 1.03922 0.188383 0.884925 0.559608 0.884925ZM5.36245 4.79509H4.813V10.2727H5.36245V4.79509ZM3.57743 4.79509H3.0289V10.2727H3.57835V4.79509H3.57743ZM0.991781 3.08317H9.03407C9.06732 3.08513 9.09502 3.08708 9.12919 3.09294C9.24462 3.11345 9.35082 3.17204 9.43116 3.25505C9.52442 3.35271 9.58537 3.48357 9.58814 3.62908C9.58814 3.65935 9.58537 3.6906 9.5826 3.72185L8.90572 11.4035C8.89648 11.5041 8.88078 11.5939 8.83276 11.6887C8.7395 11.8723 8.56681 12.0002 8.35257 12.0002H1.69914C1.64835 12.0002 1.60218 12.0002 1.55231 11.9924C1.532 11.9895 1.5126 11.9846 1.49321 11.9787C1.46459 11.9699 1.43596 11.9582 1.40918 11.9445C1.38425 11.9318 1.36116 11.9182 1.33715 11.9006C1.19771 11.799 1.10537 11.6291 1.08874 11.4396L0.415551 3.69939C0.413704 3.67497 0.411857 3.64861 0.411857 3.62419C0.416474 3.47966 0.476498 3.35075 0.568843 3.25407C0.650106 3.17009 0.759073 3.11052 0.87635 3.09099C0.914212 3.08513 0.954843 3.08317 0.991781 3.08317ZM9.02669 3.60368H0.978853C0.926217 3.60368 0.901284 3.61345 0.905901 3.67204L1.57725 11.3918V11.3928C1.58002 11.4299 1.59572 11.4602 1.6188 11.4738L1.62619 11.4768L1.63173 11.4777H1.63912H8.35165C8.40705 11.4777 8.41352 11.3957 8.41721 11.3527L9.09502 3.66618C9.09872 3.61736 9.07101 3.60368 9.02669 3.60368Z"
          fill="#C60000"
        />
      </svg>
    </>
  );
};

export default DeleteIcMb;
