import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SignInUpArrow from "../assets/svgs/SignInUpArrow";
import SignUpInMid from "../assets/svgs/SignInUpMid";
import TechnoFocusLogo from "../assets/svgs/TechnoFocusLogo";
import TechnoFocusLogoSm from "../assets/svgs/TechnoFocusLogoSm";
import pic1 from "../assets/images/signInUp/pic1.svg";
import pic2 from "../assets/images/signInUp/pic2.svg";
import pic3 from "../assets/images/signInUp/pic3.svg";
import {
  resetUserPassword,
  forgotUserPassword,
  sendForgotPasswordLink,
} from "../redux/slices/authentication/authSliceActions";
import "../pages/SignIn/SignIn.css";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.authenticationState.loadingApi);
  const [userEmail, setUserEmail] = useState("");

  const handlePasswordSubmit = () => {
    if (!userEmail) {
      return toast.error("Email Required");
    } else {
      dispatch(sendForgotPasswordLink(userEmail));
    }
  };

  return (
    <>
      <div className="signinup-con">
        <div className="signinup-con-hd">
          <Link to="/">
            <TechnoFocusLogoSm />
          </Link>
        </div>
        <div className="signinup-wrap">
          <div className="signinup-left">
            <div className="logo-box">
              <Link to="/">
                <TechnoFocusLogo />
              </Link>
            </div>

            <div className="back-img-box"></div>
            <h1 className="signinup-hd">
              <span className="signinup-hd-main"></span>
              <span className="signinup-hd-sub"></span>
            </h1>
            <div className="signinup-mid-img position-relative">
              <SignUpInMid />
              <img className="imagesignupspic3" src={pic1} />
              <img className="imagesignupspic2" src={pic2} />
              <img className="imagesignupspic1" src={pic3} />
            </div>
          </div>
          <div className="signinup-right">
            <div className="signinup-right-hd">
              <div className="signinup-right-hd-main">Forgot Password</div>
            </div>

            <div className="signinup-form-box">
              <div className="signinup-input-box">
                <label className="signinup-label">Email</label>
                <div className="signinup-input-wrap">
                  <input
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    className="signinup-input px-3"
                    placeholder="Enter Email"
                  />
                </div>
              </div>
              <div className="signinup-btn-box mt-5">
                <button onClick={handlePasswordSubmit} className="signinup-btn">
                  {isLoading === "loading" ? (
                    <Spinner />
                  ) : (
                    <>
                      {" "}
                      Send Reset Link
                      <span>
                        <SignInUpArrow />
                      </span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
