import { Link } from "react-router-dom";
import "./HeaderProfile.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { readUserProfile } from "../../redux/slices/userProfile/userProfileActions"

const HeaderProfile = ({ userProfileName }) => {
  const dispatch = useDispatch()
  // const notify = () => toast("Logout Successfully !");


  const logout = () => {
    localStorage.removeItem("techcsrf")
    // notify()
    // setTimeout(() => {
    window.location.reload()
    // }, 5500)
  }

  useEffect(() => {
    dispatch(readUserProfile())
  }, [])

  return (
    <>
      <div class="dropdown">
        <div class="btn dropdown-toggle user-profile-downbar " type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <div className="header-profile-image"></div>
          <div className="header-profile-name">{userProfileName}</div>
          {/* <HeaderProfileDownArrow /> */}
        </div>
        <ul class="dropdown-menu dropdown-bar">
          <Link to="/profile">
            <li className="border-remove">
              <a class="dropdown-item " href="#">Profile</a>
            </li>
          </Link>
          <li><a class="dropdown-item" href="#" onClick={logout}  >Logout</a></li>
        </ul>
      </div>
      <ToastContainer />
    </>
  );
};

export default HeaderProfile;
