import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { manualSignUp } from "../../redux/slices/authentication/authSliceActions";
import { useNavigate } from "react-router-dom";
import { getGoogleOAuthURL } from "../../services/getGoogleUrl";
import { getLinkedinOAuthUrl } from "../../services/getLinkedinUrl";
import { getMicrosoftUrl } from "../../services/getMicrosoftUrl";
import {
  signUpStepOneValidation,
  signUpStepThreeValidation,
  signUpStepTwoValidation,
} from "./SignUpHelper";
import GmailLogo from "../../assets/svgs/GmailLogo";
import LinkedinLogo from "../../assets/svgs/LinkedinLogo";
import MicrosoftLogo from "../../assets/svgs/MicrosoftLogo";
import SignInUpArrow from "../../assets/svgs/SignInUpArrow";
import SignUpInMid from "../../assets/svgs/SignInUpMid";
import TechnoFocusLogo from "../../assets/svgs/TechnoFocusLogo";
import TechnoFocusLogoSm from "../../assets/svgs/TechnoFocusLogoSm";
import Categories from "../../components/Categories/Categories";
import "./SignUp.css";
import pic1 from "../../assets/images/signInUp/pic1.svg";
import pic2 from "../../assets/images/signInUp/pic2.svg";
import pic3 from "../../assets/images/signInUp/pic3.svg";
import Next from "../../assets/images/signInUp/Next.svg";
import ScreenreaderLabelExample from "./ProgressBar";
import { changeLanguageText } from "../../services/helper";
import { changeLanguage } from "../../redux/slices/language/languageSlice";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errMessageState, setErrMessageState] = useState("");

  const queryParameters = new URLSearchParams(window.location.search);
  const errMessage = queryParameters.get("errMessage");

  useEffect(() => {
    if (errMessage?.length > 0) {
      var properErrMessage = errMessage.split("+").join(" ");
      setErrMessageState(properErrMessage);
    }
    if (errMessageState?.length > 0) {
      toast.error(errMessageState);
    }
  }, [errMessageState]);
  const categoryData = [
    { id: 1, name: "Student", value: "Student", label: "Student" },
    { id: 2, name: "Partner", value: "Partner", label: "Partner" },
  ];

  const isAuth = useSelector((state) => state.authenticationState.isAuth);
  const isLoading = useSelector(
    (state) => state.authenticationState.loadingApi
  );
  console.log(isLoading);
  const [userCategory, setUserCategory] = useState("");
  const [signupProgress, setSignupProgress] = useState(33);
  const [nextPage, setNextPage] = useState(1);
  const [signupStepOneData, setSignupStepOneData] = useState({
    firstName: "",
    lastName: "",
  });
  const [signupStepTwoData, setSignupStepTwoData] = useState({
    userEmail: "",
    userPassword: "",
    userConfirmPassword: "",
  });
  const [signupStepThreeData, setSignupStepThreeData] = useState({
    organizationName: "",
    country: "",
  });

  const [signupStepOneErr, setSignupStepOneErr] = useState({});
  const [signupStepTwoErr, setSignupStepTwoErr] = useState({});
  const [signupStepThreeErr, setSignupStepThreeErr] = useState({});

  const handleChangeStepOne = (e) => {
    setSignupStepOneData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const handleChangeStepTwo = (e) => {
    setSignupStepTwoData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const handleChangeStepThree = (e) => {
    setSignupStepThreeData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const handleFormSteps = () => {
    if (nextPage === 1) {
      signUpStepOneValidation(signupStepOneData)
        .then(() => {
          handelChangePage();
        })
        .catch((err) => {
          setSignupStepOneErr(err);
        });
    }

    if (nextPage === 2) {
      signUpStepTwoValidation(signupStepTwoData)
        .then(() => {
          handelChangePage();
        })
        .catch((err) => {
          console.log(err);
          setSignupStepTwoErr(err);
        });
    }

    if (nextPage === 3) {
      signUpStepThreeValidation(signupStepThreeData)
        .then(() => {
          dispatch(
            manualSignUp(
              signupStepOneData,
              signupStepTwoData,
              signupStepThreeData,
              userCategory
            )
          );
        })
        .catch((err) => {
          setSignupStepThreeErr(err);
        });
    }
  };

  const handleCategoryData = (categoryData) => {
    setUserCategory(categoryData);
  };

  const handelChangePage = () => {
    setNextPage(nextPage + 1);
    setSignupProgress(signupProgress + 33);
  };

  const activeLanguage = useSelector((state) => state.languageState.lang);
  const [pageData, setPageData] = useState({});

  const getTranslatedData = async () => {
    setPageData({
      signup: await changeLanguageText("Sign Up", activeLanguage),
      name1: await changeLanguageText("First Name", activeLanguage),
      name2: await changeLanguageText("Enter First Name", activeLanguage),
      name3: await changeLanguageText("Last Name", activeLanguage),
      name4: await changeLanguageText("Enter Last Name", activeLanguage),
      email: await changeLanguageText("Email ID", activeLanguage),
      email2: await changeLanguageText("Enter Email Address", activeLanguage),
      password: await changeLanguageText("Password", activeLanguage),
      password2: await changeLanguageText("Enter Password ", activeLanguage),
      conform: await changeLanguageText("Confirm Password", activeLanguage),
      conform2: await changeLanguageText(
        "Enter Confirm Password",
        activeLanguage
      ),
      organ: await changeLanguageText("Organization Name", activeLanguage),
      organ2: await changeLanguageText(
        "Enter Organization Name",
        activeLanguage
      ),
      country1: await changeLanguageText("Select Country", activeLanguage),
      cname1: await changeLanguageText("India", activeLanguage),
      cname2: await changeLanguageText("Singapore", activeLanguage),
      cname3: await changeLanguageText("Estonia", activeLanguage),
      next: await changeLanguageText("Enter Country Name", activeLanguage),
      register: await changeLanguageText("Register", activeLanguage),
      nexts: await changeLanguageText("Next", activeLanguage),
      orsign: await changeLanguageText("Or", activeLanguage),
      already: await changeLanguageText("Already Registered?", activeLanguage),
      loginnow: await changeLanguageText(" Login Now", activeLanguage),
      // nexts: await changeLanguageText("Next", activeLanguage),
    });
  };

  useEffect(() => {
    getTranslatedData();
  }, [activeLanguage]);

  useEffect(() => {
    dispatch(changeLanguage(localStorage.getItem("technoLang")));
  }, []);

  useEffect(() => {
    if (isAuth) {
      navigate("/", { replace: true });
    }
  }, [isAuth]);

  useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);

  return (
    <>
      <div className="signinup-con">
        <div className="signinup-con-hd">
          <Link to="/">
            <TechnoFocusLogoSm />
          </Link>
        </div>
        <div className="signinup-wrap">
          <div className="signinup-left">
            <div className="logo-box">
              <Link to="/">
                <TechnoFocusLogo />
              </Link>
            </div>

            <div className="back-img-box"></div>
            <h1 className="signinup-hd">
              <span className="signinup-hd-main"></span>
              <span className="signinup-hd-sub"></span>
            </h1>
            <div className="signinup-mid-img position-relative">
              <SignUpInMid />
              <img className="imagesignupspic3" src={pic1} alt="signupLogo" />
              <img className="imagesignupspic2" src={pic2} alt="signupLogo" />
              <img className="imagesignupspic1" src={pic3} alt="signupLogo" />
            </div>
          </div>
          <div className="signinup-right">
            <div className="signinup-right-hd">
              <div className="signinup-right-hd-main">{pageData?.signup}</div>
              <div className="signinup-right-hd-sub">
                {/* Lorem ipsum dolor sit amet consectetur. */}
              </div>
            </div>
            <div className="signinup-right-categories-wrap">
              <div className="signinup-right-categories-box">
                <Categories
                  handleCategoryData={handleCategoryData}
                  categoriesData={categoryData}
                />
              </div>
            </div>

            <div className="signinup-form-box">
              {nextPage === 1 ? (
                <>
                  {" "}
                  <div className="signinup-input-box">
                    <label className="signinup-label">{pageData?.name1}</label>
                    <div className="signinup-input-wrap">
                      <input
                        name="firstName"
                        onChange={handleChangeStepOne}
                        type="text"
                        className="signinup-input px-3"
                      />
                    </div>
                    {signupStepOneErr?.firstName && (
                      <div className="err-msg">{pageData?.name2}</div>
                    )}
                  </div>
                  <div className="signinup-input-box">
                    <label className="signinup-label mt-4">
                      {pageData?.name3}
                    </label>
                    <div className="signinup-input-wrap">
                      <input
                        name="lastName"
                        onChange={handleChangeStepOne}
                        type="text"
                        className="signinup-input px-3"
                      />
                    </div>
                    {signupStepOneErr?.lastName && (
                      <div className="err-msg">{pageData?.name4}</div>
                    )}
                  </div>
                </>
              ) : null}
              {nextPage === 2 ? (
                <>
                  {" "}
                  <div className="signinup-input-box">
                    <label className="signinup-label ">{pageData?.email}</label>
                    <div className="signinup-input-wrap">
                      <input
                        name="userEmail"
                        onChange={handleChangeStepTwo}
                        type="email"
                        className="signinup-input px-3"
                      />
                    </div>
                    {signupStepTwoErr?.userEmail && (
                      <div className="err-msg">{pageData?.email2}</div>
                    )}
                  </div>
                  <div className="signinup-input-box">
                    <label className="signinup-label mt-4">
                      {pageData?.password}
                    </label>
                    <div className="signinup-input-wrap">
                      <input
                        name="userPassword"
                        onChange={handleChangeStepTwo}
                        type="password"
                        className="signinup-input px-3"
                      />
                    </div>
                    {signupStepTwoErr?.userPassword && (
                      <div className="err-msg">{pageData?.password2}</div>
                    )}
                  </div>
                  <div className="signinup-input-box">
                    <label className="signinup-label mt-4">
                      {pageData?.conform}
                    </label>
                    <div className="signinup-input-wrap">
                      <input
                        name="userConfirmPassword"
                        onChange={handleChangeStepTwo}
                        type="password"
                        className="signinup-input px-3"
                      />
                    </div>
                    {signupStepTwoErr?.userConfirmPassword && (
                      <div className="err-msg">{pageData?.conform2}</div>
                    )}
                  </div>
                </>
              ) : null}
              {nextPage === 3 ? (
                <>
                  {" "}
                  <div className="signinup-input-box">
                    <label className="signinup-label">{pageData?.organ}</label>
                    <div className="signinup-input-wrap">
                      <input
                        name="organizationName"
                        onChange={handleChangeStepThree}
                        className="signinup-input px-3"
                      />
                    </div>
                    {signupStepThreeErr?.organizationName && (
                      <div className="err-msg">{pageData?.organ2}</div>
                    )}
                  </div>
                  <div className="signinup-input-box mb-5">
                    <div className="signinup-input-wrap">
                      <select
                        name="country"
                        onChange={handleChangeStepThree}
                        className="select-signup-country px-3"
                      >
                        <option>{pageData?.country1}</option>
                        <option>{pageData?.cname1}</option>
                        <option>{pageData?.cname2}</option>
                        <option>{pageData?.cname3}</option>
                      </select>
                    </div>
                    {signupStepThreeErr?.country && (
                      <div className="err-msg">{pageData?.next}</div>
                    )}
                  </div>
                </>
              ) : null}
              <div className="progressbar">
                <div className="signinup-btn-box  ">
                  {isLoading === "loading" ? (
                    <button className="signinup-btn  ">
                      {" "}
                      <Spinner />
                    </button>
                  ) : (
                    <button
                      onClick={handleFormSteps}
                      className="signinup-btn  "
                    >
                      {" "}
                      {nextPage === 3 ? pageData?.register : pageData?.nexts}
                      <span className="p-2">
                        <img src={Next} alt="next" />
                      </span>{" "}
                    </button>
                  )}
                </div>
                <ScreenreaderLabelExample now={signupProgress} />
              </div>

              <div className="signinup-seprator-box">
                <div className="signinup-seprator-left"></div>

                <div className="signinup-seprator-center">
                  {pageData?.orsign}
                </div>

                <div className="signinup-seprator-right"></div>
              </div>
              <div className="signinup-logo-box">
                <a href={getMicrosoftUrl(userCategory.name)}>
                  <div className="signinup-micro-logo signinup-logo-size">
                    <MicrosoftLogo />
                  </div>
                </a>
                <a href={getGoogleOAuthURL(userCategory.name)}>
                  <div className="signinup-gmail-logo signinup-logo-size">
                    <GmailLogo />
                  </div>
                </a>
                <a href={getLinkedinOAuthUrl(userCategory.name)}>
                  <div className="signinup-linkedin-logo signinup-logo-size">
                    <LinkedinLogo />
                  </div>
                </a>
              </div>
              <div className="signinup-already-box">
                {pageData?.already}
                <Link to="/signin">
                <span className="signinup-already-box-login">
                  {pageData?.loginnow}
                </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
