import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css";
import "./custom.scss";
import Footer from "./components/Footer/Footer";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import ContactUs from "./pages/ContactUsPage/ContactUs";
import HomePage from "./pages/HomePage/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PartnerCourseForm from "./pages/PartnerCourseForm/PartnerCourseForm";
import Partners from "./pages/Partners/Partners";
import OurServices from "./pages/OurServices/OurServices";
import CloudTraining from "./pages/CloudTraining/CloudTraining";
import CloudTrainingCategory from "./pages/CloudTrainingCategory/CloudTrainingCategory";
import HandsOnLabs from "./pages/HandsOnLabs/HandsOnLabs";
import ChallengeExperience from "./pages/ChallengeExperience/ChallengeExperience";
import Cart from "./pages/Cart/Cart";
import TrainingCalender from "./pages/TraningCalender/TraningCalender";
import PowerPlatform from "./pages/PowerPlatform/PowerPlatform";
import SignIn from "./pages/SignIn/SignIn";
// import Dynamic from "./pages/Dynamic/Dynamic";
// import DataAi from "./pages/CategoryCourses/CategoryCourses";
// import Microsoft from "./pages/Microsoft/Microsoft";
import CategoryCourses from "./pages/CategoryCourses/CategoryCourses";
import MicrosoftSecurity from "./pages/MicrosoftSecurity/MicrosoftSecurity";
import Courses from "./pages/Courses/Courses";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SignUp from "./pages/SignUp/SignUp";
import ProtectedRoute from "./hoc/ProtectedRoute";
import AuthRedirectRoute from "./hoc/AuthRedirectRoute";
import Profile from "./pages/Profile/Profile";
import GoogleAuthRedirectPage from "./hoc/GoogleAuthRedirectPage";
import AuthRedirectPage from "./hoc/AuthRedirectPage";
import PartnerCourseDescription from "./pages/PartnerCourseDescription/PartnerCourseDescription";
import PromotionPage from "./pages/Promotion/PromotionPage";
import PartnerProtectedRoute from "./hoc/PartnersProtectedRoute";
import { readCourseCart } from "./redux/slices/courseCart/courseCartActions";
import { useDispatch } from "react-redux";
import VerifyPartner from "./hoc/VerifyPartner";
import ForgetResetPassword from "./hoc/ForgotResetPassword";
import ForgotPassword from "./hoc/ForgotPassword";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log(localStorage?.getItem("technoLang"))
    if (!localStorage?.getItem("technoLang")) {
      localStorage?.setItem("technoLang", "en");
    }
    dispatch(readCourseCart());
  }, []);

  return (
    <div>
      {/* <Router> */}
      <Routes>
        <Route
          path="/"
          element={
            // <ProtectedRoute>
            <HomePage />
            // </ProtectedRoute>
          }
          exact={true}
        />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route
          path="/partner-course-form"
          element={
            <PartnerProtectedRoute>
              <PartnerCourseForm />
            </PartnerProtectedRoute>
          }
        />
        <Route
          path="/partners"
          element={
            <VerifyPartner>
              <Partners />
            </VerifyPartner>
          }
        />
        <Route
          path="/our-services"
          element={<OurServices />}
          replace={true}
          exact={true}
        />
        <Route
          path="/our-services/cloud-training"
          element={<CloudTraining />}
          replace={true}
          exact={true}
        />
        <Route
          path="/our-services/cloud-training/:id"
          element={<CloudTrainingCategory />}
        />
        <Route
          path="/our-services/hands-on-labs"
          element={<HandsOnLabs />}
          replace={true}
          exact={true}
        />
        <Route
          path="/our-services/course"
          element={<Courses />}
          replace={true}
          exact={true}
        />
        <Route
          path="/our-services/challenge-experience"
          element={<ChallengeExperience />}
          exact={true}
        />
        <Route path="/cart" element={<Cart />} />
        <Route path="/traning-calender" element={<TrainingCalender />} />
        <Route
          path="/our-services/course/course-description"
          element={<PowerPlatform />}
          replace={true}
          exact={true}
        />
        <Route
          path="/signin"
          element={
            <AuthRedirectRoute>
              <SignIn />
            </AuthRedirectRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <AuthRedirectRoute>
              <SignUp />
            </AuthRedirectRoute>
          }
        />
        <Route
          path="/our-services/cloud-training/course"
          element={<CategoryCourses />}
        />
        <Route
          path="/our-services/microsoft-security"
          element={<MicrosoftSecurity />}
        />
        <Route
          path="/googleAuthRedirect"
          element={<GoogleAuthRedirectPage />}
        />
        <Route path="/authRedirectPage" element={<AuthRedirectPage />} />
        <Route
          path="/partnerCourseDescription"
          element={<PartnerCourseDescription />}
        />
        <Route
          path="/promotion"
          element={<PromotionPage />}
          replace={true}
          exact={true}
        />
        <Route
          path="/forgetResetPassword"
          element={<ForgetResetPassword />}
          replace={true}
          exact={true}
        />
        <Route
          path="/forgetPassword"
          element={<ForgotPassword />}
          replace={true}
          exact={true}
        />
      </Routes>

      {/* </Router> */}

      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;
