import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { verifyPartnersAccessToken } from "../services/verifyAccessToken";
import LoadingPage from "./LoadingPage";

const IsDisplayPartnersPageContent = ({ children, isMessage }) => {
  
  let credentials = JSON.parse(localStorage?.getItem("techcsrf"));

  const [isAuthenticate, setIsAuthenticate] = useState(false);

  const handleAccessVerification = async (accessToken) => {
    try {
      let response = await verifyPartnersAccessToken(accessToken);
      console.log(response);
      if (response.status === 200) {
        isMessage === true ? setIsAuthenticate(false) :setIsAuthenticate(true);
      } else {
        isMessage === true ? setIsAuthenticate(true) :setIsAuthenticate(false);
      }
    } catch (err) {
        isMessage === true ? setIsAuthenticate(true) :setIsAuthenticate(false);
    }
  };

  useEffect(() => {
    if (credentials === undefined || credentials === null) {
        isMessage === true ? setIsAuthenticate(true) :setIsAuthenticate(false);
    } else {
      handleAccessVerification(credentials?.accessToken);
    }
  }, []);

  return <>{isAuthenticate ? children : null}</>;
};

export default IsDisplayPartnersPageContent;
