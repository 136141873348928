const CalenderIc = () => {
  return (
    <>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="19" cy="19" r="19" fill="#F1F1F1" />
        <path
          d="M24.7 12.5238V12.5738H24.75H25.5C26.2966 12.5738 26.95 13.2364 26.95 14.0476V25.4762C26.95 26.2874 26.2966 26.95 25.5 26.95H13.5C12.7034 26.95 12.05 26.2874 12.05 25.4762V14.0476C12.05 13.2364 12.7034 12.5738 13.5 12.5738H14.25H14.3V12.5238V11.7619C14.3 11.3697 14.6159 11.05 15 11.05C15.3841 11.05 15.7 11.3697 15.7 11.7619V12.5238V12.5738H15.75H23.25H23.3V12.5238V11.7619C23.3 11.3697 23.6159 11.05 24 11.05C24.3841 11.05 24.7 11.3697 24.7 11.7619V12.5238ZM13.5 16.2833H13.45V16.3333V24.7143C13.45 25.1602 13.8091 25.5262 14.25 25.5262H24.75C25.1909 25.5262 25.55 25.1602 25.55 24.7143V16.3333V16.2833H25.5H13.5Z"
          fill="black"
          stroke="#F1F1F1"
          stroke-width="0.1"
        />
      </svg>
    </>
  );
};

export default CalenderIc;
