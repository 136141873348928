const TechnoFocusLogoSm = () => {
  return (
    <>
      <svg
        width="135"
        height="30"
        viewBox="0 0 135 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.693 0H19.7179C19.6104 0.173975 19.4899 0.339373 19.3575 0.494737C16.3675 3.52927 13.3738 6.55863 10.3766 9.58282C10.235 9.70974 10.1224 9.86656 10.0467 10.0423C9.97099 10.218 9.934 10.4084 9.93833 10.6002C9.9536 16.8429 9.95768 23.0852 9.95056 29.3269C9.95056 29.5518 9.98109 29.7767 9.99789 30H19.7149C19.7271 29.7022 19.75 29.4339 19.75 29.1656C19.75 23.0211 19.75 16.8766 19.75 10.732C19.75 9.96899 19.75 9.96898 20.5135 9.96898H29.6624C29.6624 9.25248 29.6624 8.6461 29.6624 8.03971L29.693 0Z"
          fill="white"
        />
        <path d="M0 9.98139H9.85901V0H0V9.98139Z" fill="white" />
        <path
          d="M73.7454 14.2448C73.5775 13.0568 72.8521 12.3279 71.722 12.131C70.4637 11.9123 69.3367 12.1449 68.4998 13.2274C68.4632 13.2755 68.396 13.2972 68.2647 13.3825V9.07422H66.0244V20.7709C66.1939 20.7802 66.3298 20.7941 66.4581 20.7941H68.2906C68.2906 20.5243 68.2906 20.3289 68.2906 20.1397C68.2998 18.6338 68.2723 17.1263 68.3319 15.6235C68.3792 14.4573 69.0481 13.8509 70.0941 13.8788C71.0608 13.9052 71.5327 14.4588 71.5663 15.6639C71.5892 16.5246 71.5754 17.3853 71.577 18.2461V20.7631H73.8814C73.8814 19.3673 73.8951 18.0057 73.8814 16.6425C73.8875 15.8411 73.8421 15.0402 73.7454 14.2448Z"
          fill="white"
        />
        <path
          d="M48.8398 9.07129H39.3809V11.1851H42.8581V20.7478H45.3778V12.4491C45.3778 11.1805 45.3778 11.1805 46.6133 11.1805H48.8398V9.07129Z"
          fill="white"
        />
        <path
          d="M81.8974 12.4006C81.2264 12.0666 80.4676 11.9602 79.7326 12.0973C78.9977 12.2344 78.3256 12.6077 77.8154 13.1621C77.7589 13.2257 77.6887 13.2784 77.5772 13.3792C77.5451 12.9822 77.5207 12.6596 77.4947 12.3448H75.4087V20.7567H77.6489C77.6489 19.4168 77.6337 18.1094 77.6489 16.8035C77.6436 16.2184 77.6979 15.6342 77.8108 15.0604C78.0002 14.2244 78.6309 13.8305 79.5074 13.8801C80.3259 13.9251 80.7963 14.2865 80.8513 15.1953C80.9475 16.7741 80.9231 18.3606 80.9475 19.944C80.9475 20.2108 80.9475 20.4775 80.9475 20.7676H83.1389C83.1389 18.57 83.1832 16.4251 83.116 14.2787C83.0977 13.4785 82.6502 12.7977 81.8974 12.4006Z"
          fill="white"
        />
        <path
          d="M123.832 16.4439C123.832 17.0092 123.766 17.5726 123.635 18.1219C123.422 18.9206 122.786 19.2603 121.865 19.1858C121.12 19.1253 120.671 18.7516 120.62 17.9017C120.533 16.4222 120.55 14.9349 120.525 13.4507C120.525 13.0847 120.525 12.7187 120.525 12.3232H118.336C118.336 14.5224 118.276 16.6657 118.362 18.8105C118.371 19.28 118.532 19.7332 118.822 20.0995C119.111 20.4659 119.511 20.7248 119.96 20.8359C121.397 21.2454 122.72 21.1027 123.735 19.8325C123.796 19.784 123.863 19.7444 123.934 19.7147C123.952 20.0977 123.966 20.4219 123.981 20.7475H126.083V12.3465H123.848C123.848 13.7485 123.859 15.0962 123.832 16.4439Z"
          fill="white"
        />
        <path
          d="M134.996 17.9249C134.943 16.8253 134.223 16.2748 133.28 15.9615C132.858 15.8219 132.421 15.7335 131.995 15.6125C131.385 15.4388 130.755 15.3024 130.178 15.0527C130.053 14.9782 129.948 14.8736 129.872 14.748C129.797 14.6225 129.753 14.48 129.744 14.3331C129.766 14.1035 130.065 13.8011 130.309 13.7127C131.363 13.3173 132.24 13.7267 132.647 14.7441H134.72C134.7 13.6057 134.057 12.7279 132.918 12.3448C131.676 11.9387 130.339 11.9425 129.1 12.3557C128.826 12.4496 128.569 12.5861 128.336 12.7605C127.588 13.3048 127.359 14.0942 127.411 14.9782C127.463 15.8622 128.01 16.3616 128.759 16.6454C129.317 16.8563 129.908 16.9727 130.479 17.1463C131.05 17.32 131.678 17.4674 132.23 17.7279C132.812 18.0024 132.86 18.6398 132.394 19.0787C131.604 19.82 129.667 19.544 129.424 18.3855C129.401 18.3127 129.361 18.2465 129.308 18.1926C129.255 18.1386 129.19 18.0984 129.118 18.0753C128.507 18.0443 127.897 18.0614 127.223 18.0614C127.26 18.3436 127.267 18.5561 127.316 18.7577C127.4 19.143 127.572 19.5028 127.819 19.8078C128.065 20.1129 128.379 20.3545 128.735 20.5133C130.331 21.2406 131.965 21.2608 133.556 20.5133C134.604 20.0201 135.046 19.0834 134.996 17.9249Z"
          fill="white"
        />
        <path
          d="M115.571 17.5419C115.199 17.5233 114.993 17.6024 114.875 18.0212C114.644 18.8323 114.095 19.2262 113.311 19.2619C112.482 19.3068 111.882 18.9734 111.551 18.201C111.314 17.6739 111.195 17.1002 111.202 16.5208C111.208 15.9415 111.341 15.3709 111.59 14.8496C111.934 14.1021 112.545 13.764 113.374 13.809C114.29 13.854 114.748 14.4092 114.942 15.2327H117.153C117.071 14.0044 116.498 13.1282 115.474 12.5823C114.891 12.2704 114.25 12.0856 113.593 12.0397C112.935 11.9937 112.276 12.0877 111.656 12.3155C109.884 12.9266 109.11 14.3316 108.956 16.1182C108.803 17.939 109.309 19.541 110.982 20.4607C112.575 21.3369 114.229 21.2873 115.756 20.2404C116.645 19.6325 117.063 18.702 117.198 17.5435C116.604 17.5481 116.086 17.5667 115.571 17.5419Z"
          fill="white"
        />
        <path
          d="M62.6651 17.8908C62.3597 18.8538 61.8221 19.2726 60.9013 19.2726C60.0629 19.2726 59.4505 18.8073 59.1619 17.9063C58.905 17.102 58.8891 16.2384 59.1161 15.4249C59.394 14.3982 59.9911 13.8554 60.8585 13.8197C61.845 13.7794 62.2863 14.119 62.691 15.2357H64.8687C64.8152 14.3346 64.5266 13.6848 63.9433 13.1234C62.3123 11.5555 59.0657 11.7183 57.6821 13.5142C56.4299 15.1395 56.3001 16.9882 57.1614 18.8182C57.9952 20.5924 59.5666 21.1445 61.4098 21.0142C63.3461 20.887 64.8641 19.3889 64.8855 17.579H62.7735C62.7307 17.7078 62.6956 17.8008 62.6651 17.8908Z"
          fill="white"
        />
        <path
          d="M95.8795 9.51971C94.9205 10.1742 94.8716 11.2024 94.8701 12.2601L93.5293 12.3268V13.8296H94.9037V20.748H97.1944V13.8172H98.7383V12.2663H97.1241C97.2142 11.8011 97.1653 11.2489 97.3868 11.1016C97.7945 10.8287 98.3595 10.7914 98.8879 10.655V9.07927C97.8281 9.0343 96.8019 8.89161 95.8795 9.51971Z"
          fill="white"
        />
        <path
          d="M55.8646 17.1168C55.8371 16.6391 55.8356 16.2173 55.7868 15.8017C55.4294 12.7325 52.4103 11.1211 49.6997 12.5448C47.7984 13.5436 46.9509 16.0203 47.7374 18.2939C48.4261 20.2899 50.4144 21.3677 52.6378 20.9661C54.1985 20.6854 55.1667 19.7579 55.6722 18.1869C55.1942 18.1869 54.7727 18.2055 54.3543 18.1869C53.9359 18.1683 53.6595 18.2458 53.3816 18.6149C52.8776 19.2834 51.717 19.4726 50.871 19.1236C50.1548 18.829 49.7593 18.0938 49.8265 17.1199L55.8646 17.1168ZM51.5994 13.8166C52.7386 13.7793 53.4075 14.409 53.4625 15.5985H49.8341C49.8142 14.5439 50.4999 13.8507 51.5994 13.8166Z"
          fill="white"
        />
        <path
          d="M88.8148 12.0625C86.1791 12.0625 84.4595 13.8289 84.458 16.543C84.4565 19.257 86.1791 21.025 88.8148 21.025C91.4506 21.025 93.164 19.2601 93.1595 16.5414C93.1549 13.8227 91.4644 12.0625 88.8148 12.0625ZM90.6657 17.9232C90.3847 18.8274 89.7204 19.2802 88.7904 19.274C87.8863 19.274 87.2633 18.8243 86.9716 17.9294C86.6685 17.0345 86.6685 16.0623 86.9716 15.1673C87.277 14.2368 87.9123 13.7964 88.8393 13.8119C89.7662 13.8274 90.4152 14.2942 90.6794 15.2077C90.783 15.6616 90.8595 16.1215 90.9085 16.5848C90.856 17.0358 90.7748 17.4829 90.6657 17.9232Z"
          fill="white"
        />
        <path
          d="M103.619 12.0625C100.977 12.0532 99.2771 13.8026 99.2725 16.5368C99.2679 19.271 100.963 21.0343 103.605 21.0343C106.247 21.0343 107.972 19.2586 107.972 16.5569C107.972 13.8553 106.262 12.0718 103.619 12.0625ZM105.451 17.9558C105.163 18.8445 104.535 19.2772 103.619 19.2787C102.702 19.2803 102.061 18.8429 101.786 17.9605C101.464 17.0085 101.48 15.9721 101.832 15.0309C102.137 14.2027 102.748 13.8088 103.622 13.815C104.495 13.8212 105.135 14.2213 105.416 15.0293C105.586 15.5194 105.639 16.0514 105.743 16.5554C105.67 17.0276 105.57 17.4951 105.445 17.9558H105.451Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default TechnoFocusLogoSm