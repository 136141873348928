import React from "react";
import "./CourseCard.css";
import CourseImg from "../../assets/images/Homepage/courseImg1.png";
import Cloud from "../../assets/svgs/cloud.svg";
import { useDispatch } from "react-redux";
import { handleActiveCourse } from "../../redux/slices/course/courseSlice";
import { Link, useNavigate } from "react-router-dom";
import LevelIcn from "../../assets/svgs/LevelIcn";
const CourseCard = ({ index, cardData, style = {}, courseState }) => {
  const dispatch = useDispatch();

  const activeCourse = (cardData) => {
    dispatch(handleActiveCourse(cardData));
  };
  return (
    <Link
      to={
        courseState === "upcomming"
          ? `/our-services/course/course-description?courseId=${cardData.id}&courseType=${cardData.course_type}&courseState=upcomming`
          : `/our-services/course/course-description?courseId=${cardData.id}&courseType=${cardData.course_type}`
      }
      onClick={() => activeCourse(cardData)}
      className="card border-15p shadow-sm h-100 td-none"
      style={style}
    >
      {cardData?.course_banner ? (
        <>
          <img
            alt="courseImage"
            className="card-img-top"
            src={cardData.course_banner}
          />
        </>
      ) : (
        <>
          <img alt="courseImage" className="card-img-top" src={CourseImg} />
        </>
      )}

      <div className="card-body  position-relative">
        <img
          alt="courseImage"
          className="position-absolute"
          style={{ top: "-30px", right: "10px", width: "68px" }}
          src={Cloud}
        />
        <h5 style={{ height: "50px" }} className="card-title mb-1">
          {cardData.course_name.slice(0, 40)} ...
        </h5>
        {/* <h6 className="card-title mb-3 font-weight-normal">Lorem ipsum dolor sit</h6> */}
        <p
          style={{ height: "56px" }}
          className="course-description font-weight-light"
        >
          {cardData.course_about.slice(0, 88)}...
        </p>
        <div className="row">
          <div className="col-6 d-flex align-items-center course-description ">
            {/* <i
              className="bi bi-reception-4 me-2 "
              style={{ color: "#1B449c" }}
            /> */}
            <LevelIcn/>
            <span>
              {cardData.course_level.charAt(0).toUpperCase() +
                cardData.course_level.slice(1)}
            </span>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-center course-description ">
            <i
              className="bi bi-clock-fill me-2 "
              style={{ color: "#1B449c" }}
            />
            <span>{cardData.course_duration}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
