import { configureStore } from "@reduxjs/toolkit";
import courseReducer from "../redux/slices/course/courseSlice";
import courseCategoryReducer from "../redux/slices/courseCategory/courseCategorySlice";
import authenticationReducer from "../redux/slices/authentication/authSlice";
import userProfileReducer from "../redux/slices/userProfile/userProfileSlice"
import courseCartReducer from "../redux/slices/courseCart/courseCartSlice"
import testimonialReducer from "../redux/slices/testimonial/testimonialSlice";
import partnerCourseReducer from "../redux/slices/partnerCourse/partnerCourseSlice"
import languageReducer from "./slices/language/languageSlice";
import trainingCalenderReducer from "./slices/trainingCalender/trainingCalenderSlice";
import couponCodeReducer from "./slices/coupon/couponSlice"


export const store = configureStore({
  reducer: {
    courseState: courseReducer,
    courseCategoryState: courseCategoryReducer,
    authenticationState: authenticationReducer,
    userProfileState: userProfileReducer,
    courseCartState: courseCartReducer,
    testimonialState: testimonialReducer,
    partnerCourseState:partnerCourseReducer,
    languageState: languageReducer,
    trainingCalenderState: trainingCalenderReducer,
    couponCodeState: couponCodeReducer
  },
});
