const WeekendCourseIc = () => {
  return (
    <>
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24.5" cy="24" r="24" fill="#F1F1F1" />
        <mask
          id="mask0_8_5130"
          style={{"mask-type":"alpha"}}
          maskUnits="userSpaceOnUse"
          x="12"
          y="12"
          width="25"
          height="24"
        >
          <rect x="12.5" y="12" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_8_5130)">
          <path
            d="M22.175 25.7L23.05 22.85L20.75 21H23.6L24.5 18.2L25.4 21H28.25L25.925 22.85L26.8 25.7L24.5 23.925L22.175 25.7ZM18.5 35V27.275C17.8667 26.575 17.375 25.775 17.025 24.875C16.675 23.975 16.5 23.0167 16.5 22C16.5 19.7667 17.275 17.875 18.825 16.325C20.375 14.775 22.2667 14 24.5 14C26.7333 14 28.625 14.775 30.175 16.325C31.725 17.875 32.5 19.7667 32.5 22C32.5 23.0167 32.325 23.975 31.975 24.875C31.625 25.775 31.1333 26.575 30.5 27.275V35L24.5 33L18.5 35ZM24.5 28C26.1667 28 27.5833 27.4167 28.75 26.25C29.9167 25.0833 30.5 23.6667 30.5 22C30.5 20.3333 29.9167 18.9167 28.75 17.75C27.5833 16.5833 26.1667 16 24.5 16C22.8333 16 21.4167 16.5833 20.25 17.75C19.0833 18.9167 18.5 20.3333 18.5 22C18.5 23.6667 19.0833 25.0833 20.25 26.25C21.4167 27.4167 22.8333 28 24.5 28ZM20.5 32.025L24.5 31L28.5 32.025V28.925C27.9167 29.2583 27.2877 29.5207 26.613 29.712C25.9377 29.904 25.2333 30 24.5 30C23.7667 30 23.0623 29.904 22.387 29.712C21.7123 29.5207 21.0833 29.2583 20.5 28.925V32.025Z"
            fill="#1C1B1F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.05 22.85L22.175 25.7L24.5 23.925L26.8 25.7L25.925 22.85L28.25 21H25.4L24.5 18.2L23.6 21H20.75L23.05 22.85Z"
            fill="#1B449C"
          />
        </g>
      </svg>
    </>
  );
};

export default WeekendCourseIc