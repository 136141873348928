const WebIc2 = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#F1F1F1" />
        <g clip-path="url(#clip0_8_5123)">
          <path
            d="M14.0002 27.002H34.0002M14.0002 19.002H34.0002M24.0002 34.002C30.0752 34.002 35.0002 29.077 35.0002 23.002C35.0002 16.927 30.0752 12.002 24.0002 12.002C17.9252 12.002 13.0002 16.927 13.0002 23.002C13.0002 29.077 17.9252 34.002 24.0002 34.002ZM24.0002 34.002C27.0002 34.002 28.0002 29.002 28.0002 23.002C28.0002 17.002 27.0002 12.002 24.0002 12.002C21.0002 12.002 20.0002 17.002 20.0002 23.002C20.0002 29.002 21.0002 34.002 24.0002 34.002Z"
            stroke="#1B449C"
            stroke-width="1.8"
          />
        </g>
        <defs>
          <clipPath id="clip0_8_5123">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(12 11)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default WebIc2;
