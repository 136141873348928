import BuyCourseIc from "../../../assets/svgs/BuyCourseIc.png";
import CommunityIc from "../../../assets/svgs/CommunityIc";
import LabIc from "../../../assets/svgs/LabIc";
import LevelIc from "../../../assets/svgs/LevelIc";
import WebIc2 from "../../../assets/svgs/WebIc2";
import WeekendCourseIc from "../../../assets/svgs/WeekendCourseIc";
import { useDispatch, useSelector } from "react-redux";
import {
  addCourseCart,
  addCourseToCartLocalStorage,
} from "../../../redux/slices/courseCart/courseCartActions";
import { verifyEnrolledCourse } from "../../../redux/slices/course/courseSliceActions";
import "./BuyCourse.css";
import useLocalStorge from "../../../hooks/useLocalStorage";
import { changeLanguageText } from "../../../services/helper";
import { useEffect } from "react";
import { useState } from "react";
import { capitalizeFirstLetter } from "../../../utils/helperFunction";
import { useSearchParams } from "react-router-dom";
import CourseCard from "../../../components/CourseCard/CourseCard";
import { Spinner } from "react-bootstrap";
import AppModal from "../../../components/AppModal/AppModal";

export const BuyCoursesBorderVertical = ({ borderAlign }) => {
  return (
    <>
      <div
        className={
          borderAlign === "right"
            ? "buy-course-border-vertical-right"
            : "buy-course-border-vertical-left"
        }
      ></div>
    </>
  );
};

export const BuyCoursesBorderHorizontal = () => {
  return (
    <>
      <div className="buy-course-border-horizontal"></div>
    </>
  );
};

const BuyCourses = ({ courseData }) => {
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams();
  const courseState = queryParameters.get("courseState");
  const localStorageData = useLocalStorge();
  const addCourseToCart = (courseId) => {
    dispatch(addCourseCart(localStorageData.userId, courseId));
  };
  const courseId = queryParameters.get("courseId");

  const activeLanguage = useSelector((state) => state.languageState.lang);
  const isEnrolled = useSelector((state) => state.courseState.isEnrolled);
  const isLoading = useSelector((state) => state.courseCartState.loading);
  const [pageData, setPageData] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onRequestSubmit = () => {
    setShow(false)
  }

  const getTranslatedData = async () => {
    setPageData({
      off: await changeLanguageText("15 % OFF", activeLanguage),
      key: await changeLanguageText("Key Highlights", activeLanguage),
      search: await changeLanguageText("Search", activeLanguage),
      support: await changeLanguageText("Community Support", activeLanguage),
      hands: await changeLanguageText("Hands on Lab", activeLanguage),
      multi: await changeLanguageText("Multi-lingual", activeLanguage),
      weekend: await changeLanguageText(" Weekend Course", activeLanguage),
      buy: await changeLanguageText("Buy Now", activeLanguage),
      onRequest: await changeLanguageText("On Request", activeLanguage)
    });
  };

  useEffect(() => {
    getTranslatedData();
  }, [activeLanguage]);

  // useEffect(() => {
  //   console.log(isLoading)
  // },[isLoading])

  useEffect(() => {
    dispatch(verifyEnrolledCourse(courseId));
  }, []);

  return (
    <>
      <div className="buy-course-con">
        <div className="buy-course-wrap">
          <div className="buy-course-hd">
            <div className="buy-course-hd-lf">
              <div className="buy-course-icon">
                <img  style={{width:"55px"}} src={courseData?.category?.category_icon} alt="icon" />
              </div>
              <div className="buy-course-content">
                <div className="buy-course-content-hd">
                  {courseData?.course_name}
                </div>
                <div className="buy-course-content-title">
                  {courseData?.category?.category_name}
                </div>
              </div>
            </div>
            <div style={courseData.course_on_request === true? {display:"none"} : null} className="buy-course-hd-rt">
              <div className="buy-course-off-price">
                $
                {courseData.course_mrp -
                  (courseData?.course_mrp / 100) * courseData?.course_discount}
              </div>
              <div className="buy-course-price">${courseData.course_mrp}</div>
              <div className="buy-course-discount">
                {courseData.course_discount}% OFF
              </div>
            </div>
          </div>
          <div className="buy-course-key-highlights">
            <div className="buy-course-key-tx">{pageData?.key}</div>
            <div className="key-highlights-con">
              <div className="key-highlights-item">
                <BuyCoursesBorderVertical borderAlign="right" />
                <BuyCoursesBorderHorizontal />
                <LevelIc />
                <div className="key-highlights-item-tx">
                  Course Level: &nbsp;
                  {courseData?.course_level
                    ? capitalizeFirstLetter(courseData?.course_level)
                    : null}
                </div>
              </div>
              <div className="key-highlights-item">
                <BuyCoursesBorderVertical borderAlign="right" />
                <BuyCoursesBorderHorizontal />
                <LevelIc />
                <div className="key-highlights-item-tx">
                  Trainer Level: &nbsp;
                  {courseData?.course_trainer_level
                    ? courseData?.course_trainer_level.map((ele, idx) => {
                      return idx < courseData?.course_trainer_level?.length - 1 ? `${capitalizeFirstLetter(ele)}, ` : `${capitalizeFirstLetter(ele)}`
                    }) 
                    : null}
                </div>
              </div>
              <div className="key-highlights-item">
                <BuyCoursesBorderHorizontal />
                {courseData?.course_lang && <WebIc2 />}
                {courseData?.course_lang && (
                  <div className="key-highlights-item-tx">
                    Langauge:{" "}
                    {/* {courseData.course_lang.map((ele) => {
                      let capi = capitalizeFirstLetter(ele);
                      return `${capi}`;
                    })} */}
                    {courseData?.course_lang
                    ? courseData?.course_lang.map((ele, idx) => {
                      return idx < courseData?.course_lang?.length - 1 ? `${capitalizeFirstLetter(ele)}, ` : `${capitalizeFirstLetter(ele)}`
                    }) 
                    : null}
                  </div>
                )}
              </div>
              <div className="key-highlights-item">
                <BuyCoursesBorderVertical borderAlign="right" />
                {courseData?.course_lab === true ? <LabIc /> : null}
                {courseData?.course_lab === false &&
                courseData?.course_weekend === true ? (
                  <WeekendCourseIc />
                ) : null}
                {courseData?.course_lab === true ? pageData?.hands : null}
                {courseData?.course_lab === false &&
                courseData?.course_weekend === true
                  ? pageData?.weekend
                  : null}
                {/* {courseData?.course_lab === true ? (
                  <div className="key-highlights-item-tx"></div>
                ): ( <div className="key-highlights-item-tx"></div>)} */}
              </div>
              <div className="key-highlights-item">
                <BuyCoursesBorderVertical borderAlign="right" />
                {courseData?.course_weekend === true &&
                courseData?.course_lab === true ? (
                  <WeekendCourseIc />
                ) : null}
                {courseData?.course_weekend === true &&
                courseData?.course_lab === true ? (
                  <div className="key-highlights-item-tx">
                    {pageData?.weekend}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="key-highlights-item"></div>
          </div>
          {courseState !== "upcomming" ? (
            <div className="buy-course-btn-con">
              {isEnrolled === true ? (
                <a href={courseData.course_link} target="_blank">
                  <button
                    // onClick={() =>
                    //   // dispatch(addCourseToCartLocalStorage(courseData))
                    //   // window.open(courseData.course_link, "_blank")
                    // }
                    className="buy-course-btn"
                  >
                    Course Link
                  </button>
                </a>
              ) : courseData?.course_on_request === true ? (
                <button
                  className="buy-course-btn"
                  onClick={handleShow}
                >
                  {pageData?.onRequest}
                </button>
              ) : (
                <button
                  onClick={() =>
                    dispatch(addCourseToCartLocalStorage(courseData))
                  }
                  className="buy-course-btn"
                >
                  {isLoading === "loading" ? <Spinner/> : pageData?.buy}
                </button>
              )}
            </div>
          ) : null}
          
        </div>
      </div>
      <AppModal courseData={courseData} handleClose={handleClose} onRequestSubmit={onRequestSubmit} show={show}/>
    </>
  );
};

export default BuyCourses;
