import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lodingApi: "idle",
    testimonials: [],
    apiErr: "",
};

const testimonial = createSlice({
    name: "testimonial",
    initialState,
    reducers: {
        handleLoding: (state, { payload }) => {
            state.lodingApi = payload;
        },
        handleApiErr: (state, { payload }) => {
            state.apiErr = payload;
        },
        handleTestimonial: (state, { payload }) => {
            state.testimonials = payload
        }
    },
});

export const { handleLoding, handleApiErr, handleTestimonial } = testimonial.actions;
export default testimonial.reducer;
