import { createSlice } from "@reduxjs/toolkit";

const initialState = {lang: "en"};

const language = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLanguage: (state, { payload }) => {
      state.lang = payload;
      // console.log(payload, 23423423423423)
    }
  },
});

export const { changeLanguage } = language.actions;
export default language.reducer;
