import React, { useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import Services1 from "../../assets/images/OurServices/services1.png";
import Services2 from "../../assets/images/OurServices/service2.png";
import Services3 from "../../assets/images/OurServices/services3.png";
import BG from "../../assets/images/OurServices/bg.png";
import "./OurServices.css";
import ReactOwlCarousel from "react-owl-carousel";
import CourseCard from "../../components/CourseCard/CourseCard";
import ChallengeCard from "../../components/ChallengeCard/ChallengeCard";
import { Link } from "react-router-dom";
import PrimaryCategories from "../../components/PrimaryCategories/PrimaryCategories";
import { readCourseData } from "../../redux/slices/course/courseSliceActions";
import { readCourseCategoryData } from "../../redux/slices/courseCategory/courseCategoryAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLanguageText } from "../../services/helper";
import CourseCarousel from "../../components/CourseCarousel/CourseCarousel";

const OurServices = () => {
  const navigate = useNavigate();
  const title = "Our Services";
  const crumbs = [{ name: "Our Services", link: "/our-services" }];

  const courseData = useSelector((state) => state.courseState.course);
  const handsOnLabData = useSelector((state) => state.courseState.handsOnLab);
  const challengeExperinceData = useSelector(
    (state) => state.courseState.challengeExperince
  );

  const dispatch = useDispatch();

  const handleNavigate = (couseType) => {
    if (couseType === "course") {
      navigate("/our-services/course", { replace: true });
    }
    if (couseType === "handsOnLab") {
      navigate("/our-services/hands-on-labs", { replace: true });
    }
    if (couseType === "challengeExperinece") {
      navigate("/our-services/challenge-experience", { replace: true });
    }
  }

  const handleCourseCategories = (courseCategoryObj) => {
    if (courseCategoryObj.courseType === "All Categories") {
      dispatch(readCourseData(courseCategoryObj.courseType));
    } else {
      dispatch(
        readCourseData(
          courseCategoryObj.courseType,
          courseCategoryObj.activeCategory.value
        )
      );
    }
  };

  const handleCategoryRedirectCourses = (categoryId, categoryName) => {
    if (!categoryId || !categoryName) {
      navigate(`/our-services/cloud-training/course`);
    } else {
      navigate(
        `/our-services/cloud-training/course?categoryId=${categoryId}&categoryName=${categoryName}`
      );
    }
  }

  const handleCategoryRedirectHandsOnLab = (categoryId, categoryName) => {
    if (!categoryId || !categoryName) {
      navigate(`/our-services/hands-on-labs`);
    } else {
      navigate(
        `/our-services/hands-on-labs?categoryId=${categoryId}&categoryName=${categoryName}`
      );
    }
  }

  const handleCategoryRedirectChallengeExperience = (categoryId, categoryName) => {
    if (!categoryId || !categoryName) {
      navigate(`/our-services/challenge-experience`);
    } else {
      navigate(
        `/our-services/challenge-experience?categoryId=${categoryId}&categoryName=${categoryName}`
      );
    }
  }



  const activeLanguage = useSelector((state) => state.languageState.lang)
  const [pageData, setPageData] = useState({})

  const getTranslatedData = async () => {
    setPageData({
      ourService: await changeLanguageText("Our Services", activeLanguage),
      special: await changeLanguageText("SPECIALISED", activeLanguage),
      microsoft: await changeLanguageText("Microsoft Cloud Training", activeLanguage),
      microsoft1: await changeLanguageText("Understand the ins and outs of cloud services with our best-in-class certified trainers. Our meticulously moduled training programs enable your learning process in the latest Microsoft cloud technologies. Microsoft cloud services help you optimise your work and increase the productivity of your organisation. Encash the benefits of cloud technologies with Technofocus.", activeLanguage),
      explore: await changeLanguageText("Explore", activeLanguage),
      feature: await changeLanguageText("Featured", activeLanguage),
      course: await changeLanguageText("Courses", activeLanguage),
      well: await changeLanguageText("Well Equipped", activeLanguage),
      hands: await changeLanguageText("Hands On Labs", activeLanguage),
      handsDesc: await changeLanguageText("Hands On Labs are virtual labs curated to enable your learning process. As a part of our training programs, Hands On Labs provide you with much-needed firsthand experience in Microsoft cloud technologies in a virtual environment. Get step-by-step instructions and understand the key concepts to the core with practical training.", activeLanguage),
      learnMore: await changeLanguageText("Learn More", activeLanguage),
      handson: await changeLanguageText("Hands On Lab", activeLanguage),
      datanotfound: await changeLanguageText("Data Not Found", activeLanguage),
      exploremore: await changeLanguageText("Explore More", activeLanguage),
      competitive: await changeLanguageText("Competitive", activeLanguage),
      challange: await changeLanguageText("Challenge Experience", activeLanguage),
      experience1: await changeLanguageText("With Challenge Experiences you get to solve business challenges and evaluate your progress. We allow you to apply what you have learned and assess how far you have advanced. With a top-down approach to learning, Challenge Experience is the perfect platform for professionals to push the boundaries of skill acquisition.", activeLanguage),




    })
  }


  useEffect(() => {
    getTranslatedData()
  }, [activeLanguage])



  useEffect(() => {
    dispatch(readCourseData("Hands_On_Lab"));
    dispatch(readCourseData("course"));
    dispatch(readCourseData("Challenge_Experience"));
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])

  return (
    <>
      {/* {console.log("rendered", handsOnLabData)} */}
      <HeroSection title={pageData?.ourService} crumbs={[{ name: pageData.ourService, link: "/our-services" }]} />

      <div className="main-container">
        <div className="row partnersRow">
          {/* left panel */}
          <div className="col-lg-6 h-100">
            <div className="mt-5 position-relative">
              <p className="abouts-us-title color-blue">{pageData?.special}</p>
              <h2>{pageData?.microsoft}</h2>
              <p className="text-muted">
                {pageData?.microsoft1}
              </p>
              <Link to="/our-services/cloud-training">
                <button className="service_btn   px-3 py-2 mt-2  ">
                  {pageData?.explore}
                  <i className=" service_btn_icon bi bi-arrow-up-right mx-2" />
                </button>
              </Link>
            </div>
          </div>

          {/* right panel */}
          <div className="col-lg-6 p-3 h-100">
            <div
              className="row px-2 p-lg-5 position-relative"
              style={{ height: "300px" }}
            >
              <img
                className="position-absolute h-100 service1"
                src={Services1}
              />
              <div class="ripple">
                <div class="circles">
                  <div class="circle1"></div>
                  <div class="circle2"></div>
                  <div class="circle3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="row my-2">
          <p className="h2">
            <span className="color-blue">{pageData?.feature}</span> {pageData?.course}
          </p>
        </div>

        <PrimaryCategories
          handleCourseCategories={handleCourseCategories}
          handleCategoryRedirect={handleCategoryRedirectCourses}
          courseType="course"
          showArrow={true}

        />
        {/* <ReactOwlCarousel
          className="owl-theme mt-4  "
          loop={false}
          margin={20}
          items={4}
          // nav= {true}
          // navText={ [`<div className='nav-button owl-prev'>‹</div>`, `<div className='nav-button owl-next'>›</div>`]}
          autoplay={false}
          dots={false}
          responsive={{
            0: { items: 1.2 },
            500: { items: 2 },
            800: { items: 3 },
            992: { items: 4 },
          }}
        >
          {courseData.length > 0
            ? courseData?.map((el, idx) => {
              return (<CourseCard index={idx + 1} cardData={el} />)
            })
            : <div>{pageData?.datanotfound}</div>}
        </ReactOwlCarousel> */}
        
        <CourseCarousel corPosProp={0}>
          {courseData.length > 0 ? (
            courseData?.map((el, idx) => {
              return (
                <CourseCard
                  index={idx + 1}
                  cardData={el}
                  style={{
                    minHeight: "370px",
                    width: "298px",
                    marginRight: "20px",
                  }}
                />
              );
            })
          ) : (
            <div>{pageData?.notFound}</div>
          )}
        </CourseCarousel>

        <div onClick={() => handleNavigate("course")} className="d-flex justify-content-end">
          <button className="service_btn1  px-3 py-2 mt-2 ">
            {pageData?.exploremore}
            <i className=" service_btn_icon bi bi-arrow-up-right mx-2" />
          </button>
        </div>
      </div>

      <div className="main-container">
        <div className="row">
          <div className="col-md-6 d-md-flex  p-4 m-auto">
            <div className="d-flex flex-column justify-content-center w-75">
              <div className="color-blue text-uppercase">{pageData?.well}</div>
              <h3>{pageData?.hands}</h3>
              <div className="text-muted">
                {pageData?.handsDesc}
              </div>
              <Link className="hands-on-labslinks" to="/our-services/hands-on-labs">
                <button className="homepage_learnMore  ">
                  {pageData?.learnMore}
                  <i className=" homepage_learnMore_ico bi bi-arrow-up-right mx-2" />
                </button>
              </Link>

            </div>
          </div>
          <div className="col-md-6 p-4 m-auto ourServiceBg">
            <div className="d-flex justify-content-center">
              <img src={Services2} className="w-75 h-75" />
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="row my-2">
          <p className="h2">
            <span className="color-blue">{pageData?.feature}</span> {pageData?.handson}
          </p>
        </div>

        <PrimaryCategories
          handleCourseCategories={handleCourseCategories}
          handleCategoryRedirect={handleCategoryRedirectHandsOnLab}
          courseType="Hands_On_Lab"
          showArrow={true}
        />
        {/* <ReactOwlCarousel
          className="owl-theme mt-4"
          loop={false}
          margin={20}
          items={4}
          // nav= {true}
          // navText={ [`<div className='nav-button owl-prev'>‹</div>`, `<div className='nav-button owl-next'>›</div>`]}
          autoplay={true}
          dots={false}
          responsive={{
            0: { items: 1.2 },
            500: { items: 2 },
            800: { items: 3 },
            992: { items: 4 },
          }}
        >
          {handsOnLabData.length > 0
            ? handsOnLabData?.map((el, idx) => (
              <CourseCard index={idx + 1} cardData={el} />
            ))
            : <div>{pageData?.datanotfound}</div>}
        </ReactOwlCarousel> */}
        
        <CourseCarousel corPosProp={0}>
          {handsOnLabData.length > 0 ? (
            handsOnLabData?.map((el, idx) => {
              return (
                <CourseCard
                  index={idx + 1}
                  cardData={el}
                  style={{
                    minHeight: "370px",
                    width: "298px",
                    marginRight: "20px",
                  }}
                />
              );
            })
          ) : (
            <div>{pageData?.notFound}</div>
          )}
        </CourseCarousel>
        <div onClick={() => handleNavigate("handsOnLab")} className="d-flex justify-content-end">
          <button className="service_btn1   px-3 py-2 mt-2  ">
            {pageData?.exploremore}
            <i className=" service_btn_icon bi bi-arrow-up-right mx-2" />
          </button>
        </div>
      </div>



      <div className="main-container">
        <div className="row">
          <div className="col-md-6 d-flex p-4 m-auto">
            <div className="d-flex flex-column justify-content-center w-75">
              <div className="color-blue text-uppercase">{pageData?.competitive}</div>
              <h3>{pageData?.challange}</h3>
              <div className="text-muted">
                {pageData?.experience1}

              </div>
              <Link to="/our-services/challenge-experience" className="hands-on-labslinks">
                <button className="homepage_learnMore  ">
                  {pageData?.learnMore}
                  <i className=" homepage_learnMore_ico bi bi-arrow-up-right mx-2" />
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-6 p-4 m-auto">
            <div className="d-flex justify-content-center">
              <img src={Services3} className="w-75 h-75" />
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="row my-2">
          <p className="h2">
            <span className="color-blue">{pageData?.feature}</span> {pageData?.challange}
          </p>
        </div>

        <PrimaryCategories
          handleCourseCategories={handleCourseCategories}
          courseType="Challenge_Experience"
          showArrow={true}
          handleCategoryRedirect={handleCategoryRedirectChallengeExperience}
        />
        {/* <ReactOwlCarousel
          className="owl-theme mt-4"
          loop={false}
          margin={20}
          items={4}
          // nav= {true}
          // navText={ [`<div className='nav-button owl-prev'>‹</div>`, `<div className='nav-button owl-next'>›</div>`]}
          autoplay={false}
          dots={false}
          responsive={{
            0: { items: 1.2 },
            500: { items: 2 },
            800: { items: 3 },
            992: { items: 4 },
          }}
        >
          {challengeExperinceData.length > 0
            ? challengeExperinceData?.map((el, idx) => (
              <CourseCard index={idx + 1} cardData={el} />
            ))
            : <div>{pageData?.datanotfound}</div>}
        </ReactOwlCarousel> */}
        
        <CourseCarousel corPosProp={0}>
          {challengeExperinceData.length > 0 ? (
            challengeExperinceData?.map((el, idx) => {
              return (
                <CourseCard
                  index={idx + 1}
                  cardData={el}
                  style={{
                    minHeight: "370px",
                    width: "298px",
                    marginRight: "20px",
                  }}
                />
              );
            })
          ) : (
            <div>{pageData?.notFound}</div>
          )}
        </CourseCarousel>
        <div onClick={() => handleNavigate("challengeExperinece")} className="d-flex justify-content-end">
          <button className="service_btn  px-3 py-2 mt-2 border-primary">
            {pageData?.exploremore}
            <i className=" service_btn_icon bi bi-arrow-up-right mx-2" />
          </button>
        </div>
      </div>
    </>
  );
};

export default OurServices;
