export const NextCarouselArrow = () => {
  return (
    <>
      <svg
        width="21"
        height="13"
        viewBox="0 0 21 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.1804 6.98195C20.4928 6.66953 20.4928 6.16299 20.1804 5.85057L15.0892 0.759406C14.7768 0.446986 14.2703 0.446986 13.9578 0.759406C13.6454 1.07183 13.6454 1.57836 13.9578 1.89078L18.4833 6.41626L13.9578 10.9417C13.6454 11.2542 13.6454 11.7607 13.9578 12.0731C14.2703 12.3855 14.7768 12.3855 15.0892 12.0731L20.1804 6.98195ZM0.881714 7.21626H19.6147V5.61626H0.881714V7.21626Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default NextCarouselArrow;
