const WebIc = () => {
  return (
    <>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="19" cy="19" r="19" fill="#F1F1F1" />
        <g clip-path="url(#clip0_8_5002)">
          <path
            d="M12.3335 21.666H25.6669M12.3335 16.3327H25.6669M19.0002 26.3327C23.0502 26.3327 26.3335 23.0493 26.3335 18.9993C26.3335 14.9493 23.0502 11.666 19.0002 11.666C14.9502 11.666 11.6669 14.9493 11.6669 18.9993C11.6669 23.0493 14.9502 26.3327 19.0002 26.3327ZM19.0002 26.3327C21.0002 26.3327 21.6669 22.9993 21.6669 18.9993C21.6669 14.9993 21.0002 11.666 19.0002 11.666C17.0002 11.666 16.3335 14.9993 16.3335 18.9993C16.3335 22.9993 17.0002 26.3327 19.0002 26.3327Z"
            stroke="black"
            stroke-width="0.9"
          />
        </g>
        <defs>
          <clipPath id="clip0_8_5002">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(11 11)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default WebIc;
