import {
  handleChallengeExperience,
  handleCourse,
  handleHandsOnLab,
  handleLoding,
  handleApiErr,
  handleActiveCourse,
  handleLatestCourse,
  handleUpcommingCourse,
  handleIsEnrolled,
} from "./courseSlice";
import http from "../../../services/axios/axiosInstance";
import { apiUrls } from "../../../api/apiUrls";

export const readCourseData =
  (courseType, courseCategoryId) => async (dispatch) => {
    try {
      dispatch(handleLoding("loading"));
      let config = {
        method: "get",
        url:
          courseCategoryId && courseCategoryId !== "All Categories"
            ? `${apiUrls.readCourseData}/${courseType}?categoryId=${courseCategoryId}`
            : `${apiUrls.readCourseData}/${courseType}`,
      };
      let response = await http(config);
      if (courseType === "course") {
        dispatch(handleCourse(response.data.data));
      }
      if (courseType === "Hands_On_Lab") {
        dispatch(handleHandsOnLab(response.data.data));
      }
      if (courseType === "Challenge_Experience") {
        dispatch(handleChallengeExperience(response.data.data));
      }
      dispatch(handleLoding("idle"));
    } catch (err) {
      dispatch(handleApiErr(`Error While Fetching ${courseType} Data`));
    }
  };

export const readOneCourseData = (courseType, courseId) => async (dispatch) => {
  try {
    dispatch(handleLoding("loading"));
    let config = {
      method: "get",
      url: `${apiUrls.readParticularCourse}?courseId=${courseId}`,
    };
    let response = await http(config);
    console.log(response)
    dispatch(handleActiveCourse(response.data.data[0]));
    dispatch(handleLoding("idle"));
  } catch (err) {
    dispatch(handleApiErr(`Error While Fetching ${courseType} Data`));
  }
};

export const readCourseSearchData =
  (courseType, courseCategoryId, courseSearchQuery) => async (dispatch) => {
    try {
      dispatch(handleLoding("loading"));
      let config = {
        method: "get",
        url: courseCategoryId
          ? `${apiUrls.readCourseData}?courseType=${courseType}&courseCategory=${courseCategoryId}&courseQuery=${courseSearchQuery}`
          : `${apiUrls.readCourseData}?courseType=${courseType}&courseQuery=${courseSearchQuery}`,
      };
      let response = await http(config);
      console.log(response);
      if (courseType === "course") {
        dispatch(handleCourse(response.data.data));
      }
      if (courseType === "Hands_On_Lab") {
        dispatch(handleHandsOnLab(response.data.data));
      }
      if (courseType === "Challenge_Experience") {
        dispatch(handleChallengeExperience(response.data.data));
      }
      console.log(response);
      dispatch(handleLoding("idle"));
    } catch (err) {
      console.log(err);
    }
  };

export const readLatestCourse = (courseCategoryId) => async (dispatch) => {
  try {
    dispatch(handleLoding("loading"));
    let config = {
      method: "get",
      url:
        courseCategoryId && courseCategoryId !== "All Categories"
          ? `${apiUrls.latestCourse}?categoryId=${courseCategoryId}`
          : `${apiUrls.latestCourse}`,
    };
    let response = await http(config);
    dispatch(handleLatestCourse(response?.data?.data));
    dispatch(handleLoding("idle"));
  } catch (err) {
    dispatch(handleApiErr(`Error While Fetching Courses Data`));
  }
};

export const readUpcommingCourse = (courseId) => async (dispatch) => {
  try {
    dispatch(handleLoding("loading"));
    
    let config = {
      method: "get",
      url: apiUrls.upcommingCourse,
    };
    let response = await http(config);
    dispatch(handleUpcommingCourse(response?.data?.data));
    dispatch(handleLoding("idle"));
  } catch (err) {
    dispatch(handleApiErr(`Error While Fetching Courses Data`));
  }
};

export const readUpcommingOneCourseData = (courseId) => async (dispatch) => {
  try {
    dispatch(handleLoding("loading"));
    let config = {
      method: "get",
      url: `upcommingCourse?courseId=${courseId}`,
    };
    let response = await http(config);
    dispatch(handleActiveCourse(response.data.data[0]));
    dispatch(handleLoding("idle"));
  } catch (err) {
    dispatch(handleApiErr(`Error While Fetching Data`));
  }
};

export const verifyEnrolledCourse = (courseId) => async (dispatch) => {
  try {
    let localStorageData = JSON.parse(localStorage.getItem("techcsrf"));
    console.log(courseId)
    let accessToken = localStorageData.accessToken;
    let config = {
      method: "post",
      url: apiUrls.verifyEnrolledCourse,
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      data:{
        courseId
      }
    };
    const response = await http(config);
    console.log(response.status)
    if(response.status === 200){
      dispatch(handleIsEnrolled(true))
    }else{
      dispatch(handleIsEnrolled(false))
    }
  } catch (err) {
    console.log(err)
    dispatch(handleIsEnrolled(false))
  }
};
