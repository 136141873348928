import { useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import CourseCard from "../../components/CourseCard/CourseCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  readCourseData,
  readCourseSearchData,
} from "../../redux/slices/course/courseSliceActions";
import { changeLanguageText } from "../../services/helper";
import { changeLanguage } from "../../redux/slices/language/languageSlice";
import { getDefaultLang } from "../../utils/helperFunction";
import { readCourseCategoryData } from "../../redux/slices/courseCategory/courseCategoryAction";

const CategoryCourses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  const [categoryCourseData, setCategoryCourseData] = useState([]);
  const [courseSearchQuery, setCourseSearchQuery] = useState();

  const categoryCourseDataInfo = useSelector(
    (state) => state.courseState.course
  );
  const categories = useSelector(
    (state) => state.courseCategoryState.courseCategory
  );
  const categoryId = queryParameters.get("categoryId");
  // const categoryName = queryParameters.get("categoryName");
  const [categoryName, setCategoryName] = useState("");
  const searchQuery = queryParameters.get("searchQuery");
  const title = categoryName !== null ? categoryName : "Courses";
  const crumbs = [
    { name: "Our Services", link: "/our-services" },
    { name: categoryName !== null ? categoryName : "Courses" },
  ];

  const handleSearchQuery = (e) => {
    setCourseSearchQuery(e.target.value);
  };

  const handleSearchCourse = () => {
    if (categoryId) {
      navigate(
        `/our-services/cloud-training/course?categoryId=${categoryId}&categoryName=${categoryName}&searchQuery=${courseSearchQuery}`,
        { replace: true }
      );
    } else {
      navigate(
        `/our-services/cloud-training/course?searchQuery=${courseSearchQuery}`,
        { replace: true }
      );
    }

    window.location.reload();
  };

  useEffect(() => {
    if (searchQuery && categoryId) {
      dispatch(readCourseSearchData("course", categoryId, searchQuery));
    } else if (searchQuery) {
      dispatch(readCourseSearchData("course", null, searchQuery));
    } else {
      dispatch(readCourseData("course", categoryId));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [activeLanguage, setActiveLanguage] = useState(getDefaultLang());
  const [pageData, setPageData] = useState({});

  const handleLanguage = (data) => {
    setActiveLanguage(data);
    localStorage.setItem("technoLang", data);
    dispatch(changeLanguage(data));
  };

  const getTranslatedData = async () => {
    setPageData({
      ourservice: await changeLanguageText("Our Services", activeLanguage),
      courses: await changeLanguageText("Course List", activeLanguage),
      notfound: await changeLanguageText("Data Not Found", activeLanguage),
      loadMore: await changeLanguageText(" Load More", activeLanguage),
      search: await changeLanguageText("Search", activeLanguage),
    });
  };

  useEffect(() => {
    setCategoryCourseData(categoryCourseDataInfo);
    getTranslatedData();
  }, [categoryCourseDataInfo, activeLanguage]);

  useEffect(() => {
    handleLanguage(getDefaultLang());
  }, []);

  useEffect(() => {
    dispatch(readCourseCategoryData());
  }, []);

  useEffect(() => {
    if (categories?.length > 0) {
      categories?.map((ele) => {
        if (ele?.id === categoryId) {
          setCategoryName(ele?.category_name);
        }
      });
    }
  }, [categories]);

  return (
    <div className="">
      <HeroSection
        title={title}
        crumbs={[
          { name: pageData?.ourservice, link: "/our-services" },
          { name: categoryName },
        ]}
      />

      <div className="position-relative d-none d-lg-flex">
        <div className="d-flex text-white justify-content-center mt-3 position-absolute partnersSearch">
          <div className="d-flex justify-content-between bg-white newsletterInputBox shadow w-100">
            <input
              type="text"
              className="ms-2 w-75 color-blue py-2 ps-3 newsletterInput"
              placeholder={pageData?.search}
              onChange={handleSearchQuery}
            />
            <button
              onClick={handleSearchCourse}
              className="newsletterSubmit px-3 t-12 d-flex align-items-center justify-content-center"
            >
              <i className="submitTextIcon text-white  bi bi-search"></i>
            </button>
          </div>
          <button className="newsletterSubmit shadow bg-white mx-2 px-3 t-12 d-flex align-items-center justify-content-center">
            <i className="filterIcon color-blue bi bi-filter"></i>
          </button>
        </div>
      </div>

      <div className="main-container">
        <div className="row my-2">
          <p className="h2">{pageData?.courses}</p>
        </div>
        <div className="row">
          {categoryCourseData.length > 0 ? (
            categoryCourseData?.map((el) => (
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <CourseCard cardData={el} />
              </div>
            ))
          ) : (
            <div>{pageData?.notfound}</div>
          )}
        </div>

        <div className="d-flex justify-content-center">
          {/* <button className="service_btn color-blue bg-white border px-3 py-2 mt-2 border-primary">
            <i className=" service_btn_icon bi bi-plus me-2" />
            {pageData?.loadMore}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default CategoryCourses;
