import { useEffect } from "react";
import TriangleBurger from "../../assets/svgs/TriangleBurger";
import AppTable from "../../components/AppTable/AppTable";
import AppTableMobile from "../../components/AppTable/AppTableMobile";
import HeroSection from "../../components/HeroSection/HeroSection";
import useWindowSize from "../../hooks/useWindowSize";
import { readTrainingCalender } from "../../redux/slices/trainingCalender/trainingCalenderAction";
import "./TraningCalender.css";
import { useDispatch, useSelector } from "react-redux";

const TrainingCalender = () => {
  const dispatch = useDispatch();
  const trainingCalender = useSelector(
    (state) => state.trainingCalenderState.trainingCalender
  );
  const crumbs = [{ name: "Course Purchased" }];
  const { width } = useWindowSize();
  useEffect(() => {
    dispatch(readTrainingCalender());
  },[]);
  return (
    <>
      <div className="calender-con">
        <HeroSection title="Course Purchased" crumbs={crumbs} />
        <div className="calender-wrap">
          <div className="calender-qun-hd">
            Course Purchased <TriangleBurger />
          </div>
          <div className="table-con">
            {width < 890 ? (
              <AppTableMobile cartCourses={trainingCalender} tableName="studyCalender" />
            ) : (
              <AppTable
                tableName="studyCalender"
                cartCourses={trainingCalender}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingCalender;
