import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lodingApi: "idle",
  trainingCalender: {},
  apiError: "",
};



const trainingCalender = createSlice({
  name: "trainingCalender",
  initialState,
  reducers: {
    handleLoding: (state, { payload }) => {
      state.lodingApi = payload;
    },
    handleTrainingCalender: (state, { payload }) => {
      state.trainingCalender = payload;
    },
    handleApiError: (state, { payload }) => {
      state.apiError = payload;
    },
  },
});

export const { handleLoding, handleApiError, handleTrainingCalender } =
trainingCalender.actions;
export default trainingCalender.reducer;
