import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courses: [],
  cartErr: "",
  loading:"idle",
  noOfCourse: 0 
};

const courseCart = createSlice({
  name: "courseCart",
  initialState,
  reducers: {
    handleCartCourse: (state, { payload }) => {
      state.courses = payload;
    },
    handleCartCourseErr: (state, { payload }) => {},
    handleLoading: (state, {payload}) => {
      state.loading = payload
    },
    handleCartCourseNo: (state, {payload}) => {
      state.noOfCourse = payload
    },
  },
});

export const { handleCartCourse, handleCartCourseErr, handleLoading, handleCartCourseNo} = courseCart.actions;

export default courseCart.reducer;
