const DeleteIc = () => {
  return (
    <>
      <svg
        width="27"
        height="30"
        viewBox="0 0 27 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8965 11.9877H17.4463V25.6815H18.8965V11.9877ZM1.47949 2.21231H9.33106V1.51406C9.33106 1.48965 9.33105 1.46768 9.3335 1.44326C9.36523 0.747461 9.87549 0.161523 10.5566 0.0296875C10.7349 -0.00449219 10.8984 0.000390625 11.0791 0.000390625H15.6006C15.6616 0.000390625 15.7202 -0.00205078 15.7788 0.00527344C16.1646 0.0248047 16.5161 0.19082 16.77 0.447168C17.0215 0.698633 17.1826 1.04043 17.207 1.41885C17.2095 1.46279 17.2119 1.50918 17.2119 1.55313V2.21231H25.1465C25.2539 2.21231 25.3296 2.21475 25.437 2.23184C25.9766 2.31973 26.3843 2.75186 26.4307 3.29873C26.4355 3.35732 26.438 3.41348 26.438 3.47207V6.04531C26.438 6.4042 26.1475 6.69473 25.7861 6.69473H0.651856C0.290527 6.69717 0 6.4042 0 6.04531V6.03067V3.61123C0 2.59805 0.498047 2.21231 1.47949 2.21231ZM14.1772 11.9877H12.7246V25.6815H14.1772V11.9877ZM9.45801 11.9877H8.00781V25.6815H9.46045V11.9877H9.45801ZM2.62207 7.70791H23.8843C23.9722 7.71279 24.0454 7.71768 24.1357 7.73233C24.4409 7.7836 24.7217 7.93008 24.9341 8.1376C25.1807 8.38174 25.3418 8.70889 25.3491 9.07266C25.3491 9.14834 25.3418 9.22647 25.3345 9.30459L23.5449 28.5087C23.5205 28.7602 23.479 28.9848 23.3521 29.2216C23.1055 29.6806 22.6489 30.0004 22.0825 30.0004H4.49219C4.35791 30.0004 4.23584 30.0004 4.104 29.9809C4.05029 29.9735 3.99902 29.9613 3.94775 29.9467C3.87207 29.9247 3.79639 29.8954 3.72559 29.8612C3.65967 29.8295 3.59863 29.7953 3.53516 29.7514C3.1665 29.4975 2.92236 29.0727 2.87842 28.599L1.09863 9.24844C1.09375 9.1874 1.08887 9.12149 1.08887 9.06045C1.10107 8.69912 1.25977 8.37686 1.50391 8.13516C1.71875 7.9252 2.00684 7.77627 2.31689 7.72744C2.41699 7.71279 2.52441 7.70791 2.62207 7.70791ZM23.8647 9.00918H2.58789C2.44873 9.00918 2.38281 9.0336 2.39502 9.18008L4.16992 28.4794V28.4818C4.17725 28.5746 4.21875 28.6503 4.27979 28.6845L4.29932 28.6918L4.31397 28.6942H4.3335H22.0801C22.2266 28.6942 22.2437 28.4892 22.2534 28.3817L24.0454 9.16543C24.0552 9.04336 23.9819 9.00918 23.8647 9.00918Z"
          fill="#C60000"
        />
      </svg>
    </>
  );
};

export default DeleteIc;
