const SignInUpArrow = () => {
  return (
    <>
      <svg
        width="12"
        height="12"
        style={{marginBottom:"2px"}}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.7 9.5L4 8.8L6.3 6.5L4 4.2L4.7 3.5L7.7 6.5L4.7 9.5Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default SignInUpArrow;
