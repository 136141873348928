import React, { useEffect, useState } from "react";
import Footerlogo from "../../assets/svgs/footerlogo.svg"
import facebooklogo from "../../assets/svgs/facebooklogo.svg"
import twitterlogo from "../../assets/svgs/twitterlogo.svg"
import linkedinlogo from "../../assets/svgs/linkedinlogo.svg"
import instagramlogo from "../../assets/svgs/instagramlogo.svg"
import telephone from "../../assets/svgs/telephone.svg"
import inbox from "../../assets/svgs/inbox.svg"
import location from "../../assets/svgs/location.svg"
import { Link } from "react-router-dom";
import { changeLanguage } from "../../redux/slices/language/languageSlice";
import { changeLanguageText } from "../../services/helper";
import { useDispatch, useSelector } from "react-redux";
import VerifyPartner from "../../hoc/VerifyPartner";

function Footer() {

  const activeLanguage = useSelector((state) => state.languageState.lang)
  const dispatch = useDispatch()



  const [pageData, setPageData] = useState({})


  const getTranslatedData = async () => {
    setPageData({
      desc1: await changeLanguageText("With a vision to impart quality technical learning experiences to students and professionals, Technofocus aspires to be your ultimate enablement partner for Microsoft cloud!", activeLanguage),
      desc2: await changeLanguageText("With a vision to impart quality technical learning experiences to students and professionals, Technofocus aspires to be the ultimate enablement partner for Microsoft cloud!", activeLanguage),
      company: await changeLanguageText("Company", activeLanguage),
      about: await changeLanguageText("About", activeLanguage),
      service: await changeLanguageText("Our Services ", activeLanguage),
      promotion: await changeLanguageText("Promotions", activeLanguage),
      service: await changeLanguageText("Services", activeLanguage),
      partners: await changeLanguageText("Partners", activeLanguage),
      handsOnLa: await changeLanguageText("Hands on Labs", activeLanguage),
      cloudCourses: await changeLanguageText("Cloud Courses", activeLanguage),
      contact: await changeLanguageText("Contact", activeLanguage),
      mail: await changeLanguageText("contact@technofocus.co", activeLanguage),
      location: await changeLanguageText("India | Singapore | Estonia", activeLanguage),
      copyright: await changeLanguageText("Technofocus © 2022", activeLanguage),

    })
  }


  useEffect(() => {
    dispatch(changeLanguage(localStorage.getItem("technoLang")))
    getTranslatedData()
  }, [activeLanguage])





  let pathname = window.location.pathname
  return (
    <div className="mt-5">
      <hr className="w-95" />
      <div className="container mt-5">
        <div className="row ">
          <div className="col-md-5">
            <div className="mb-4" href="#">
              <Link to="/">
                <img src={Footerlogo} />
              </Link>

            </div>

            <p className="w-75 footer-content">
              {window.location.pathname === "/" ? pageData?.desc1 : pageData?.desc2}
            </p>

            <div className="d-flex justify-content-start align-items-center mb-4">
              {/* <a href="https://www.facebook.com/" target="blank"><img className="mr-4 " src={facebooklogo} alt="facebooklogo" /></a> */}
              <a href="https://twitter.com/tfsems" target="blank">  <img className="mr-4" src={twitterlogo} alt="twitterlogo" /></a>
              <a href="https://www.linkedin.com/company/technofocus-solutions/" target="blank"> <img className="mr-4" src={linkedinlogo} alt="linkedinlogo" /></a>
              {/* <a href="https://www.instagram.com/" target="blank"> <img className="mr-4" src={instagramlogo} alt="instagramlogo" /></a> */}




            </div>
          </div>
          <div className="col-md-7 footer-headnig-areas">
            <div className="footer-section-part row">
              <div className="col-6 col-md-4">
                <h3 className="footer-heading mb-3 mb-md-5">{pageData?.company}</h3>
                <ul className="p-0 hover-effect-li-tags" >
                  <Link to="/about-us" className="hands-on-labslinks">
                    <li className="my-3 footer-items footer-items">{pageData?.about}
                    </li>
                  </Link>
                  <Link to={"/our-services"} className="hands-on-labslinks">
                    <li className="my-3 footer-items">{pageData?.service}</li>
                  </Link>
                  <Link to="/promotion">
                    <li className="my-3 footer-items">{pageData?.promotion}</li>
                  </Link>

                </ul>
              </div>
              <div className="col-6 col-md-4">

                <h3 className="footer-heading mb-3 mb-md-5">{pageData?.service}</h3>

                <ul className="p-0 hover-effect-li-tags">
                  <VerifyPartner>
                  <Link to="/partners">
                    <li className="my-3 footer-items">{pageData?.partners} </li>
                  </Link>
                  </VerifyPartner>
                  <Link to={"/our-services/hands-on-labs"} className="hands-on-labslinks">
                    <li className="my-3 footer-items">{pageData?.handsOnLa}</li>
                  </Link>
                  <Link to="/our-services/cloud-training">
                    <li className="my-3 footer-items">{pageData?.cloudCourses}</li>
                  </Link>

                </ul>{" "}
              </div>
              <div className="col-12 col-md-4">
                <h3 className="footer-heading mb-3 mb-md-5">{pageData?.contact}</h3>
                <ul className="p-0 ">
                  <li className="my-3 footer-items  d-flex"> <img className="mr-3" src={telephone} />(406) 555-0120 </li>
                  <li className="my-3 footer-items  d-flex"> <img className="mr-3" src={inbox} />{pageData?.mail}</li>
                  <li className="my-3 footer-items d-flex  "> <img className="mr-3" src={location} />{pageData?.location}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-5 w-90" />
      <div className="text-center my-4 footer-last-headnig-copyright">{pageData?.copyright}</div>
    </div>
  );
}

export default Footer;
