import React, { useEffect, useState } from "react";
import "./Courses.css";
import HeroSection from "../../components/HeroSection/HeroSection";
import PrimaryCategories from "../../components/PrimaryCategories/PrimaryCategories";
import { useDispatch, useSelector } from "react-redux";
import { readCourseData } from "../../redux/slices/course/courseSliceActions";
import CourseCard from "../../components/CourseCard/CourseCard";


const Courses = () => {
    const dispatch = useDispatch();
    const handsOnLabData = useSelector((state) => state.courseState.course);
    const title = "Courses";
    const crumbs = [
      { name: "Our Services", link: "/our-services" },
      { name: "Courses", link: "/course" },
    ];
  
    const handleCourseCategories = (courseCategoryObj) => {
      if (courseCategoryObj.courseType === "All Categories") {
        dispatch(readCourseData(courseCategoryObj.courseType));
      } else {
        dispatch(
          readCourseData(
            courseCategoryObj.courseType,
            courseCategoryObj.activeCategory.value
          )
        );
      }
    };
  
    useEffect(() => {
      dispatch(readCourseData("course"));
    }, []);
  return (
    <>
      <div className="HandsBg">
        <HeroSection title={title} crumbs={crumbs} />

        <div className="position-relative d-none d-lg-flex">
          <div className="d-flex text-white justify-content-center mt-3 position-absolute partnersSearch">
            <div className="d-flex justify-content-between bg-white newsletterInputBox shadow w-100">
              <input
                type="email"
                className="ms-2 w-75 color-blue py-2 ps-3 newsletterInput"
                placeholder="Search"
              />
              <button className="newsletterSubmit px-3 t-12 d-flex align-items-center justify-content-center">
                {/* <span className="submitText text-white">Submit</span> */}
                <i className="submitTextIcon text-white  bi bi-search"></i>
              </button>
            </div>
            <button className="newsletterSubmit shadow bg-white mx-2 px-3 t-12 d-flex align-items-center justify-content-center">
              <i className="filterIcon color-blue bi bi-filter"></i>
            </button>
          </div>
        </div>

        <div className="homepage_section2">
          <div className="row my-2">
            <p className="h2">Course Listings</p>
          </div>

          <PrimaryCategories
            handleCourseCategories={handleCourseCategories}
            courseType="course"
          />

          <div className="row mt-4">
            {handsOnLabData.length > 0
              ? handsOnLabData?.map((el) => (
                  <div style={{width: "321px",}} className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <CourseCard cardData={el} />
                  </div>
                ))
              : <div>Data Not Found</div>}
          </div>

          {/* <div className="d-flex justify-content-center">
            <button className="service_btn color-blue bg-white border px-3 py-2 mt-2 border-primary">
              <i className=" service_btn_icon bi bi-plus me-2" />
              Load More
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Courses;
