import { useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import Categories from "../../components/Categories/Categories";
import CalenderIc from "../../assets/svgs/CalenderIc";
import WebIc from "../../assets/svgs/WebIc";
import AppAccordian from "../../components/AppAccordian/AppAccordian";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { readOneCourseData } from "../../redux/slices/course/courseSliceActions";
import CourseModules from "./CourseModules/CourseModules";
import "./PartnerCourseDescription.css";
import PartnerApplicationForm from "./PartnerApplicationForm/PartnerApplicationForm";

const PartnerCourseDescription = () => {
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams();

  const [courseModules, setCourseModules] = useState(true);
  const courseInfo = useSelector((state) => state.courseState.activeCourse);

  const courseId = queryParameters.get("courseId");
  const courseType = queryParameters.get("courseType");
  const crumbs = [
    { name: "Our Services" },
    { name: "Courses" },
    { name: courseInfo ? courseInfo?.category?.category_name : "Course About" },
  ];
  const categoryData = [
    { id: 1, name: "About", value: "About", label: "About" },
    { id: 2, name: "Modules", value: "Modules", label: "Modules" },
  ];

  const handleCategoryData = (cateData) => {
    if (cateData.name === "Modules") {
      setCourseModules(true);
    } else {
      setCourseModules(false);
    }
  };

  useEffect(() => {
    dispatch(readOneCourseData(courseType, courseId));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="power-con">
        <div className="power-hd">
          <HeroSection title={courseInfo.course_name} crumbs={crumbs} />
          <div className="power-hd-img partner-app-form-con">
            <PartnerApplicationForm />
          </div>
        </div>
        <div className="power-wrap">
          <div className="course-about">
            <div className="course-about-lf">
              <div className="category-con category-con-display ">
                <Categories
                  categoriesData={categoryData}
                  handleCategoryData={handleCategoryData}
                />
              </div>
              {courseModules ? (
                <CourseModules courseInfo={courseInfo} />
              ) : (
                <>
                  <div className="about-con ">
                    <div className="about-title">About</div>
                    <div className="about-content">
                      {courseInfo?.course_about}
                    </div>
                  </div>
                  <div className="about-con">
                    <div className="about-title">Duration</div>
                    <div className="about-content">
                      <CalenderIc />
                      &nbsp;<span>{courseInfo?.course_duration} Hours</span>
                    </div>
                  </div>
                  <div className="about-con">
                    <div className="about-title">Schedule</div>
                    <div className="about-content">
                      <CalenderIc />
                      &nbsp;<span>NA</span>
                    </div>
                  </div>
                  <div className="about-con">
                    <div className="about-title">Mode of Delivery</div>
                    <div className="about-content">
                      <WebIc />
                      &nbsp;<span>{courseInfo?.course_mode}</span>
                    </div>
                  </div>
                  <div className="about-con">
                    <div className="about-title">Course Outline</div>
                    <div className="about-content">
                      <span>
                        We follow a systematic course outline to enable better
                        learning.
                      </span>
                    </div>
                    <div className="accordian-con">
                      {courseInfo?.course_outlines?.length > 0 && courseInfo
                        ? courseInfo?.course_outlines.map((ele) => {
                          return (
                            <>
                              <div className="accordian-wrap">
                                <AppAccordian
                                  accHed={ele?.cousrseOutlineKey?.trim()}
                                  accBdy={ele?.courseOutlineValue}
                                />
                              </div>
                            </>
                          );
                        })
                        : null}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="course-about-rt"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerCourseDescription;
