const HomeBread = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{marginBottom:"3px"}}
      >
        <path
          d="M8.33415 15.8329V11.6663H11.6675V15.8329C11.6675 16.2913 12.0425 16.6663 12.5008 16.6663H15.0008C15.4591 16.6663 15.8341 16.2913 15.8341 15.8329V9.99961H17.2508C17.6341 9.99961 17.8175 9.52461 17.5258 9.27461L10.5591 2.99961C10.2425 2.71628 9.75915 2.71628 9.44248 2.99961L2.47581 9.27461C2.19248 9.52461 2.36748 9.99961 2.75081 9.99961H4.16748V15.8329C4.16748 16.2913 4.54248 16.6663 5.00081 16.6663H7.50081C7.95915 16.6663 8.33415 16.2913 8.33415 15.8329Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default HomeBread;
