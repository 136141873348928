import useLocalStorge from "../../../../hooks/useLocalStorage";
import { useDispatch } from "react-redux";
import {
  addCourseCart,
  addCourseToCartLocalStorage,
  deleteCourseCart,
} from "../../../../redux/slices/courseCart/courseCartActions";
import "./Quantity.css";

const Quantity = ({ courseQuantity, courseId, courseData, tableName }) => {
  const dispatch = useDispatch();

  const addCourseToCart = (courseData) => {
    dispatch(addCourseToCartLocalStorage(courseData));
  };

  const deleteCourseFromCart = (courseData) => {
    dispatch(deleteCourseCart(courseData));
  };
  return (
    <>
      <div className="qun-con">
        <div className="qun-wrap">
          {tableName === "studyCalender" ? null : (
            <div
              onClick={() => deleteCourseFromCart(courseData)}
              className="qun-left qun-flex"
            >
              -
            </div>
          )}

          <div className="qun-mid qun-flex">{courseQuantity}</div>
          {tableName === "studyCalender" ? null : (
            <div
              onClick={() => addCourseToCart(courseData)}
              className="qun-right qun-flex"
            >
              +
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Quantity;
