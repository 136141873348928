import {
  handleCouponCode,
  handleCouponCodeIsValid,
  handleLoading,
  handleCouponInfo
} from "./couponSlice";
import { apiUrls } from "../../../api/apiUrls";
import http from "../../../services/axios/axiosInstance";
import { toast } from "react-toastify";

export const verifyCouponCode = (couponCode) => async (dispatch) => {
  try {
    dispatch(handleLoading("loading"));
    const payload = {
      couponCode: couponCode,
    };
    const config = {
      method: "post",
      url: apiUrls.verifyCouponCode,
      data: payload,
    };
    const response = await http(config);
    console.log(response)
    if (response.status === 200) {
      dispatch(handleCouponCodeIsValid(true));
      dispatch(handleCouponInfo(response.data.data))
      dispatch(handleCouponCode(response.data.data.coupon_code))
      localStorage.setItem("couponCode", response.data.data.coupon_code)
    } else {
      dispatch(handleCouponCodeIsValid(true));
      toast.error("Invalid Coupon Code")
    }
  } catch (err) {
    dispatch(handleCouponCodeIsValid(true));
    toast.error("Invalid Coupon Code")
  }
};
