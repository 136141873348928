import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lodingApi: "idle",
  partnerCourse: [],
  apiErr: "",
  applicationFormRes: "",
};

const partnerCourse = createSlice({
  name: "partnerCourse",
  initialState,
  reducers: {
    handleLoding: (state, { payload }) => {
      state.lodingApi = payload;
    },
    handlePartnerCourse: (state, { payload }) => {
      state.partnerCourse = payload;
    },
    handleApiErr: (state, { payload }) => {
      state.apiErr = payload;
    },
    handleApplicationFormResponse: (state, { payload }) => {
      state.applicationFormRes = payload;
    },
  },
});

export const {
  handleLoding,
  handleApiErr,
  handlePartnerCourse,
  handleApplicationFormResponse,
} = partnerCourse.actions;
export default partnerCourse.reducer;
