import DeleteIcMb from "../../assets/svgs/DeleteIcMb";
import "./AppTable.css";
import CoursePurchased from "./AppTableComponents/CoursePurchased/CoursePurchased";
import Schedule from "./AppTableComponents/Schedule/Schedule";
import TimeButton from "./AppTableComponents/TimeButton/TimeButton";
import { destroyCourseCart } from "../../redux/slices/courseCart/courseCartActions";
import { useDispatch } from "react-redux";
const AppTableMobile = ({ tableName, cartCourses }) => {
  const dispatch = useDispatch();
  const destroyCourseFromCart = (courseId) => {
    dispatch(destroyCourseCart(courseId));
  };
  console.log(cartCourses,"aaaa")
  return (
    <>
      <div
        className={
          tableName === "studyCalender"
            ? "app-table-con-calender"
            : "app-table-con "
        }
      >
        <div className="mb-tb-con">
          {cartCourses?.length > 0 ? cartCourses?.map((ele) => {
            console.log(ele)
            return (
              <>
                <div

                  className={
                    tableName === "studyCalender" ? "mb-tb-calender-wrap" : ""
                  }
                >
                  <div className="mb-tb-wrap">
                    <div className="mb-tb-rt">
                      <CoursePurchased courseData={ele} courseName={ele.course_name}  deviceType="mobile" />
                    </div>
                    <div
                      className={
                        tableName === "studyCalender"
                          ? "mb-tb-lf-calender"
                          : "mb-tb-lf"
                      }
                    >
                      <div className="mb-tb-price">{ele.course_mrp}</div>
                      <div className="mb-tb-ic" onClick={() => destroyCourseFromCart(ele.id)}>
                        {tableName === "studyCalender" ? (
                          null
                        ) : (
                          <DeleteIcMb />
                        )}
                      </div>
                    </div>
                  </div>
                  {tableName === "studyCalender" ? (
                    // <div className="mb-tb-bt">
                    //   <Schedule />
                    // </div>
                    null
                  ) : null}
                </div>
              </>
            );
          }): <div>Cart Is Empty</div>}
        </div>
      </div>
    </>
  );
};

export default AppTableMobile;
