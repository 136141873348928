import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { manualSignIn } from "../../redux/slices/authentication/authSliceActions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GmailLogo from "../../assets/svgs/GmailLogo";
import LinkedinLogo from "../../assets/svgs/LinkedinLogo";
import MicrosoftLogo from "../../assets/svgs/MicrosoftLogo";
import SignInUpArrow from "../../assets/svgs/SignInUpArrow";
import SignUpInMid from "../../assets/svgs/SignInUpMid";
import TechnoFocusLogo from "../../assets/svgs/TechnoFocusLogo";
import TechnoFocusLogoSm from "../../assets/svgs/TechnoFocusLogoSm";
import Categories from "../../components/Categories/Categories";
import { getLinkedinSignInUrl } from "../../services/getLinkedinUrl";
import { getMicrosoftSignInUrl } from "../../services/getMicrosoftUrl";
import { getGoogleOAuthSignInURL } from "../../services/getGoogleUrl";
import pic1 from "../../assets/images/signInUp/pic1.svg";
import pic2 from "../../assets/images/signInUp/pic2.svg";
import pic3 from "../../assets/images/signInUp/pic3.svg";

import "./SignIn.css";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errMessageState, setErrMessageState] = useState("");

  const isLoading = useSelector(
    (state) => state.authenticationState.loadingApi
  );

  const queryParameters = new URLSearchParams(window.location.search);
  const errMessage = queryParameters.get("errMessage");

  useEffect(() => {
    if (errMessage?.length > 0) {
      var properErrMessage = errMessage.split("+").join(" ");
      setErrMessageState(properErrMessage);
    }
    if (errMessageState?.length > 0) {
      toast.error(errMessageState);
    }
  }, [errMessageState]);
  const categoryData = [
    { id: 1, name: "Student", value: "Student", label: "Student" },
    { id: 2, name: "Partner", value: "Partner", label: "Partner" },
  ];
  const signupSchema = yup
    .object({
      userEmail: yup.string().required().email(),
      userPassword: yup.string().required(),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupSchema),
  });

  const [userCategory, setUserCategory] = useState("");

  const handleCategoryData = (categoryData) => {
    setUserCategory(categoryData);
  };
  const isAuth = useSelector((state) => state.authenticationState.isAuth);

  const onSubmit = (formData) => {
    dispatch(
      manualSignIn(
        formData?.userEmail,
        formData?.userPassword,
        userCategory?.name
      )
    );
  };

  const handleForgotPassword = () => {
    navigate("/forgetPassword")
  }

  useEffect(() => {
    if (isAuth) {
      navigate("/", { replace: true });
    }
  }, [isAuth]);

  return (
    <>
      <div className="signinup-con">
        <div className="signinup-con-hd">
          <Link to="/">
            <TechnoFocusLogoSm />
          </Link>
        </div>
        <div className="signinup-wrap">
          <div className="signinup-left">
            <div className="logo-box">
              <Link to="/">
                <TechnoFocusLogo />
              </Link>
            </div>

            <div className="back-img-box"></div>
            <h1 className="signinup-hd">
              <span className="signinup-hd-main"></span>
              <span className="signinup-hd-sub">

              </span>
            </h1>
            <div className="signinup-mid-img position-relative">
              <SignUpInMid />
              <img className="imagesignupspic3" src={pic1} />
              <img className="imagesignupspic2" src={pic2} />
              <img className="imagesignupspic1" src={pic3} />
            </div>
          </div>
          <div className="signinup-right">
            <div className="signinup-right-hd">
              <div className="signinup-right-hd-main">Sign In</div>
              <div className="signinup-right-hd-sub">
                {/* Lorem ipsum dolor sit amet consectetur. */}
              </div>
            </div>
            <div className="signinup-right-categories-wrap">
              <div className="signinup-right-categories-box">
                <Categories
                  handleCategoryData={handleCategoryData}
                  categoriesData={categoryData}
                />
              </div>
            </div>

            <div className="signinup-form-box">
              <div className="signinup-input-box">
                <label className="signinup-label">Email Address</label>
                <div className="signinup-input-wrap">
                  <input
                    {...register("userEmail")}
                    className="signinup-input px-3"
                  />
                </div>
                <div style={{ color: "red", marginTop: "10px" }}>{errors.userEmail?.message}</div>
              </div>
              <div className="signinup-input-box">
                <label className="signinup-label mt-4">Password</label>
                <div className="signinup-input-wrap">
                  <input
                    type="password"
                    {...register("userPassword")}
                    className="signinup-input px-3"
                  />
                </div>
                <div style={{ color: "red", marginTop: "10px" }}>{errors.userPassword?.message}</div>
              </div>
              <div className="signinup-btn-box mt-5">
                <button
                  onClick={handleSubmit(onSubmit)}
                  className="signinup-btn"
                >
                  {isLoading === "loading" ? <Spinner /> : "Login"}
                  <span>
                    <SignInUpArrow />
                  </span>
                </button>
              </div>
              <div className="signinup-seprator-box">
                <div className="signinup-seprator-left"></div>

                <div className="signinup-seprator-center">
                  Or
                </div>

                <div className="signinup-seprator-right"></div>
              </div>
              <div className="signinup-logo-box">
                <a href={getMicrosoftSignInUrl(userCategory.name)}>
                  <div className="signinup-micro-logo signinup-logo-size">
                    <MicrosoftLogo />
                  </div>
                </a>
                <a href={getGoogleOAuthSignInURL(userCategory.name)}>
                  <div className="signinup-gmail-logo signinup-logo-size">
                    <GmailLogo />
                  </div>
                </a>
                <a href={getLinkedinSignInUrl(userCategory.name)}>
                  <div className="signinup-linkedin-logo signinup-logo-size">
                    <LinkedinLogo />
                  </div>
                </a>
              </div>
              <div className="signinup-already-box d-flex justify-content-between">
                <span>Don't have account? <Link to="/signup">Sign Up</Link></span> <span onClick={handleForgotPassword} className="signinup-already-box-login">Reset Password</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
