import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import http from "../../services/axios/axiosInstance";
import img1 from "../../assets/images/ContactUsPage/contactus.png";
import { toast } from "react-toastify";
import "./ContactUs.css"
import HeroSection from "../../components/HeroSection/HeroSection";
import contactUsPage from "../../assets/images/ContactUsPage/map.svg"
import MissionComp from "../AboutUsPage/MissionComp";
import VisionComp from "../AboutUsPage/VisionComp";
import Categories from "../../components/Categories/Categories";
import location from "../../assets/images/ContactUsPage/location.svg";
import DownloadInfo from "../../assets/images/profile/DownloadInfo.svg";
import call from "../../assets/images/ContactUsPage/call.svg";
import tickMark from "../../assets/images/ContactUsPage/tickMark.svg"
import Location from "./Location";
import bgleftcontactus from "../../assets/images/ContactUsPage/bgleftcontactus.png"
import { changeLanguageText } from "../../services/helper";
import { useSelector } from "react-redux";



function ContactUs() {

  const [mission, setMission] = useState(true)


  const contactUsSchema = yup
    .object({
      email_address: yup.string().required().email(),
      phone_number: yup.string().required().max(10),
      enquiry: yup.string().required(),

    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactUsSchema),
  });

  const onSubmit = async (data) => {
    // console.log("data",data)
    try {
      await http.post(`enquiryform`, data)
      toast.success(' Enquiry submitted ', { autoClose: 3000 })
      setTimeout(() => {
        window.location.reload()
      }, 1000)
      // register({ email_address: "", phone_number: "", enquiry: "" })
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong!', { autoClose: 3000 })
    }

  }
  const title = "Contact Us"
  const crumbs = [
    { name: "Contact Us", link: "/contact-us" }
  ]


  const categoryData = [
    { id: 1, name: "India", value: "India", label: "India" },
    { id: 2, name: "Singapore", value: "Singapore", label: "Singapore" },
    { id: 3, name: "Estonia", value: "Estonia", label: "Estonia" }

  ];
  const handleCategoryData = (cateData) => {
    // console.log(cateData)
  }


  const activeLanguage = useSelector((state) => state.languageState.lang)
  const [pageData, setPageData] = useState({})

  const getTranslatedData = async () => {
    setPageData({
      contactus: await changeLanguageText("Contact Us", activeLanguage),
      details: await changeLanguageText("Contact Information", activeLanguage),
      email: await changeLanguageText("Email", activeLanguage),
      desc: await changeLanguageText("contact@technofocus.co", activeLanguage),
      phone: await changeLanguageText("Phone Number", activeLanguage),
      location: await changeLanguageText("Location", activeLanguage),
      location1: await changeLanguageText("K 1, Green Park, New Delhi, India", activeLanguage),
      enquiry: await changeLanguageText("Enquiry Form", activeLanguage),
      email: await changeLanguageText("Email address", activeLanguage),
      email1: await changeLanguageText("abc@technofocus.co", activeLanguage),
      phone1: await changeLanguageText("Phone Number", activeLanguage),
      text: await changeLanguageText("Enquiry", activeLanguage),
      text2: await changeLanguageText("Write your enquiry here", activeLanguage),
      clearform: await changeLanguageText("Clear Form", activeLanguage),
      submit: await changeLanguageText("Submit", activeLanguage),




    })
  }




  useEffect(() => {
    getTranslatedData()
  }, [activeLanguage])

  return (
    <>
      <HeroSection title={pageData?.contactus} crumbs={[{ name: pageData?.contactus, link: "/contact-us" }]} />
      <div className="container mt-5">
        <div className="row partnersRow mb-5">
          <div className="col-lg-6">
            <Categories handleCategoryData={handleCategoryData} categoriesData={categoryData} />
            <div className="card p-4 mt-5">
              <h3 className="pt-5 ">{pageData?.details}</h3 >
              <div className="mt-4 d-flex " >
                <img className="downloadinfo me-5" src={DownloadInfo} />
                <div className="contact-us-addressses">
                  <label className="email-address">{pageData?.email}</label>
                  <p className="p-tags">{pageData?.desc}</p>
                </div>
              </div>
              <div className="mt-2 d-flex " >
                <img className="downloadinfo me-5" src={call} />
                <div className="contact-us-addressses">
                  <label className="email-address">{pageData?.phone}</label>
                  <p className="p-tags">+91(11) 4056 6360</p>
                  <p className="p-tags">(+65) 62900570</p>

                </div>
              </div>

            </div>
          </div>
          <div className="col-lg-6 p-3">
            <div className="row px-2 p-lg-5 position-relative">
              {/* <img src={contactUsPage} className="objectFitCover" id="partnersImage" alt="Technofocus partners page" /> */}
              <Location />
              <div className="location-contact-us">
                <h2 className="location-title">{pageData?.location}</h2>
                {/* <span>531 A Upper Cross Street, Hong Lim Complex, Singapore</span> */}
                <p>{pageData?.location1}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 position-relative">
            <img src={bgleftcontactus} />
            {/* <img src={img1} className="w-75 " />
            <img className="tickmarkindex" src={tickMark} /> */}
          </div>
          <div className="col-md-6 m-auto mt-5">
            <Form className="contact-us-form p-5 m-auto border">

              <h3 className="mb-3 contact-form-heading">{pageData?.enquiry}</h3>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{pageData?.email}</Form.Label>
                <Form.Control type="email" placeholder={pageData?.email1}  {...register("email_address")} className="signinup-input" />
                <div className="error-messages">{errors.email_address?.message}</div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>{pageData?.phone1}</Form.Label>
                <Form.Control type="text" placeholder="7832178321"  {...register("phone_number")} className="signinup-input" />
                <div className="error-messages">{errors.phone_number?.message}</div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" >
                <Form.Label>{pageData?.text}</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder={pageData?.text2} {...register("enquiry")} className="signinup-input" />
                <div className="error-messages" >{errors.enquiry?.message}</div>
              </Form.Group>

              <div className="row">
                <div className="col-md-6">
                  <button type="reset" class="btn btn-outline-primary w-100">
                    {pageData?.clearform}
                  </button>
                </div>
                <div className="col-md-6">
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {pageData?.submit}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
