const LabIc = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#F1F1F1" />
        <path
          d="M32.8655 32.8978L26.1297 20.9352C25.9611 20.6357 25.8725 20.2977 25.8725 19.9539V15.8797C25.8725 14.8416 25.0309 14 23.9928 14V14C22.9546 14 22.113 14.8416 22.113 15.8797V19.9539C22.113 20.2977 22.0244 20.6357 21.8558 20.9352L15.1201 32.8978C14.7732 33.5132 15.2207 34.2629 15.9144 34.2629H32.0599C32.7648 34.2517 33.2123 33.502 32.8655 32.8978Z"
          stroke="#1B449C"
          stroke-width="2"
          stroke-miterlimit="90.323"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.5888 14H21.386"
          stroke="#1B449C"
          stroke-width="2"
          stroke-miterlimit="90.323"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.4221 26.7845C28.9996 26.0331 28.1409 25.6529 27.279 25.6598C27.2505 25.6601 27.2218 25.6602 27.1927 25.6602C24.5522 25.6602 24.5522 24.7539 21.9228 24.7539C21.1909 24.7539 20.6635 24.8221 20.225 24.9216C19.7407 25.0315 19.3488 25.374 19.1052 25.8068L15.1201 32.8881C14.7732 33.5035 15.2207 34.2532 15.9144 34.2532H32.0599C32.7648 34.2532 33.2011 33.5035 32.8543 32.8881L29.4221 26.7845Z"
          stroke="#1B449C"
          stroke-width="2"
          stroke-miterlimit="90.323"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default LabIc