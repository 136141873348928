import React from 'react'
import "./TestimonialMobileCard.css"
import Quote2 from "../../assets/images/Homepage/quote2.svg"
import User from "../../assets/images/Homepage/user.png"


const TestimonialMobileCard = ({ testimonialData }) => {
    return (
        <div className='px-5 py-4  rounded-10 testimonial-mobile-card'>
            <div>
                <img className="textimonial_quote mb-5" src={Quote2} />
            </div>
            <div className='color-white asas'>
                {testimonialData.testimonial_description}
            </div>
            <div className="tetsimonail-card-images">
                <img className=" me-3 testimonial_user" src={User} />
                <div  >
                    {testimonialData.testimonial_name}
                    <div>
                        {(testimonialData.testimonial_job_profile) }
                    </div>
                    { }
                </div>
            </div>

        </div>
    )
}

export default TestimonialMobileCard
