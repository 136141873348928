import React, { useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import BG1 from "../../assets/images/Partners/bg1.svg";
import TrainingImage from "../../assets/images/CloudTraining/cloudTraining.png";
import CloudTrainingCard from "../../components/CloudTrainingCard/CloudTrainingCard";
import { useDispatch, useSelector } from "react-redux";
import { readCourseCategoryData } from "../../redux/slices/courseCategory/courseCategoryAction";
import { useNavigate } from "react-router-dom";
import { changeLanguageText } from "../../services/helper";
import { changeLanguage } from "../../redux/slices/language/languageSlice";
import { getDefaultLang } from "../../utils/helperFunction";

const CloudTraining = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const title = "Microsoft Cloud Training";
  const crumbs = [
    { name: "Our Services", link: "/our-services" },
    { name: "Microsoft Cloud Training" },
  ];
  let courseCategories = useSelector(
    (state) => state.courseCategoryState.courseCategory
  );



  // const activeLanguage = useSelector((state) => state.languageState.lang)
  const [activeLanguage, setActiveLanguage] = useState(getDefaultLang());
  const [pageData, setPageData] = useState({})

  const handleLanguage = (data = "en") => {
    setActiveLanguage(data)
    localStorage.setItem("technoLang", data)

    dispatch(changeLanguage(data))
  }

  const getTranslatedData = async () => {
    setPageData({
      ourservice: await changeLanguageText("Microsoft Cloud Training", activeLanguage),
      title: await changeLanguageText("Our Services", activeLanguage),
      abouttraining: await changeLanguageText("About Microsoft Cloud Training", activeLanguage), microDesc: await changeLanguageText("We, at Technofocus, are dedicated to creating a dynamic, value-based learning experience for our trainees. With your expertise, help us spread the knowledge and skills on the latest in Microsoft cloud. Choose from the upcoming engagements, match your skills to the course requirements, and submit your application! We will review your application and get in touch with you.", activeLanguage),
      microsoftcloud: await changeLanguageText("Microsoft Cloud Training", activeLanguage),
      categies: await changeLanguageText("Categories", activeLanguage),
      location1: await changeLanguageText("K 1, Green Park, New Delhi, India", activeLanguage),
      enquiry: await changeLanguageText("Enquiry Form", activeLanguage),
      email: await changeLanguageText("Email address", activeLanguage),
      email1: await changeLanguageText("abc@technofocus.co", activeLanguage),
      phone1: await changeLanguageText("Phone Number", activeLanguage),
      text: await changeLanguageText("Enquiry", activeLanguage),
      text2: await changeLanguageText("Write your enquiry here", activeLanguage),
      clearform: await changeLanguageText("Clear Form", activeLanguage),
      submit: await changeLanguageText("Submit", activeLanguage),




    })
  }

  useEffect(() => {
    dispatch(readCourseCategoryData());
    getTranslatedData()

  }, [activeLanguage]);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])

  useEffect(() => {
    handleLanguage(getDefaultLang())
  }, [])



  return (
    <div className="partnersBg2">
      {/* <HeroSection title={title} crumbs={crumbs} /> */}
      <HeroSection title={pageData?.ourservice} crumbs={[{ name: pageData?.title, link: "/our-services" }, { name: pageData?.ourservice }]} />

      <div className="main-container">
        <div className="row partnersRow">
          {/* left panel */}
          <div className="col-lg-6 h-100">
            <div className="mt-5 position-relative">
              <h2>{pageData?.abouttraining}</h2>
              <p className="text-muted">
                {pageData?.microDesc}
              </p>
              <img className="position-absolute partnersBg1" src={BG1} />
            </div>
          </div>

          {/* right panel */}
          <div className="col-lg-6 p-3 h-100">
            <div className="row px-2 p-lg-5 position-relative">
              <img
                src={TrainingImage}
                className="objectFitCover p-0 shadow"
                id="partnersImage"
                alt="Technofocus partners page"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="main-container mt-4 ">
        <div className="row my-2">
          <p className="h2 color-blue">{pageData?.microsoftcloud}</p>
          {/* <p className="h2">{pageData?.categies}</p> */}
        </div>
        <div className="row row-cols-2 mt-5 g-4">
          {courseCategories?.map((ele) => {
            return (
              <div
                onClick={() => {
                  navigate(
                    `/our-services/cloud-training/course?categoryId=${ele.id}&categoryName=${ele.category_name}`
                  );
                }}
                className="col-md-5"
              >
                <CloudTrainingCard cardData={ele} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CloudTraining;
