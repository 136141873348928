import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Header/logo.png";
import english from "../../assets/svgs/Languages/english.svg";
import japanese from "../../assets/svgs/Languages/japanese.svg";
import spanish from "../../assets/svgs/Languages/spanish.svg";
import portugese from "../../assets/svgs/Languages/portugese.svg";
import german from "../../assets/svgs/Languages/german.svg";
import HeaderProfile from "./HeaderProfile";
import { useSelector, useDispatch } from "react-redux";
import { readUserProfile } from "../../redux/slices/userProfile/userProfileActions";
import cartIcon from "../../assets/images/Homepage/carticon.svg";
import translate from "translate";
import "./HeaderProfile.css";
import { changeLanguage } from "../../redux/slices/language/languageSlice";
import { changeLanguageText } from "../../services/helper";
import { Link } from "react-router-dom";
import ShopCart from "../../assets/svgs/ShopCartIc";
import { getDefaultLang } from "../../utils/helperFunction";
import VerifyPartner from "../../hoc/VerifyPartner";
import GlobeIcn from "../../assets/svgs/GlobeIcn";
// import { changeLanguageText } from "../../services/helper";

const Header = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector(
    (state) => state.userProfileState.userProfile
  );
  const noCartCourses = useSelector(
    (state) => state.courseCartState.noOfCourse
  );

  const [activeLanguage, setActiveLanguage] = useState(getDefaultLang());

  const handleLanguage = (data = "en") => {
    setActiveLanguage(data);
    localStorage.setItem("technoLang", data);
    dispatch(changeLanguage(data));
  };

  const [pageData, setPageData] = useState({});

  const getTranslatedData = async () => {
    setPageData({
      home: await changeLanguageText("Home", activeLanguage),
      about: await changeLanguageText(" About Us", activeLanguage),
      ourServices: await changeLanguageText("Our services", activeLanguage),
      promotions: await changeLanguageText("Promotions", activeLanguage),
      partners: await changeLanguageText("Partners", activeLanguage),
      contactUs: await changeLanguageText("Contact Us", activeLanguage),
      signIn: await changeLanguageText("Sign In", activeLanguage),
      signUp: await changeLanguageText("Sign Up", activeLanguage),
      choose: await changeLanguageText(" Choose a language", activeLanguage),
      suggested: await changeLanguageText("Suggested language", activeLanguage),
    });
  };

  useEffect(() => {
    getTranslatedData();
    dispatch(readUserProfile());
  }, [activeLanguage]);

  useEffect(() => {
    handleLanguage(getDefaultLang());
  }, []);

  return (
    <>
      <div className="main-container">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <Link className="navbar-brand" to="/">
            <img src={Logo} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* <ul className="navbar-nav mr-auto" /> */}
            <div style={{ display: "flex" }} className="my-2 my-lg-0 w-100">
              <ul
                style={{ width: "100%" }}
                className="navbar-nav m-auto d-flex justify-content-end "
              >
                <li style={{ textAlign: "center" }} className="nav-item active">
                  <Link
                    className={
                      window.location.pathname === "/"
                        ? " nav-link text-white mx-2 underline-navbar-tab"
                        : "nav-link text-white mx-2"
                    }
                    to="/"
                  >
                    {pageData?.home}
                  </Link>
                </li>
                <li style={{ textAlign: "center" }} className="nav-item active">
                  <Link
                    className={
                      window.location.pathname === "/about-us"
                        ? " nav-link text-white mx-2 underline-navbar-tab"
                        : "nav-link text-white mx-2"
                    }
                    to="/about-us"
                  >
                    {pageData?.about}
                  </Link>
                </li>
                <li style={{ textAlign: "center" }} className="nav-item active">
                  <Link
                    className={
                      window.location.pathname === "/our-services" ||
                      window.location.pathname ===
                        "/our-services/hands-on-labs" ||
                      window.location.pathname ===
                        "/our-services/cloud-training" ||
                      window.location.pathname ===
                        "/our-services/challenge-experience"
                        ? " nav-link text-white mx-2 underline-navbar-tab"
                        : "nav-link text-white mx-2"
                    }
                    to="/our-services"
                  >
                    {pageData?.ourServices}
                  </Link>
                </li>
                <li style={{ textAlign: "center" }} className="nav-item active">
                  <Link
                    className={
                      window.location.pathname === "/promotion"
                        ? " nav-link text-white mx-2 underline-navbar-tab"
                        : "nav-link text-white mx-2"
                    }
                    to="/promotion"
                  >
                    {pageData?.promotions}
                  </Link>
                </li>
                <VerifyPartner>
                  <li
                    style={{ textAlign: "center" }}
                    className="nav-item active"
                  >
                    <Link
                      className={
                        window.location.pathname === "/partners"
                          ? " nav-link text-white mx-2 underline-navbar-tab"
                          : "nav-link text-white mx-2"
                      }
                      to="/partners"
                    >
                      {pageData?.partners}
                    </Link>
                  </li>
                </VerifyPartner>
                <li style={{ textAlign: "center" }} className="nav-item active">
                  <Link
                    className={
                      window.location.pathname === "/contact-us"
                        ? " nav-link text-white mx-2 underline-navbar-tab"
                        : "nav-link text-white mx-2"
                    }
                    to="/contact-us"
                  >
                    {pageData?.contactUs}
                  </Link>
                </li>
                <li style={{ textAlign: "center" }} className="nav-item active">
                  <Link
                    className="nav-link text-white mx-2 d-flex align-items-center"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    {/* <GlobeIcn/> */}
                    <i className="bi bi-globe"></i>
                  </Link>
                </li>
                <li
                  style={{ textAlign: "center" }}
                  className="nav-item active "
                >
                  <Link
                    to="/cart"
                    className={
                      window.location.pathname === "/cart"
                        ? " nav-link text-white mx-2 underline-navbar-tab"
                        : "nav-link text-white mx-2"
                    }
                  >
                    <div>
                      <ShopCart />
                      <div className="badge">{noCartCourses}</div>
                    </div>
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav home-nav m-auto d-none d-lg-flex justify-content-end">
                {Object.keys(userProfile)?.length > 0 ? (
                  <HeaderProfile userProfileName={userProfile.user_name} />
                ) : (
                  <>
                    {/* <li
                      style={{ textAlign: "center" }}
                      className="nav-item active"
                    >
                      <Link className="nav-link text-white mx-2  " to="/signin">
                        {pageData?.signIn}
                      </Link>
                    </li> */}
                    <li
                      style={{ textAlign: "center" }}
                      className="nav-item active"
                    >
                      <Link
                        className="nav-link signup-button-cra1 w-100 px-4"
                        to="/signup"
                      >
                        {pageData?.signUp}
                      </Link>
                    </li>
                  </>
                )}
              </ul>
              <ul className="navbar-nav home-nav2   ">
                {Object.keys(userProfile)?.length > 0 ? (
                  <HeaderProfile userProfileName={userProfile.user_name} />
                ) : (
                  <>
                    <li
                      style={{ textAlign: "center" }}
                      className="nav-item active"
                    >
                      <Link
                        className="nav-link text-white w-100  "
                        to="/signin"
                      >
                        {pageData?.signIn}
                      </Link>
                    </li>
                    <li
                      style={{ textAlign: "center" }}
                      className="nav-item active"
                    >
                      <Link
                        className="nav-link text-white w-100  "
                        to="/signup"
                      >
                        {pageData?.signUp}
                      </Link>
                    </li>
                  </>
                )}
              </ul>
              {/* <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
            </div>
          </div>
          {/* pop-up language here */}
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog ">
              <div className="modal-content tab-english-popup">
                <div class="modal-header  ">
                  <div className="px-4">
                    <h1
                      class="modal-title language-font-size pb-4"
                      id="exampleModalLabel"
                    >
                      {pageData?.suggested}
                    </h1>
                    <div className=" w-100 p-2 background-white-boxe">
                      <img className="pr-2" src={english} />
                      <span>English</span>
                    </div>
                  </div>

                  <button
                    type="button"
                    class="btn-close pr-5"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body pb-5">
                  <h1
                    class="modal-title language-font-size pb-4 px-4"
                    id="exampleModalLabel"
                  >
                    {pageData?.choose}
                  </h1>

                  <div className="row mx-2   pb-3 ">
                    <div
                      className={`${
                        activeLanguage === "en" ? "back-blue-language" : ""
                      } col-md-2 background-white-boxes mr-3`}
                      onClick={() => handleLanguage("en")}
                    >
                      <img className="pr-2" src={english} />
                      <span>English</span>
                    </div>
                    <div
                      className={`${
                        activeLanguage === "ru" ? "back-blue-language" : ""
                      } col-md-2 background-white-boxes mr-3`}
                      onClick={() => handleLanguage("ru")}
                    >
                      <img className="pr-2" src={japanese} />{" "}
                      <span>Russian</span>
                    </div>
                    <div
                      className={`${
                        activeLanguage === "es" ? "back-blue-language" : ""
                      } col-md-2 background-white-boxes mr-3`}
                      onClick={() => handleLanguage("es")}
                    >
                      <img className="pr-2" src={spanish} />{" "}
                      <span>Spanish</span>
                    </div>
                    <div
                      className={`${
                        activeLanguage === "fr" ? "back-blue-language" : ""
                      } col-md-2 background-white-boxes mr-3`}
                      onClick={() => handleLanguage("fr")}
                    >
                      <img className="pr-2" src={portugese} />{" "}
                      <span>French</span>
                    </div>
                    <div
                      className={`${
                        activeLanguage === "de" ? "back-blue-language" : ""
                      } col-md-2 background-white-boxes mr-3`}
                      onClick={() => handleLanguage("de")}
                    >
                      <img className="pr-2" src={german} /> <span>German</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
