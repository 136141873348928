import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lodingApi: "idle",
  courseCategory: [],
  courseCategoryDataArr: [],
  activeCourseCategory: "",
  apiErr: "",
};

const courseCategory = createSlice({
  name: "courseCategory",
  initialState,
  reducers: {
    handleLoding: (state, { payload }) => {
      state.lodingApi = payload;
    },
    handleCourseCategory: (state, { payload }) => {
      state.courseCategory = payload;
    },
    handleCourseCategoryDataArr: (state, { payload }) => {
      state.courseCategoryDataArr = payload;
    },
    handleApiErr: (state, { payload }) => {
      state.apiErr = payload;
    },
    handleActiveCategory: (state, { payload }) => {
      state.activeCourseCategory = payload;
    },
  },
});

export const {
  handleLoding,
  handleCourseCategory,
  handleApiErr,
  handleActiveCategory,
  handleCourseCategoryDataArr,
} = courseCategory.actions;
export default courseCategory.reducer;
