import "./CoursePurchased.css";
import courseImg from "../../../../assets/images/Cart/courseImg.jpg";
import Quantity from "../Quantity/Quantity";

const CoursePurchased = ({deviceType, courseName, courseData}) => {
  return (
    <>
      <div className="course-con">
        <div className="course-left">
          <picture>
            <img
              src={courseImg}
              alt="courseImg"
              className={deviceType === "mobile" ? "course-mb-img": null}
            />
          </picture>
        </div>
        <div className="course-right">
        {courseName}
        {deviceType === "mobile" ? <Quantity tableName="studyCalender" courseQuantity={courseData.quantity} courseData={courseData}/> : null}
        </div>
      </div>
    </>
  );
};

export default CoursePurchased;
