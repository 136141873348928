export const PrevCarouselArrow = () => {
  return (
    <>
      <svg
        width="20"
        height="13"
        viewBox="0 0 20 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.483688 6.98195C0.171268 6.66953 0.171268 6.16299 0.483688 5.85057L5.57486 0.759406C5.88728 0.446986 6.39381 0.446986 6.70623 0.759406C7.01865 1.07183 7.01865 1.57836 6.70623 1.89078L2.18074 6.41626L6.70623 10.9417C7.01865 11.2542 7.01865 11.7607 6.70623 12.0731C6.39381 12.3855 5.88728 12.3855 5.57486 12.0731L0.483688 6.98195ZM19.7823 7.21626H1.04937V5.61626H19.7823V7.21626Z"
          fill="#1B449C"
        />
      </svg>
    </>
  );
};

export default PrevCarouselArrow;
