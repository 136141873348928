import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lodingApi: "idle",
  course: [],
  handsOnLab: [],
  challengeExperince: [],
  apiErr: "",
  activeCourse: [],
  latestCourse: [],
  upcommingCourse: [],
  isEnrolled: false
};

const course = createSlice({
  name: "course",
  initialState,
  reducers: {
    handleLoding: (state, { payload }) => {
      state.lodingApi = payload;
    },
    handleCourse: (state, { payload }) => {
      state.course = payload;
    },
    handleHandsOnLab: (state, { payload }) => {
      state.handsOnLab = payload;
    },
    handleChallengeExperience: (state, { payload }) => {
      state.challengeExperince = payload;
    },
    handleActiveCourse: (state, { payload }) => {
      state.activeCourse = payload;
    },
    handleApiErr: (state, { payload }) => {
      state.apiErr = payload;
    },
    handleLatestCourse: (state, {payload}) => {
      state.latestCourse = payload
    },
    handleUpcommingCourse: (state, {payload}) => {
      state.upcommingCourse = payload
    },
    handleIsEnrolled: (state, {payload}) => {
      console.log(payload, "aaaaa")
      state.isEnrolled = payload
    }
  },
});

export const {
  handleLoding,
  handleChallengeExperience,
  handleCourse,
  handleHandsOnLab,
  handleApiErr,
  handleActiveCourse,
  handleLatestCourse,
  handleUpcommingCourse,
  handleIsEnrolled
} = course.actions;
export default course.reducer;
