import React, { useEffect, useState } from "react";
import "./Categories.css";

const Categories = ({ categoriesData, handleCategoryData }) => {
  const [activeCategory, setActiveCategory] = useState("");
  const [categoryData, setCategoryData] = useState(categoriesData);

  useEffect(() => setActiveCategory(categoryData?.[0]), []);
  useEffect(() => handleCategoryData(activeCategory), [activeCategory]);

  return (
    <div>
      <div className=" d-flex categories my-2  justify-content-between mission-vissio-part">
        {categoryData?.map((el) => (
           
          
          <span
            className={`${
              el?.id === activeCategory?.id ? "categories_active" : ""
            } category_menu`}
            key={el?.id}
            onClick={() => setActiveCategory(el)}
          >
            <span><img className=" me-2" src={el.img}/></span>
            {el?.name}
          </span>
          
        ))}
      </div>
    </div>
  );
};

export default Categories;
