

const GmailLogo = () => {
  return (
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_8_8571)">
          <path
            d="M47.4433 36.2242C47.4433 35.2409 47.3635 34.5234 47.1909 33.7793H35.9629V38.2173H42.5535C42.4206 39.3202 41.7031 40.9811 40.1086 42.0972L40.0862 42.2458L43.6363 44.996L43.8822 45.0205C46.1411 42.9344 47.4433 39.8649 47.4433 36.2242Z"
            fill="#4285F4"
          />
          <path
            d="M35.9645 47.9178C39.1933 47.9178 41.9039 46.8548 43.8838 45.0211L40.1101 42.0978C39.1003 42.8021 37.7449 43.2937 35.9645 43.2937C32.802 43.2937 30.118 41.2076 29.1612 38.3242L29.0209 38.3361L25.3295 41.193L25.2812 41.3271C27.2478 45.2337 31.2872 47.9178 35.9645 47.9178Z"
            fill="#34A853"
          />
          <path
            d="M29.1594 38.3248C28.907 37.5807 28.7609 36.7834 28.7609 35.9596C28.7609 35.1357 28.907 34.3385 29.1461 33.5944L29.1394 33.4359L25.4018 30.5332L25.2795 30.5914C24.469 32.2125 24.0039 34.0329 24.0039 35.9596C24.0039 37.8863 24.469 39.7066 25.2795 41.3277L29.1594 38.3248Z"
            fill="#FBBC05"
          />
          <path
            d="M35.9645 28.624C38.21 28.624 39.7248 29.594 40.5885 30.4046L43.9635 27.1093C41.8907 25.1826 39.1933 24 35.9645 24C31.2872 24 27.2478 26.6841 25.2812 30.5906L29.1479 33.5936C30.118 30.7102 32.802 28.624 35.9645 28.624Z"
            fill="#EB4335"
          />
        </g>
        <rect
          x="0.8"
          y="0.8"
          width="69.86"
          height="70.4"
          rx="7.2"
          stroke="#D6D6D6"
          stroke-width="1.6"
        />
        <defs>
          <clipPath id="clip0_8_8571">
            <rect
              width="23.46"
              height="24"
              fill="white"
              transform="translate(24 24)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default GmailLogo