const LevelIcn = () => {
  return (
    <>
      <svg
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{marginRight:"5px"}}
      >
        <circle cx="14" cy="14.957" r="14" fill="#1B449C" fill-opacity="0.1" />
        <path
          d="M21 20.957H17V7.95703H21V20.957ZM16 20.957H12V11.957H16V20.957ZM11 20.957H7V14.957H11V20.957Z"
          fill="#1B449C"
        />
      </svg>
    </>
  );
};

export default LevelIcn;
