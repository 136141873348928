import { authApiUrls } from "../api/authApiUrls";

export const getGoogleOAuthURL = (userType) => {
  const rootUrl = authApiUrls.googleAuthRoot;
  const options = {
    redirect_uri: authApiUrls.googleAuthRedirectUri,
    client_id:
      "144570879-kmn7rt37ghnairrir4u8f762p1ls61ds.apps.googleusercontent.com",
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ].join(" "),
    state: userType,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};

export const getGoogleOAuthSignInURL = (userType) => {
  const rootUrl = authApiUrls.googleAuthRoot;
  const options = {
    redirect_uri: authApiUrls.googleAuthSignInRedirectUri,
    // client_id:
    //   "1058905247682-62e9hpoats9er0mi8ln8jn0vki6umcrk.apps.googleusercontent.com",
    client_id: "144570879-kmn7rt37ghnairrir4u8f762p1ls61ds.apps.googleusercontent.com",
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ].join(" "),
    state: userType,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};
