import {apiUrls} from "../../../api/apiUrls"
import http from "../../../services/axios/axiosInstance"
import {handleApiError,handleLoding,handleTrainingCalender} from "./trainingCalenderSlice"
 
export const readTrainingCalender = () => async (dispatch) => {
    try{
        dispatch(handleLoding("loading"))
        let localStorageData = JSON.parse(localStorage.getItem("techcsrf"))
        let accessToken = localStorageData.accessToken
        let config = {
            method: "get",
            url: `${apiUrls.trainingCalender}`,
            headers: {
                "authorization": `Bearer ${accessToken}`
            }
        }
        let response = await http(config);
        console.log(response.data)
        dispatch(handleTrainingCalender(response.data))
        dispatch(handleLoding("idle"))
    }catch(err){
        dispatch(handleApiError("Error While Fetching Training Calender Data"))
    }
}