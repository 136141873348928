import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormInput, CFormLabel, CFormTextarea } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import HeroSection from '../../components/HeroSection/HeroSection'
import ProfileImg from "../../assets/images/profile/ProfileImg.svg"
import ProfileIcon from "../../assets/images/profile/ProfileIcon.svg"
import PenLight from "../../assets/images/profile/PenLight.svg"
import PenDark from "../../assets/images/profile/PenDark.svg"
import LocationIcon from "../../assets/images/profile/LocationIcon.svg"
import UserIcon from "../../assets/images/profile/UserIcon.svg"
import DownloadInfo from "../../assets/images/profile/DownloadInfo.svg"
import Categories from '../../components/Categories/Categories'
import reset from "../../assets/images/profile/reset.svg"
import download from "../../assets/images/profile/download.svg"
import userDelete from "../../assets/images/profile/userDelete.svg"
import { useDispatch, useSelector } from 'react-redux'
import './profile.css'
import { customStyles } from "../../services/helper.js"
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import http from "../../services/axios/axiosInstance"
import { apiUrls } from "../../api/apiUrls"
import { readUserProfile } from '../../redux/slices/userProfile/userProfileActions'
import httpAuth from "../../services/axios/axiosAuthInstance"
import { resolvePath, useNavigate } from 'react-router-dom'
import axios from 'axios'
// import axios from 'axios'

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categoryData = [
    { id: 1, name: "My Profile", value: "My Profile", label: "My Profile" },
    {
      id: 2,
      name: "My Purchases ",
      value: "My Purchases ",
      label: "My Purchases ",
    },
  ];
  const [submitLoader, setSubmitLoader] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedData, setSelectedData] = useState()
  const [image, setImage] = useState()
  const [imageUrl, setImageUrl] = useState("")

  const selectEditData = (data) => {
    setModalIsOpen(true);
    setSelectedData(data);
  };

  const updateProfile = async () => {
    try {
      setSubmitLoader(true);
      const { user_org, user_description, user_email } = selectedData;
      let localStorageData = JSON.parse(localStorage?.getItem("techcsrf"));
      let accessToken = localStorageData.accessToken
      if (!(user_org && user_description)) {
        toast.error("All fields required", { autoClose: 3000 });
        setSubmitLoader(false);
        return;
      }
      const payload = {
        userOrg: user_org,
        userDescription: user_description,
      };
      let config = {
        method: "patch",
        url: `${apiUrls.readUserProfile}`,
        data: payload,
        headers: {
          "authorization": `Bearer ${accessToken}`
      }
      };
      await httpAuth(config);
      toast.success('About Update', { autoClose: 1500 })
      setModalIsOpen(false)
      setSubmitLoader(false)
      dispatch(readUserProfile())
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!", { autoClose: 3000 });
      setSubmitLoader(false);
    }
  }

  const updateProfileImage = async (imageUrlP) => {
    console.log(imageUrlP, "imageUrlP")
    try {
      setSubmitLoader(true)
      let localStorageData = JSON.parse(localStorage?.getItem("techcsrf"));
      let accessToken = localStorageData.accessToken

      const payload = {
        userProfileImage: imageUrlP
      }
      let config = {
        method: "patch",
        url: `${apiUrls.readUserProfile}`,
        data: payload,
        headers: {
          "authorization": `Bearer ${accessToken}`
      }
      }
      const res = await httpAuth(config);
      if (res.status === 200) {
        toast.success('Image Uploaded', { autoClose: 1000 })
      }
      // setModalIsOpen(false)
      setSubmitLoader(false)
      dispatch(readUserProfile())
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong!', { autoClose: 3000 })
      setSubmitLoader(false)
    }
  }

  const handleCategoryData = (cateData) => {
    console.log(cateData, "aaaaaa");
    if (cateData?.id === 2) {
      navigate("/traning-calender");
    }
  };
  const userProfile = useSelector(
    (state) => state.userProfileState.userProfile
  );
  // console.log(userProfile)

  const handleResetPassword = () => {
    navigate("/forgetResetPassword");
  };

  const deleteUserAccount = () => {
    JSON.parse(localStorage?.removeItem("techcsrf"));
    // navigate("/")
  };






  const saveImage = (e) => {
    const imageFile = e.target.files[0]
    // console.log(imageFile,5255555)
    setImage(imageFile)
  }

  const handleSendImage = async () => {
    try {

      const formData = new FormData();
      formData.append('image', image);
      // console.log("image", formData)

      let config = {
        method: "post",
        url: `${apiUrls.imageUploadProfile}`,
        data: formData
      }
      const res = await http(config); 
      setImageUrl(res?.data?.url)
      if (res.status === 200) {
        updateProfileImage(res?.data?.url)
      }


    } catch (err) {
      console.log(err)
    }

  }

  // console.log("imageUrl", imageUrl)



  // const inputRef = useRef(null);
  // const [image, setImage] = useState("")
  // const [imageUrl, setImageUrl] = useState("")


  // const handleClick = () => {
  //   inputRef.current.click();

  // };

  // const handleFileChange = async event => {
  //   const fileObj = event.target.files && event.target.files[0];

  //   if (!fileObj) {
  //     return;
  //   }
  //   console.log("fileObj", fileObj)
  //   const res = await axios.post("http://localhost:4003/api/v1/azureProfileImageUpload", fileObj)
  //   console.log(res, 5255)
  // };


  // const handleFileChange = async (e) => {
  //   const imageFile = e.target.files[0]
  //   setImage(imageFile)
  //   try {
  //     if (!image) {
  //       return
  //     }
  //     const formData = new FormData();
  //     formData.append('image', image);
  //     const res = await axios.post(`http://localhost:4003/api/v1/azureProfileImageUpload`, formData)
  //     setImageUrl(res.data.url)
  //     toast.success('Image Uploaded', { autoClose: 1000 })

  //   } catch (err) {
  //     console.log(err)
  //   }

  // };
  // console.log(imageUrl,5252525)
  // 
  return (
    <>
      <div className="margin_bottom">
        <HeroSection />

        <div className="my_conatiner ">
          <div className="Main_container p-lg-4 mx-lg-5 p-2 position-relative background-color">
            <div className="media profile_positon ">
              <img
                className={"align-self-start-image mr-3 w-100 h-100"}
                src={userProfile?.user_profile_image || ProfileImg}
                alt="Profile "
              />
              <img
                class="position_pen p-3 rounded-circle bg-blue shadow"
                src={PenLight}
                alt=""
                data-target="#exampleModal4"
                data-toggle="modal"
              />
              <div class="media-body ">
                <div class="user-title-profile px-2 my-1">
                  {userProfile?.user_name || "User Name"}
                </div>
                <div className="company-section-user fs-3 w-200">
                  <span className="p-lg-2">
                    <img src={ProfileIcon} alt="" />
                  </span>
                  <div className="user-profile-company">
                    {userProfile?.user_org || "Company name"}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 pt-5 categorywidthProfile">
              <Categories
                categoriesData={categoryData}
                handleCategoryData={handleCategoryData}
              />
            </div>
            <div className="d-flex justify-content-between py-4 ">
              <h2>About</h2>
              <img
                className="cursor-pointer"
                src={PenDark}
                onClick={() => selectEditData(userProfile)}
              />
            </div>
            <p>{userProfile?.user_description || "Write About Yourself"}</p>
            <div class="d-flex justify-content-lg-between flex-wrap pt-5">
              <h5 className="fs-5 fw-light">
                {" "}
                <span>
                  <img src={LocationIcon} alt="" />
                </span>
                {userProfile?.user_location || "Location"}
              </h5>
              <h5 className="fs-5 fw-light">
                {" "}
                <span>
                  <img src={DownloadInfo} alt="" />
                </span>
                {userProfile?.user_email || "Email ID"}
              </h5>
              <h5 className="fs-5 fw-light">
                {" "}
                <span>
                  <img src={UserIcon} alt="" />
                </span>
                {userProfile?.user_name || "User Name"}
              </h5>
            </div>
            <div></div>
          </div>
          <div className="p-lg-4 mx-lg-5 mt-4 background-color1 p-2">
            <div className="d-flex justify-content-lg-between ">
              <h2>Account Management</h2>
            </div>
            <div class="d-flex justify-content-between flex-wrap pt-5">
              <h5
                onClick={handleResetPassword}
                style={{flex:1}}
                className="fs-5 fw-light"
              >
                {" "}
                <span>
                  <img className="me-2" src={reset} alt="" />
                </span>
                Reset Password
              </h5>
              
              <h5
                className="fs-5 fw-light"
                style={{flex:1}}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal3"
              >
                {" "}
                <span>
                  <img className="me-2" src={userDelete} alt="" />
                </span>
                Delete Account
              </h5>
              
            </div>
          </div>
        </div>
        {/* change details  */}
        {/* <div
          class="modal fade modal-width-css"
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"

        >
          <div class="modal-dialog modal-dialogues-corner">
            <div className="modal-content tab-english-popup">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close pr-5"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                </button>
              </div>
              <div class="modal-body pb-5">
                <form className='form-profilepage'>
                  
                  <label>Email</label><br />
                  <input type='text' placeholder='Change Email Address' className='w-100' />
                  <label>About</label><br />
                  <input type='text' placeholder='Set About ' className='w-100' />
                  <label>Organisation</label><br />
                  <input type='text' placeholder='Change Organisation' className='w-100' />
                  <div className='flex-box-cra-button'>
                    <button type="reset" class="">
                      Clear Form
                    </button>
                    <button type="submit" class="">
                      Submit
                    </button>

                  </div>


                </form>


              </div>
            </div>
          </div>
        </div> */}
        {/* reset password */}
        <div
          class="modal fade modal-width-css"
          id="exampleModal2"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialogues-corner">
            <div className="modal-content tab-english-popup">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close pr-5"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pb-5">
                <form className="form-profilepage">
                  <label>Change Password</label>
                  <br />
                  <input
                    type="text"
                    placeholder="Change Password"
                    className="w-100"
                  />
                  <div className="flex-box-cra-button">
                    <button type="reset" class="">
                      Clear Form
                    </button>
                    <button type="submit" class="">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* delete Account */}
        <div
          class="modal fade modal-width-css"
          id="exampleModal3"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialogues-corner">
            <div className="modal-content tab-english-popup">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close pr-5"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pb-5">
                <form className="form-profilepage">
                  <h4 className="text-center">
                    Are You Sure You Want To Delete This Account ?.
                  </h4>
                  <div className="flex-box-cra-button">
                    <button type="submit" class="" onClick={deleteUserAccount}>
                      Yes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>



        <div
          class="modal fade modal-width-css"
          id="exampleModal4"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"

        >
          <div class="modal-dialog modal-dialogues-corner">
            <div className="modal-content tab-english-popup">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close pr-5"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                </button>
              </div>
              <div class="modal-body pb-5">
                <input type='file' onChange={saveImage} />
                <button onClick={handleSendImage}>Upload Image</button>

              </div>
            </div>
          </div>
        </div>





      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Update Profile</strong>
          </CCardHeader>
          <CCardBody>
            <CForm>
              {/* <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Email</CFormLabel>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Change Email Address"
                  value={selectedData?.user_email}
                  onChange={(e) => setSelectedData({ ...selectedData, user_email: e.target.value })}
                />
              </div> */}
              <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">
                  Organisation
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Change organisation"
                  value={selectedData?.user_org}
                  onChange={(e) =>
                    setSelectedData({
                      ...selectedData,
                      user_org: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlTextarea1 ">
                  Write About Yourself
                </CFormLabel>
                <CFormTextarea
                  id="exampleFormControlTextarea1"
                  rows="3"
                  value={selectedData?.user_description}
                  onChange={(e) =>
                    setSelectedData({
                      ...selectedData,
                      user_description: e.target.value,
                    })
                  }
                ></CFormTextarea>
              </div>
              <div className="mb-3">
                <CButton
                  className="me-3 px-4"
                  color={"success"}
                  onClick={selectedData?.id ? updateProfile : null}
                  disabled={submitLoader}
                >
                  {submitLoader ? (
                    <div
                      class="spinner-border spinner-border-sm text-secondary"
                      role="status"
                    >
                      <span class="sr-only"></span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </CButton>
                <CButton
                  className="me-3 px-4"
                  color={"danger"}
                  onClick={() => setModalIsOpen(false)}
                >
                  cancel
                </CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </Modal>
    </>
  );
};

export default Profile;
