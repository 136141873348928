import CheckoutArrow from "../../assets/svgs/CheckoutArrow";
import PromIc from "../../assets/svgs/PromIc";
import AppTable from "../../components/AppTable/AppTable";
import AppTableMobile from "../../components/AppTable/AppTableMobile";
import HeroSection from "../../components/HeroSection/HeroSection";
import { readCourseCart } from "../../redux/slices/courseCart/courseCartActions";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../hooks/useWindowSize";
import "./Cart.css";
import { useEffect } from "react";
import useLocalStorge from "../../hooks/useLocalStorage";
import { useState } from "react";
import PaymentGateway from "../../paymentGateway/PaymentGateway";
import PaymentProtectedRoute from "../../hoc/PaymentProtectedRoute";
import { verifyCouponCode } from "../../redux/slices/coupon/couponActions";

const Cart = () => {
  const dispatch = useDispatch();
  const crumbs = [{ name: "My Cart" }];
  const { width } = useWindowSize();
  const localStorageItem = useLocalStorge();
  const cartCourses = useSelector((state) => state.courseCartState.courses);
  const isValidCouponCode = useSelector(
    (state) => state.couponCodeState.isValid
  );
  const couponInfo = useSelector((state) => state.couponCodeState.couponInfo);
  // const [cartCourses, setCartCourses] = useState([])
  const [totalAmount, setTotalAmout] = useState(0);
  const [couponCode, setCouponCode] = useState("");


  const handleSetAmount = (courseAmt) => {
    setTotalAmout(courseAmt);
  };

  const handleChangeCouponCode = (e) => {
    setCouponCode(e.target.value);
  };

  const handleApplyDiscount = () => {
    dispatch(verifyCouponCode(couponCode));
  };

  useEffect(() => {
    dispatch(readCourseCart());
    localStorage.removeItem("couponCode")
  }, []);

  useEffect(() => {
    let totalCourseAmount = 0;
    if (cartCourses.length > 0) {
      cartCourses.forEach((ele) => {
        totalCourseAmount = totalCourseAmount + (ele.course_mrp - ((ele?.course_mrp / 100) * ele?.course_discount)) * ele.quantity;
      });
      if (isValidCouponCode === true) {
        handleSetAmount(totalCourseAmount - (totalCourseAmount / 100) * couponInfo?.coupon_discount);
      } else {
        handleSetAmount(totalCourseAmount);
      }
    } else {
      setTotalAmout(0);
    }
  }, [cartCourses, isValidCouponCode]);

  return (
    <>
      <div className="cart-con">
        <HeroSection title="My Cart" crumbs={crumbs} />
        <div className="cart-wrap">
          <div className="cart-qun-hd">
            {cartCourses > 0 ? cartCourses.length : null} Courses in cart
          </div>
          <div className="table-con">
            {width < 890 ? (
              <AppTableMobile cartCourses={cartCourses} tableName="cart" />
            ) : (
              <AppTable cartCourses={cartCourses} tableName="cart" />
            )}
          </div>
          <div className="checkout-con">
            <div className="checkout-wrap">
              <div className="amt-con">
                <div className="amt-left">TOTAL AMOUNT :</div>
                <div className="amt-right">{totalAmount.toFixed(2)}</div>
              </div>
              <div className="prom-wrap">
                <div className="prom-lb">
                  <div className="prom-tx">PROMOTION DISCOUNT</div>
                  <div className="prom-ic">
                    <PromIc />
                  </div>
                </div>
                <div className="discount-con">
                  <input
                    onChange={handleChangeCouponCode}
                    placeholder="DISCOUNT100"
                    className="prom-in"
                  />
                  <button
                    onClick={handleApplyDiscount}
                    className="coupon-apply-btn"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div className="checkout-btn-con">
                {/* <button className="checkout-btn">
                  Checkout &nbsp; &nbsp;
                  <CheckoutArrow />
                </button> */}
              </div>
              <PaymentProtectedRoute>
                <PaymentGateway />
              </PaymentProtectedRoute>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
