import {
    handleApiErr, handleLoding, handleTestimonial
} from "./testimonialSlice";
import http from "../../../services/axios/axiosInstance";
import { apiUrls } from "../../../api/apiUrls";

 

export const readTestimonialData = () => async (dispatch) => {
    try {
        dispatch(handleLoding("loading"));
        let config = {
            method: "get",
            url: apiUrls.readTestimonialData,
        };
        let response = await http(config);
        // console.log("response",response)
        dispatch(handleTestimonial(response?.data?.data));
        dispatch(handleLoding("idle"));
    } catch (err) {
        console.log(err)
        dispatch(handleApiErr(`Error While Fetching Course Category Data`));
    }
};
