import {getLocalStorage} from "../../../services/getLocalStorage"
import {apiUrls} from "../../../api/apiUrls"
import http from "../../../services/axios/axiosInstance"
import httpAuth from "../../../services/axios/axiosAuthInstance"
import {handleApiError,handleLoding,handleUserProfile} from "../userProfile/userProfileSlice"

export const readUserProfile = () => async (dispatch) => {
    try{
        dispatch(handleLoding("loading"))
        let localStorageData = JSON.parse(localStorage.getItem("techcsrf"))
        let accessToken = localStorageData.accessToken
        let config = {
            method: "get",
            url: `${apiUrls.readUserProfile}`,
            headers: {
                "authorization": `Bearer ${accessToken}`
            }
        }
        let response = await http(config);
        dispatch(handleUserProfile(response.data.data))
        dispatch(handleLoding("idle"))
    }catch(err){
        dispatch(handleApiError("Error While Fetching User Profile Data"))
    }
}