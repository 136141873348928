import ProgressBar from "react-bootstrap/ProgressBar";
import "./ProgressBarCourse.css";
const ProgressBarCourse = ({ now }) => {
  return (
    <div className="course-progress-con">
      <ProgressBar className="course-progress-bar" now={now} visuallyHidden />
    </div>
  );
};

export default ProgressBarCourse;
