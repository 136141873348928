import { Navigate, useLocation } from "react-router-dom";

const AuthRedirectPage = () => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const accessToken = queryParameters.get("accessToken");
  const email = queryParameters.get("email");
  const userType = queryParameters.get("userType")
  const userId = queryParameters.get("userId")
  const tokenObj = {
    accessToken,
    email,
    userType,
    userId
  };
  localStorage.setItem("techcsrf", JSON.stringify(tokenObj));
  return (
    <>
      {" "}
      <Navigate to="/" state={{ from: location }} replace />
    </>
  );
};

export default AuthRedirectPage;
