const TechnoFocusLogo = () => {
  return (
    <>
      <svg
        width="181"
        height="40"
        viewBox="0 0 181 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.7574 0H26.4014C26.2573 0.231966 26.096 0.452497 25.9188 0.659649C21.9153 4.70569 17.907 8.74484 13.8939 12.7771C13.7043 12.9463 13.5536 13.1554 13.4522 13.3897C13.3508 13.624 13.3013 13.8779 13.3071 14.1336C13.3275 22.4573 13.333 30.7802 13.3235 39.1025C13.3235 39.4024 13.3644 39.7022 13.3868 40H26.3973C26.4136 39.603 26.4443 39.2452 26.4443 38.8875C26.4443 30.6948 26.4443 22.5021 26.4443 14.3094C26.4443 13.292 26.4443 13.292 27.4667 13.292H39.7165C39.7165 12.3366 39.7165 11.5281 39.7165 10.7196L39.7574 0Z"
          fill="white"
        />
        <path d="M0 13.3085H13.2006V0H0V13.3085Z" fill="white" />
        <path
          d="M98.7403 18.9937C98.5154 17.4098 97.5442 16.4379 96.0311 16.1753C94.3463 15.8837 92.8373 16.1939 91.7168 17.6372C91.6677 17.7014 91.5777 17.7303 91.4019 17.844V12.0996H88.4023V27.6951C88.6293 27.7076 88.8113 27.7262 88.983 27.7262H91.4366C91.4366 27.3664 91.4366 27.1058 91.4366 26.8535C91.4489 24.8457 91.4121 22.8357 91.4919 20.832C91.5552 19.277 92.4508 18.4685 93.8515 18.5057C95.1457 18.5409 95.7775 19.2791 95.8225 20.8858C95.8532 22.0334 95.8348 23.1811 95.8369 24.3287V27.6848H98.9223C98.9223 25.8238 98.9407 24.0082 98.9223 22.1906C98.9305 21.1221 98.8697 20.0542 98.7403 18.9937Z"
          fill="white"
        />
        <path
          d="M65.3944 12.0957H52.7295V14.9141H57.3853V27.6644H60.759V16.5994C60.759 14.908 60.7591 14.908 62.4132 14.908H65.3944V12.0957Z"
          fill="white"
        />
        <path
          d="M109.657 16.5348C108.758 16.0894 107.742 15.9476 106.758 16.1304C105.774 16.3132 104.874 16.8109 104.191 17.5501C104.116 17.6349 104.021 17.7052 103.872 17.8396C103.829 17.3102 103.797 16.8801 103.762 16.4604H100.969V27.6763H103.968C103.968 25.8897 103.948 24.1465 103.968 22.4054C103.961 21.6251 104.034 20.8462 104.185 20.0811C104.439 18.9666 105.283 18.4413 106.457 18.5075C107.553 18.5675 108.182 19.0493 108.256 20.261C108.385 22.3661 108.352 24.4815 108.385 26.5927C108.385 26.9484 108.385 27.304 108.385 27.6907H111.319C111.319 24.7606 111.378 21.9008 111.288 19.039C111.264 17.972 110.665 17.0642 109.657 16.5348Z"
          fill="white"
        />
        <path
          d="M165.804 21.9258C165.805 22.6796 165.716 23.4307 165.54 24.1632C165.256 25.2281 164.403 25.681 163.17 25.5818C162.172 25.5011 161.571 25.0028 161.504 23.8696C161.387 21.8969 161.41 19.9138 161.377 17.9349C161.377 17.4469 161.377 16.9589 161.377 16.4316H158.445C158.445 19.3638 158.365 22.2215 158.48 25.0813C158.492 25.7073 158.708 26.3116 159.096 26.8C159.483 27.2885 160.019 27.6337 160.621 27.7819C162.545 28.3278 164.315 28.1376 165.675 26.444C165.756 26.3794 165.845 26.3265 165.941 26.2869C165.965 26.7976 165.984 27.2298 166.004 27.664H168.818V16.4626H165.826C165.826 18.332 165.841 20.1289 165.804 21.9258Z"
          fill="white"
        />
        <path
          d="M180.751 23.8992C180.68 22.4331 179.717 21.699 178.453 21.2813C177.889 21.0952 177.304 20.9774 176.733 20.8161C175.916 20.5845 175.073 20.4025 174.3 20.0696C174.133 19.9703 173.992 19.8308 173.891 19.6634C173.79 19.496 173.731 19.306 173.72 19.1101C173.748 18.8041 174.149 18.4008 174.476 18.283C175.887 17.7557 177.061 18.3016 177.607 19.6581H180.381C180.355 18.1403 179.494 16.9699 177.968 16.4591C176.306 15.9176 174.516 15.9226 172.857 16.4736C172.49 16.5988 172.145 16.7809 171.834 17.0133C170.832 17.7391 170.526 18.7917 170.595 19.9703C170.665 21.149 171.397 21.8148 172.401 22.1932C173.147 22.4745 173.938 22.6296 174.703 22.8611C175.468 23.0927 176.308 23.2892 177.048 23.6366C177.827 24.0026 177.891 24.8525 177.267 25.4376C176.21 26.4261 173.615 26.058 173.29 24.5133C173.26 24.4163 173.207 24.3281 173.136 24.2561C173.064 24.1841 172.977 24.1305 172.881 24.0998C172.063 24.0584 171.245 24.0812 170.344 24.0812C170.393 24.4575 170.403 24.7408 170.468 25.0096C170.581 25.5234 170.811 26.0031 171.141 26.4098C171.471 26.8165 171.892 27.1387 172.368 27.3504C174.505 28.3202 176.693 28.3471 178.823 27.3504C180.226 26.6928 180.819 25.4439 180.751 23.8992Z"
          fill="white"
        />
        <path
          d="M154.744 23.3886C154.245 23.3638 153.969 23.4693 153.811 24.0276C153.503 25.109 152.767 25.6343 151.718 25.6818C150.607 25.7418 149.804 25.2972 149.36 24.2674C149.043 23.5645 148.884 22.7996 148.893 22.0271C148.902 21.2547 149.08 20.4938 149.413 19.7988C149.873 18.8022 150.691 18.3514 151.801 18.4113C153.028 18.4713 153.642 19.2116 153.901 20.3096H156.862C156.752 18.6719 155.985 17.5036 154.613 16.7757C153.832 16.3599 152.974 16.1135 152.094 16.0522C151.214 15.991 150.331 16.1163 149.501 16.42C147.129 17.2348 146.093 19.1082 145.886 21.4903C145.682 23.918 146.358 26.054 148.599 27.2802C150.732 28.4486 152.947 28.3824 154.991 26.9866C156.181 26.176 156.741 24.9353 156.921 23.3907C156.126 23.3969 155.433 23.4217 154.744 23.3886Z"
          fill="white"
        />
        <path
          d="M83.9038 23.8537C83.4948 25.1378 82.7751 25.6961 81.5421 25.6961C80.4196 25.6961 79.5997 25.0758 79.2132 23.8744C78.8693 22.802 78.848 21.6505 79.1519 20.5659C79.524 19.197 80.3235 18.4732 81.4849 18.4257C82.8058 18.3719 83.3967 18.8247 83.9385 20.3136H86.8543C86.7827 19.1122 86.3963 18.2458 85.6152 17.4972C83.4314 15.4066 79.0844 15.6238 77.2319 18.0183C75.5552 20.1854 75.3815 22.6502 76.5347 25.0903C77.6511 27.4559 79.755 28.192 82.223 28.0183C84.8157 27.8487 86.8481 25.8512 86.8767 23.4381H84.049C83.9917 23.6097 83.9447 23.7338 83.9038 23.8537Z"
          fill="white"
        />
        <path
          d="M128.377 12.6916C127.093 13.5643 127.028 14.9352 127.026 16.3455L125.23 16.4344V18.4381H127.071V27.6627H130.138V18.4216H132.205V16.3538H130.044C130.164 15.7334 130.099 14.9973 130.395 14.8008C130.941 14.4369 131.698 14.3873 132.405 14.2053V12.1044C130.986 12.0444 129.612 11.8542 128.377 12.6916Z"
          fill="white"
        />
        <path
          d="M74.799 22.823C74.7622 22.1862 74.7601 21.6237 74.6947 21.0695C74.2163 16.9773 70.1739 14.8288 66.5445 16.7271C63.9989 18.0588 62.8641 21.3611 63.9171 24.3925C64.8393 27.0538 67.5014 28.491 70.4785 27.9554C72.5682 27.5811 73.8646 26.3446 74.5414 24.2498C73.9014 24.2498 73.337 24.2747 72.7768 24.2498C72.2165 24.225 71.8465 24.3284 71.4743 24.8206C70.7996 25.7118 69.2456 25.9641 68.1128 25.4988C67.1539 25.1059 66.6243 24.1258 66.7142 22.8272L74.799 22.823ZM69.0881 18.4227C70.6135 18.3731 71.5091 19.2126 71.5827 20.7987H66.7245C66.6979 19.3925 67.6159 18.4682 69.0881 18.4227Z"
          fill="white"
        />
        <path
          d="M118.918 16.084C115.388 16.084 113.086 18.4392 113.084 22.0579C113.082 25.6766 115.388 28.0339 118.918 28.0339C122.447 28.0339 124.741 25.6808 124.735 22.0559C124.729 18.431 122.465 16.084 118.918 16.084ZM121.396 23.8983C121.019 25.1038 120.13 25.7076 118.885 25.6994C117.674 25.6994 116.84 25.0997 116.45 23.9066C116.044 22.7133 116.044 21.4171 116.45 20.2238C116.858 18.9831 117.709 18.3958 118.95 18.4165C120.191 18.4372 121.06 19.0596 121.414 20.2775C121.553 20.8828 121.655 21.496 121.721 22.1138C121.65 22.7151 121.542 23.3111 121.396 23.8983Z"
          fill="white"
        />
        <path
          d="M138.739 16.084C135.202 16.0716 132.926 18.4041 132.92 22.0497C132.914 25.6953 135.183 28.0464 138.721 28.0464C142.258 28.0464 144.569 25.6787 144.569 22.0766C144.569 18.4744 142.279 16.0964 138.739 16.084ZM141.193 23.9418C140.806 25.1266 139.966 25.7036 138.739 25.7056C137.512 25.7077 136.654 25.1246 136.286 23.948C135.854 22.6786 135.876 21.2967 136.347 20.0419C136.756 18.9376 137.574 18.4124 138.743 18.4207C139.913 18.429 140.77 18.9624 141.146 20.0398C141.373 20.6932 141.444 21.4025 141.583 22.0745C141.486 22.7041 141.352 23.3275 141.185 23.9418H141.193Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default TechnoFocusLogo;
