import { Form, Toast } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./AppModal.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import http from "../../services/axios/axiosInstance";
import { toast } from "react-toastify";

const AppModal = ({ show, handleClose, onRequestSubmit, courseData }) => {
  const contactUsSchema = yup
    .object({
      email_address: yup.string().required().email(),
      phone_number: yup.string().required().max(14),
      enquiry: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactUsSchema),
  });
  const onSubmit = async (data) => {
    try {
      let config = {
        method: "post",
        url: "courseEnquiry",
        data: {
          email: data?.email_address,
          phoneno: data?.phone_number,
          enquiry: data?.enquiry,
          courseId: courseData?.id,
        },
      };
      await http(config);
      toast.success("Sucessfully Submitted");
    } catch (err) {
      toast.error("Something Went Wrong");
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="">
            <Form className="p-5 m-auto border">
              <h3 className="mb-3 contact-form-heading">Course Request</h3>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Eamil</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  {...register("email_address")}
                  className="signinup-input"
                />
                <div className="error-messages">
                  {errors.email_address?.message}
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Phone No</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="+91 7832178321"
                  {...register("phone_number")}
                  className="signinup-input"
                />
                <div className="error-messages">
                  {errors.phone_number?.message}
                </div>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Enquiry</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  className="signinup-input"
                  placeholder="Enquiry"
                  {...register("enquiry")}
                />
                <div className="error-messages">{errors.enquiry?.message}</div>
              </Form.Group>

              <div className="row">
                <div className="col-md-6">
                  <button type="reset" class="btn btn-outline-primary w-100">
                    Clear
                  </button>
                </div>
                <div className="col-md-6">
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={onRequestSubmit}>
            Submit
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AppModal;
