const SignUpInMid = () => {
  return (
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 487 254"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_dd_8_8468)">
          <rect
            x="20.5"
            y="12"
            width="446"
            height="213.339"
            rx="16"
            fill="white"
          />
        </g>
        <path
          d="M20.5 28C20.5 19.1635 27.6634 12 36.5 12H450.5C459.337 12 466.5 19.1634 466.5 28V43.3971H20.5V28Z"
          fill="#DBDBDB"
        />
        <rect
          x="47.0664"
          y="119.072"
          width="80.5054"
          height="80.5054"
          rx="5"
          fill="#F4F4F4"
        />
        <g clip-path="url(#clip0_8_8468)">
          <path
            d="M79.9354 139.2H92.676L79.45 178.215C79.3141 178.616 79.0553 178.964 78.7101 179.211C78.3649 179.458 77.9506 179.591 77.5256 179.591H67.6102C67.2882 179.591 66.9709 179.515 66.6844 179.368C66.398 179.222 66.1506 179.01 65.9628 178.749C65.775 178.489 65.6522 178.188 65.6044 177.871C65.5567 177.554 65.5855 177.23 65.6883 176.926L78.0105 140.575C78.1464 140.174 78.4052 139.826 78.7505 139.579C79.0959 139.332 79.5103 139.199 79.9354 139.199V139.2Z"
            fill="url(#paint0_linear_8_8468)"
          />
          <path
            d="M98.45 165.369H78.2465C78.0587 165.369 77.8752 165.425 77.7198 165.53C77.5645 165.635 77.4446 165.785 77.3757 165.959C77.3068 166.133 77.2922 166.323 77.3336 166.506C77.375 166.688 77.4707 166.854 77.608 166.981L90.5903 179.045C90.9684 179.396 91.4661 179.592 91.9829 179.591H103.423L98.45 165.369Z"
            fill="#0078D4"
          />
          <path
            d="M79.9349 139.199C79.5051 139.198 79.0861 139.333 78.739 139.585C78.3919 139.837 78.1349 140.194 78.0056 140.602L65.703 176.893C65.5931 177.198 65.5586 177.524 65.6024 177.845C65.6462 178.166 65.767 178.472 65.9546 178.737C66.1422 179.001 66.391 179.217 66.6801 179.366C66.9691 179.514 67.2899 179.592 67.6152 179.591H77.7863C78.1651 179.523 78.5192 179.357 78.8124 179.109C79.1055 178.861 79.3272 178.539 79.4549 178.178L81.9084 170.979L90.6717 179.117C91.039 179.42 91.4997 179.587 91.9764 179.591H103.374L98.375 165.368L83.8028 165.372L92.7216 139.199H79.9349Z"
            fill="url(#paint1_linear_8_8468)"
          />
          <path
            d="M96.2091 140.573C96.0733 140.173 95.8149 139.825 95.4701 139.578C95.1253 139.332 94.7115 139.199 94.287 139.199H80.0879C80.9583 139.199 81.7316 139.752 82.0099 140.573L94.3324 176.925C94.4355 177.229 94.4643 177.553 94.4167 177.87C94.369 178.187 94.2462 178.489 94.0584 178.749C93.8705 179.01 93.6231 179.222 93.3365 179.368C93.05 179.515 92.7325 179.591 92.4104 179.591H106.61C106.932 179.591 107.25 179.515 107.536 179.368C107.823 179.222 108.07 179.009 108.258 178.749C108.446 178.488 108.568 178.187 108.616 177.87C108.664 177.553 108.635 177.229 108.532 176.925L96.2089 140.573H96.2091Z"
            fill="url(#paint2_linear_8_8468)"
          />
        </g>
        <rect
          x="151.725"
          y="119.072"
          width="80.5054"
          height="80.5054"
          rx="5"
          fill="#F4F4F4"
        />
        <path
          d="M175.877 136.783L207.823 148.162V163.775C207.823 165.049 206.591 165.914 205.404 165.504L198.696 163.137V154.262C198.696 152.35 197.509 150.666 195.729 149.983L192.626 148.8C192.033 148.572 191.394 149.027 191.394 149.664V160.497L175.877 154.99V136.783Z"
          fill="url(#paint3_linear_8_8468)"
        />
        <path
          opacity="0.24"
          d="M207.823 151.531C207.823 153.443 206.637 155.173 204.811 155.81L184.092 163.366V181.572L207.823 172.924V151.531Z"
          fill="black"
        />
        <path
          opacity="0.32"
          d="M207.823 152.26C207.823 154.171 206.637 155.901 204.811 156.538L184.092 164.094V182.301L207.823 173.653V152.26Z"
          fill="black"
        />
        <path
          d="M207.823 151.348C207.823 153.259 206.637 154.989 204.811 155.626L184.092 163.182V181.389L207.823 172.74V151.348Z"
          fill="url(#paint4_linear_8_8468)"
        />
        <path
          opacity="0.5"
          d="M207.823 151.348C207.823 153.259 206.637 154.989 204.811 155.626L184.092 163.182V181.389L207.823 172.74V151.348Z"
          fill="url(#paint5_linear_8_8468)"
        />
        <path
          opacity="0.5"
          d="M198.695 157.859L191.393 160.545V171.241C191.393 171.878 192.031 172.334 192.625 172.106L195.728 170.923C197.508 170.24 198.695 168.556 198.695 166.644V157.859Z"
          fill="#B0ADFF"
        />
        <rect
          x="256.381"
          y="119.072"
          width="80.5054"
          height="80.5054"
          rx="5"
          fill="#F4F4F4"
        />
        <mask
          id="mask0_8_8468"
          style={{"mask-type":"alpha"}}
          maskUnits="userSpaceOnUse"
          x="277"
          y="138"
          width="40"
          height="42"
        >
          <path
            d="M292.068 138.395H309.795C314.756 138.395 317.997 143.573 315.867 148.023L316.196 147.366L310.035 159.688C310.018 159.725 310 159.762 309.982 159.798L309.019 161.721L309.982 159.795C308.842 162.075 306.512 163.515 303.964 163.515H293.319L285.783 178.588C285.191 179.77 283.504 179.77 282.912 178.588L277.648 168.059C277.168 167.1 277.23 166.062 277.663 165.221L283.46 153.626C284.004 152.539 285.115 151.852 286.331 151.852H308.545C307.369 150.752 305.782 150.058 303.965 150.058H287.841C286.648 150.058 285.872 148.802 286.406 147.735L290.633 139.281C290.904 138.738 291.46 138.395 292.068 138.395Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_8_8468)">
          <path
            d="M280.457 163.516H293.319L285.783 178.588C285.191 179.771 283.504 179.771 282.912 178.588L277.648 168.06C276.604 165.972 278.122 163.516 280.457 163.516Z"
            fill="url(#paint6_linear_8_8468)"
          />
          <path
            d="M292.068 138.395H309.795C314.797 138.395 318.05 143.658 315.814 148.132L310.036 159.688C310.018 159.725 310 159.762 309.982 159.798L309.019 161.721L310.036 159.688C312.168 155.238 308.927 150.058 303.965 150.058H287.841C286.648 150.058 285.872 148.802 286.406 147.735L290.633 139.281C290.905 138.738 291.46 138.395 292.068 138.395Z"
            fill="url(#paint7_linear_8_8468)"
          />
          <path
            d="M292.068 138.395H309.795C314.797 138.395 318.05 143.658 315.814 148.132L310.023 159.714C310.009 159.742 309.995 159.77 309.981 159.798L309.468 160.823L310.023 159.714C312.18 155.258 308.936 150.058 303.964 150.058H287.841C286.648 150.058 285.872 148.802 286.406 147.735L290.633 139.281C290.905 138.738 291.46 138.395 292.068 138.395Z"
            fill="url(#paint8_linear_8_8468)"
          />
          <g filter="url(#filter1_f_8_8468)">
            <path
              d="M303.961 163.694H280.497C279.282 163.694 278.171 164.38 277.627 165.467L283.458 153.805C284.002 152.717 285.113 152.031 286.328 152.031H309.791C312.337 152.031 314.664 150.595 315.805 148.32L316.194 147.545L309.979 159.974C308.84 162.254 306.51 163.694 303.961 163.694Z"
              fill="black"
              fill-opacity="0.24"
            />
          </g>
          <g filter="url(#filter2_f_8_8468)">
            <path
              d="M303.961 164.412H280.497C279.282 164.412 278.171 165.099 277.627 166.186L283.458 154.523C284.002 153.436 285.113 152.749 286.328 152.749H309.791C312.337 152.749 314.664 151.313 315.805 149.038L316.194 148.264L309.979 160.693C308.84 162.973 306.51 164.412 303.961 164.412Z"
              fill="black"
              fill-opacity="0.32"
            />
          </g>
          <path
            d="M303.963 163.516H280.499C279.284 163.516 278.173 164.202 277.629 165.289L283.46 153.627C284.004 152.54 285.115 151.853 286.33 151.853H309.793C312.338 151.853 314.666 150.417 315.807 148.142L316.196 147.367L309.981 159.796C308.842 162.076 306.512 163.516 303.963 163.516Z"
            fill="url(#paint9_linear_8_8468)"
          />
          <path
            opacity="0.7"
            d="M303.951 163.515H280.517C279.301 163.515 278.19 164.202 277.646 165.289L283.478 153.626C284.022 152.539 285.133 151.852 286.348 151.852H309.917C312.382 151.852 314.637 150.464 315.748 148.264L309.966 159.801C308.825 162.078 306.497 163.515 303.951 163.515Z"
            fill="url(#paint10_linear_8_8468)"
          />
        </g>
        <rect
          x="361.037"
          y="119.072"
          width="80.5054"
          height="80.5054"
          rx="5"
          fill="#F4F4F4"
        />
        <g style={{"mix-blend-mode":"multiply"}} opacity="0.2">
          <path
            d="M392.124 172.018C391.594 172.023 391.081 172.202 390.663 172.528C390.245 172.853 389.945 173.306 389.809 173.818C389.674 174.33 389.71 174.873 389.912 175.362C390.114 175.852 390.471 176.262 390.928 176.529L399.47 181.371C400.173 181.771 400.969 181.981 401.778 181.98C402.213 181.98 402.646 181.917 403.064 181.792L415.914 178.13C416.872 177.855 417.715 177.276 418.316 176.48C418.917 175.684 419.242 174.714 419.244 173.717V172.018H392.124Z"
            fill="white"
          />
        </g>
        <g style={{"mix-blend-mode":"multiply"}} opacity="0.12">
          <path
            d="M392.124 172.018C391.594 172.023 391.081 172.202 390.663 172.528C390.245 172.853 389.945 173.306 389.809 173.818C389.674 174.33 389.71 174.873 389.912 175.362C390.114 175.852 390.471 176.262 390.928 176.529L399.47 181.371C400.173 181.771 400.969 181.981 401.778 181.98C402.213 181.98 402.646 181.917 403.064 181.792L415.914 178.13C416.872 177.855 417.715 177.276 418.316 176.48C418.917 175.684 419.242 174.714 419.244 173.717V172.018H392.124Z"
            fill="white"
          />
        </g>
        <path
          d="M403.012 136.678L406.463 144.573V172.017L403.064 181.791L415.914 178.13C416.873 177.854 417.716 177.275 418.316 176.479C418.917 175.683 419.243 174.713 419.245 173.716V144.753C419.245 143.753 418.92 142.781 418.317 141.983C417.715 141.185 416.868 140.605 415.907 140.332L403.012 136.678Z"
          fill="url(#paint11_linear_8_8468)"
        />
        <path
          d="M386.716 171.722L390.476 169.692C390.991 169.409 391.421 168.992 391.72 168.485C392.019 167.979 392.176 167.401 392.175 166.812V152.038C392.176 151.365 392.383 150.708 392.769 150.157C393.155 149.606 393.701 149.186 394.333 148.955L406.461 144.571V141.241C406.46 140.208 406.122 139.204 405.499 138.381C404.877 137.557 404.003 136.959 403.01 136.677C402.587 136.555 402.149 136.494 401.709 136.496C400.882 136.498 400.068 136.713 399.348 137.12L385.468 145.038C384.765 145.438 384.18 146.018 383.773 146.718C383.367 147.418 383.152 148.213 383.152 149.023V169.594C383.151 170.013 383.259 170.425 383.465 170.789C383.672 171.154 383.969 171.458 384.329 171.673C384.688 171.887 385.097 172.005 385.516 172.014C385.935 172.022 386.348 171.922 386.716 171.722Z"
          fill="url(#paint12_linear_8_8468)"
        />
        <path
          d="M406.462 172.018H392.124C391.594 172.023 391.081 172.202 390.663 172.528C390.245 172.853 389.945 173.306 389.809 173.818C389.674 174.33 389.71 174.873 389.912 175.362C390.114 175.852 390.471 176.262 390.928 176.529L399.47 181.371C400.173 181.771 400.969 181.981 401.778 181.98C402.213 181.98 402.646 181.917 403.064 181.792C404.045 181.513 404.907 180.922 405.521 180.107C406.134 179.293 406.465 178.3 406.462 177.281V172.018Z"
          fill="url(#paint13_linear_8_8468)"
        />
        <path
          d="M386.716 171.722L390.476 169.692C390.991 169.409 391.421 168.992 391.72 168.485C392.019 167.979 392.176 167.401 392.175 166.812V152.038C392.176 151.365 392.383 150.708 392.769 150.157C393.155 149.606 393.701 149.186 394.333 148.955L406.461 144.571V141.241C406.46 140.208 406.122 139.204 405.499 138.381C404.877 137.557 404.003 136.959 403.01 136.677C402.587 136.555 402.149 136.494 401.709 136.496C400.882 136.498 400.068 136.713 399.348 137.12L385.468 145.038C384.765 145.438 384.18 146.018 383.773 146.718C383.367 147.418 383.152 148.213 383.152 149.023V169.594C383.151 170.013 383.259 170.425 383.465 170.789C383.672 171.154 383.969 171.458 384.329 171.673C384.688 171.887 385.097 172.005 385.516 172.014C385.935 172.022 386.348 171.922 386.716 171.722Z"
          fill="url(#paint14_linear_8_8468)"
        />
        <path
          d="M406.462 172.018H392.124C391.594 172.023 391.081 172.202 390.663 172.528C390.245 172.853 389.945 173.306 389.809 173.818C389.674 174.33 389.71 174.873 389.912 175.362C390.114 175.852 390.471 176.262 390.928 176.529L399.47 181.371C400.173 181.771 400.969 181.981 401.778 181.98C402.213 181.98 402.646 181.917 403.064 181.792C404.045 181.513 404.907 180.922 405.521 180.107C406.134 179.293 406.465 178.3 406.462 177.281V172.018Z"
          fill="url(#paint15_linear_8_8468)"
        />
        <rect
          x="47.0664"
          y="62.7188"
          width="181.942"
          height="14.491"
          fill="#E7E7E7"
        />
        <rect
          x="47.0664"
          y="85.2598"
          width="101.437"
          height="14.491"
          fill="#F1F0F0"
        />
        <defs>
          <filter
            id="filter0_dd_8_8468"
            x="0.5"
            y="0"
            width="486"
            height="253.34"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="4"
              operator="erode"
              in="SourceAlpha"
              result="effect1_dropShadow_8_8468"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="12" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_8_8468"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="6"
              operator="erode"
              in="SourceAlpha"
              result="effect2_dropShadow_8_8468"
            />
            <feOffset dy="6" />
            <feGaussianBlur stdDeviation="6" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_8_8468"
              result="effect2_dropShadow_8_8468"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_8_8468"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_f_8_8468"
            x="277.028"
            y="146.946"
            width="39.7642"
            height="19.1196"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="0.299443"
              result="effect1_foregroundBlur_8_8468"
            />
          </filter>
          <filter
            id="filter2_f_8_8468"
            x="271.638"
            y="142.275"
            width="50.5441"
            height="29.8996"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="2.99443"
              result="effect1_foregroundBlur_8_8468"
            />
          </filter>
          <linearGradient
            id="paint0_linear_8_8468"
            x1="81.5599"
            y1="142.193"
            x2="69.18"
            y2="178.927"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#114A8B" />
            <stop offset="1" stop-color="#0669BC" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8_8468"
            x1="88.1516"
            y1="160.329"
            x2="85.4566"
            y2="161.244"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-opacity="0.3" />
            <stop offset="0.071" stop-opacity="0.2" />
            <stop offset="0.321" stop-opacity="0.1" />
            <stop offset="0.623" stop-opacity="0.05" />
            <stop offset="1" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_8_8468"
            x1="90.7312"
            y1="141.057"
            x2="104.245"
            y2="177.22"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3CCBF4" />
            <stop offset="1" stop-color="#2892DF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_8_8468"
            x1="186.39"
            y1="137.308"
            x2="194.846"
            y2="159.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0B53CE" />
            <stop offset="1" stop-color="#7252AA" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_8_8468"
            x1="195.962"
            y1="179.792"
            x2="195.962"
            y2="153.357"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2266E3" />
            <stop offset="1" stop-color="#AE7FE2" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_8_8468"
            x1="207.828"
            y1="166.368"
            x2="198.736"
            y2="166.368"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#94B9FF" />
            <stop offset="0.288" stop-color="#94B9FF" stop-opacity="0.5236" />
            <stop offset="1" stop-color="#538FFF" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_8_8468"
            x1="279.862"
            y1="163.516"
            x2="284.347"
            y2="180.562"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#159455" />
            <stop offset="1" stop-color="#3FBDA9" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_8_8468"
            x1="286.994"
            y1="139.975"
            x2="313.019"
            y2="151.545"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#23A794" />
            <stop offset="0.568447" stop-color="#007A84" />
            <stop offset="1" stop-color="#005158" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_8_8468"
            x1="309.019"
            y1="155.44"
            x2="304.309"
            y2="143.105"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#004A8B" />
            <stop
              offset="0.405563"
              stop-color="#105DA8"
              stop-opacity="0.500243"
            />
            <stop offset="1" stop-color="#2170C6" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_8_8468"
            x1="283.45"
            y1="153.647"
            x2="307"
            y2="165.31"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#7FD9A2" />
            <stop offset="0.196063" stop-color="#47BF79" />
            <stop offset="0.713946" stop-color="#009280" />
            <stop offset="1" stop-color="#007A84" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_8_8468"
            x1="283.226"
            y1="154.095"
            x2="288.609"
            y2="156.787"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#A8E47C" stop-opacity="0.86" />
            <stop offset="0.367474" stop-color="#87D152" stop-opacity="0.2" />
            <stop offset="1" stop-color="#58BE5A" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_8_8468"
            x1="411.125"
            y1="134.106"
            x2="411.125"
            y2="178.633"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFB900" />
            <stop offset="0.17" stop-color="#EF8400" />
            <stop offset="0.31" stop-color="#E25C01" />
            <stop offset="0.43" stop-color="#DB4401" />
            <stop offset="0.5" stop-color="#D83B01" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_8_8468"
            x1="403.093"
            y1="135.677"
            x2="379.513"
            y2="169.346"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#800600" />
            <stop offset="0.6" stop-color="#C72127" />
            <stop offset="0.73" stop-color="#C13959" />
            <stop offset="0.85" stop-color="#BC4B81" />
            <stop offset="0.94" stop-color="#B95799" />
            <stop offset="1" stop-color="#B85BA2" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_8_8468"
            x1="391.048"
            y1="177.003"
            x2="421.831"
            y2="177.003"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F32B44" />
            <stop offset="0.6" stop-color="#A4070A" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_8_8468"
            x1="403.574"
            y1="134.992"
            x2="398.581"
            y2="142.12"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-opacity="0.4" />
            <stop offset="1" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_8_8468"
            x1="411.966"
            y1="177.694"
            x2="398.184"
            y2="176.491"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-opacity="0.4" />
            <stop offset="1" stop-opacity="0" />
          </linearGradient>
          <clipPath id="clip0_8_8468">
            <rect
              width="43.0557"
              height="40.523"
              fill="white"
              transform="translate(65.582 139.199)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default SignUpInMid