import React from "react";
import "./HomepageCategoryCard.css";

import { useSelector } from "react-redux";


const HomepageCategoryCard = ({ data, handleCategoryRedirect, idx }) => {

  return (
    <div
      onClick={() => handleCategoryRedirect(data?.id, data?.category_name)}
      className="position-relative py-2 pb-4"
    >
      <div className="card px-3 py-4 pb-5d-flex flex-column justify-content-center align-items-center position-relative homapage-category-card shadow">
        <>
          <img
            src={data?.category_icon}
            style={{ width: "158px" }}
            className="mb-2"
            alt="category_icn"
          />
        </>

        <div className="category-title">{data?.category_name}</div>
        <div
          style={{ height: "96px" }}
          className="text-muted text-center mb-3 homepageCategoryCardDesc"
        >
          {data?.category_description?.length > 100
            ? data?.category_description?.slice(0, 50) + "..."
            : data?.category_description}
        </div>
        <div className="d-flex justify-content-center position-absolute categoryCard_link">
          <i
            className=" bi bi-arrow-up-right-circle-fill "
            style={{ zIndex: 1111 }}
            onClick={() =>
              handleCategoryRedirect(data?.id, data?.category_name)
            }
          ></i>
        </div>
      </div>
    </div>
  );
};

export default HomepageCategoryCard;
