const TriangleBurger = () => {
  return (
    <>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="#F2F2F2" />
        <path
          d="M25.5001 35.4997V32.9997H30.5001V35.4997H25.5001ZM20.5001 29.2497V26.7497H35.5001V29.2497H20.5001ZM16.7501 22.9997V20.4997H39.2501V22.9997H16.7501Z"
          fill="#1B449C"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.5001 35.4997V32.9997H30.5001V35.4997H25.5001ZM20.5001 29.2497V26.7497H35.5001V29.2497H20.5001ZM16.7501 22.9997V20.4997H39.2501V22.9997H16.7501Z"
          fill="#1B449C"
        />
      </svg>
    </>
  );
};

export default TriangleBurger;
