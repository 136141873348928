import React from 'react';
import { Link } from 'react-router-dom';
import  gonextpage from "../../assets/images/aboutus/gonextpage.svg"
import "./Breadcrumb.css"
import HomeBread from '../../assets/svgs/HomeBread';

const Breadcrumb = ({ title = "", crumbs }) => {
    return (
        <div className="main-container">
            <div className="row m-0">
                <div className="col-lg-6 p-0">
                    <div>
                        <Link to="/" className="td-none" >
                            {/* <i className="text-white bi bi-house-door-fill" /> */}
                            <HomeBread/>
                        </Link>
                        {
                            crumbs?.map(el => <>
                                <span className="text-white mx-2"><img className='gonextpage-img' src={gonextpage}/></span>
                                <Link to={el?.link ? el?.link : "#"} className="td-none" >
                                    <span className="text-white ">{el?.name}</span>
                                </Link>
                            </>)
                        }
                    </div>
                    <div className="bread-crumb-hd">
                        <h2 className="text-white ">{title}</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Breadcrumb;