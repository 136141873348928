import "./HomePage.css";
import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Header/logo.png";
import HomepageImg1 from "../../assets/images/Homepage/bg2.png";
import Categories from "../../components/Categories/Categories";
import OwlCarousel from "react-owl-carousel";
import OwlCarouselRTL from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import KeyImg1 from "../../assets/images/Homepage/keyImg1.svg";
import KeyImg2 from "../../assets/images/Homepage/key2.png";
import KeyImg3 from "../../assets/images/Homepage/key3.png";
import KeyImg4 from "../../assets/images/Homepage/key4.png";
import CourseCard from "../../components/CourseCard/CourseCard";
import HandsOnLab from "../../assets/images/Homepage/handsonlab.png";
import Challenge from "../../assets/images/Homepage/challenge.png";
import HomepageCategoryCard from "../../components/HomepageCategoryCard/HomepageCategoryCard";
import HomepageTestimonialCard from "../../components/HomepageTestimonialCard/HomepageTestimonialCard";
import english from "../../assets/svgs/Languages/english.svg";
import german from "../../assets/svgs/Languages/german.svg";
import portugese from "../../assets/svgs/Languages/portugese.svg";
import spanish from "../../assets/svgs/Languages/spanish.svg";
import japanese from "../../assets/svgs/Languages/japanese.svg";
import { getApiWithoutToken } from "../../api/api";
import { readCourseData } from "../../redux/slices/course/courseSliceActions";
import { readCourseCategoryData } from "../../redux/slices/courseCategory/courseCategoryAction";
import { readUserProfile } from "../../redux/slices/userProfile/userProfileActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PrimaryCategories from "../../components/PrimaryCategories/PrimaryCategories";
import { ToastContainer, toast } from "react-toastify";
import http from "../../services/axios/axiosInstance";
import HeaderProfile from "../../components/Header/HeaderProfile";
import arrowUp from "../../assets/images/Homepage/arrowUp.svg";
import next from "../../assets/svgs/next.svg";
import back from "../../assets/svgs/back.svg";
import { readTestimonialData } from "../../redux/slices/testimonial/testimonialActions";
import CourseCarousel from "../../components/CourseCarousel/CourseCarousel";
import PrevCarouselArrow from "../../assets/svgs/PrevCarouselArrow";
import NextCarouselArrow from "../../assets/svgs/NextCarouselArrow";
import ProgressBarCourse from "../../components/ProgressBar/ProgressBarCourse";
import cartIcon from "../../assets/images/Homepage/carticon.svg"
import { Accordion } from "react-bootstrap";
import ShopCart from "../../assets/svgs/ShopCartIc";
import { set } from "react-hook-form";
import { changeLanguage } from "../../redux/slices/language/languageSlice";
import { changeLanguageText } from "../../services/helper";
import { getDefaultLang } from "../../utils/helperFunction";
import TestimonialMobileCard from "../../components/HomepageTestimonialCard/TestimonialMobileCard";
import {readUpcommingCourse, readLatestCourse} from "../../redux/slices/course/courseSliceActions"
import {verifyIsPartner} from "../../redux/slices/authentication/authSliceActions"
import VerifyPartner from "../../hoc/VerifyPartner";
// import catebackbtn from "../../assets/images/catebackbtn.png"
import backBtn from "../../assets/images/backbtn.png"
import CategoryBackBtn from "../../assets/svgs/CategoryBackBtn";

function HomePage() {
  const dispatch = useDispatch();
  const courseData = useSelector((state) => state.courseState.course);
  const handsOnLabData = useSelector((state) => state.courseState.handsOnLab);
  const lastestCoure = useSelector((state) => state.courseState.latestCourse);
  const categories = useSelector(
    (state) => state.courseCategoryState.courseCategory
  );
  const userProfile = useSelector(
    (state) => state.userProfileState.userProfile
  );
  const testimonial = useSelector(
    (state) => state.testimonialState.testimonials
  );
  const upcommingCourses = useSelector((state) => state.courseState.upcommingCourse);
  const noCartCourses = useSelector((state) => state.courseCartState.noOfCourse);

  const [selectData, setSelectedData] = useState();
  const [carouselPos, setCarouselPos] = useState(0);
  const [testimonialCarPos, setTestimonialCarPos] = useState(70);

  const [upCourseCarouselPos, setUpCourseCarouselPos] = useState(0);
  const [courseProgress, setCourseProgress] = useState(8);

  const [upcomingCourseProgress, setUpcomingCourseProgress] = useState(8);


  const handleCarouselNext = () => {
    if (carouselPos > 72) {
      setCarouselPos(0);
      setCourseProgress(0);
    } else {
      setCarouselPos(carouselPos + 8);
      setCourseProgress(courseProgress + 8);
    }
  };

  const handleTestimonialCarNext = () => {
    if (testimonialCarPos > 72) {
      setTestimonialCarPos(0);
    } else {
      setTestimonialCarPos(testimonialCarPos + 8);
    }
  };

  const handleTestimonialCarPrev = () => {
    if (testimonialCarPos < 0) {
      setTestimonialCarPos(0);
    } else {
      setTestimonialCarPos(testimonialCarPos - 8);
    }
  };

  const handleCarouselPrev = () => {
    if (carouselPos < 0) {
      setCarouselPos(0);
      setCourseProgress(0);
    } else {
      setCarouselPos(carouselPos - 8);
      setCourseProgress(courseProgress - 8);
    }
  };

  const handleCarouseUpcourselNext = (event) => {
    if (upCourseCarouselPos > 72) {
      setUpCourseCarouselPos(0);
      setUpcomingCourseProgress(0);
    } else {
      setUpCourseCarouselPos(upCourseCarouselPos + 8);
      setUpcomingCourseProgress(upCourseCarouselPos + 8);
    }
  };
  const handleCarouselUpCoursePrev = () => {
    if (upCourseCarouselPos < 0) {
      setUpCourseCarouselPos(0);
      setUpcomingCourseProgress(0);
    } else {
      setUpCourseCarouselPos(upCourseCarouselPos - 8);
      setUpcomingCourseProgress(upCourseCarouselPos - 8);
    }
  };
  const [tabUnderline, setTabUnderline] = useState();

  const handleTabUnderLine = (data) => {
    setTabUnderline(data);
  };

  const handleCourseCategories = (courseCategoryObj) => {
    if (courseCategoryObj.courseType === "All Categories") {
      setCarouselPos(0);
      dispatch(readLatestCourse());
    } else {
      setCarouselPos(0);
      dispatch(
        readLatestCourse(
          courseCategoryObj.activeCategory.value
        )
      );
    }
  };

  function ValidateEmail(input) {
    var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  const handleEMailSubmit = async () => {
    try {
      const { email_address } = selectData;
      // console.log(ValidateEmail(email_address))
      if (!ValidateEmail(email_address)) {
        toast.error("Email Address not Valid", { autoClose: 3000 });
        return;
      }
      const payload = {
        email_address,
      };
      await http.post(`email`, payload);
      toast.success("Submission Done", { autoClose: 3000 });
      setSelectedData({ email_address: "" });
      // console.log(selectData)
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!", { autoClose: 3000 });
    }
  };

  const [activeLanguage, setActiveLanguage] = useState(getDefaultLang());

  const handleLanguage = (data) => {
    // console.log(typeof data, data, 444444444444)
    setActiveLanguage(data)
    localStorage.setItem("technoLang", data)
    dispatch(changeLanguage(data))
  }

  const [pageData, setPageData] = useState({})
  const getTranslatedData = async () => {
    setPageData({
      signIn: await changeLanguageText("Sign In", activeLanguage),
      signUp: await changeLanguageText("Sign Up", activeLanguage),
      home: await changeLanguageText("Home", activeLanguage),
      about: await changeLanguageText(" About Us", activeLanguage),
      ourServices: await changeLanguageText("Our Services", activeLanguage),
      promotions: await changeLanguageText("Promotions", activeLanguage),
      partners: await changeLanguageText("Partners", activeLanguage),
      contactUs: await changeLanguageText("Contact Us", activeLanguage),
      achieveMore1: await changeLanguageText("Get a step ahead with", activeLanguage),
      achieveMore3: await changeLanguageText("Microsoft cloud technologies", activeLanguage),
      achieveMore2: await changeLanguageText("Get ready to conquer the world of Microsoft cloud. Gain expertise in the latest Microsoft cloud technology to optimise not only your work productivity but also your organisation’s efficiency. ", activeLanguage),
      learnMore: await changeLanguageText("Learn More", activeLanguage),
      latestCourse: await changeLanguageText("Latest Courses", activeLanguage),
      upcommingCourse: await changeLanguageText("Upcoming Courses", activeLanguage),
      notFound: await changeLanguageText("Data Not Found", activeLanguage),
      highlights: await changeLanguageText("Key Highlights", activeLanguage),
      cardTitle1: await changeLanguageText("Build a center of excellence in Microsoft cloud", activeLanguage),
      cardTitle2: await changeLanguageText("Make tech training of new hires hassle-free", activeLanguage),
      cardTitle3: await changeLanguageText("Sales and pre-sales enablement", activeLanguage),
      cardTitle4: await changeLanguageText("Learn in the language of your choice", activeLanguage),
      cardDesc1: await changeLanguageText("With our guidance, you can build a center of excellence around Microsoft technology practice and help drive adoption.", activeLanguage),
      cardDesc2: await changeLanguageText("Optimise your turnaround time by quickly getting your new hires up to speed on the right technology.", activeLanguage),
      cardDesc3: await changeLanguageText("Reduce ramp-up time and increase your revenue growth. Empower your sales and pre-sales teams to achieve more with our sales enablement programs.", activeLanguage),
      cardDesc4: await changeLanguageText(" We ensure that our courses reach you in your native language for a better understanding of critical concepts.", activeLanguage),
      wellEquiped: await changeLanguageText("WELL EQUIPPED", activeLanguage),
      handsOnlab: await changeLanguageText("Hands On Labs", activeLanguage),
      handsText: await changeLanguageText("Hands On Labs are virtual labs curated to enable your learning process. As a part of our training programs, Hands On Labs provide you with much-needed firsthand experience in Microsoft cloud technologies in a virtual environment. Get step-by-step instructions and understand the key concepts to the core with practical training.", activeLanguage),
      Competitive: await changeLanguageText("COMPETITIVE", activeLanguage),
      challngeExper: await changeLanguageText("Challenge Experience", activeLanguage),
      challengeDesc: await changeLanguageText("With Challenge Experiences you get to practice on actual problem cases and evaluate your progress. We allow you to apply what you have learned and assess how far you have advanced. With a top-down approach to learning, Challenge Experience is the perfect platform for professionals to push the boundaries of skill acquisition.", activeLanguage),
      Category: await changeLanguageText("Category", activeLanguage),
      categDesc: await changeLanguageText("Your one-stop training partner for all Microsoft cloud technologies", activeLanguage),
      training: await changeLanguageText("Our training programs help you be at par with the latest in Microsoft cloud technologies.", activeLanguage),
      testimonials: await changeLanguageText("Testimonials", activeLanguage),
      professors: await changeLanguageText(" What Professionals Say About Us", activeLanguage),
      lorem: await changeLanguageText("Our mentees who benefited from our training programs have commended us with their kind words ", activeLanguage),
      professors1: await changeLanguageText(" What Professionals ", activeLanguage),
      professors2: await changeLanguageText("  Say About Us", activeLanguage),
      newsletter1: await changeLanguageText("Subscribe to our Newsletter", activeLanguage),
      newsletter2: await changeLanguageText("Stay in the loop with everything you need to know.", activeLanguage),
      enteremail: await changeLanguageText("Enter your email", activeLanguage),
      submitemail: await changeLanguageText("Submit", activeLanguage),
      choose: await changeLanguageText(" Choose a language", activeLanguage),
      suggested: await changeLanguageText("Suggested language", activeLanguage),



    })
  }
  const navigate = useNavigate();
  const handleCategoryRedirectCourses = (categoryId, categoryName) => {
    console.log(categoryName)
    if (!categoryId || !categoryName) {
      navigate(`/our-services/cloud-training/course`);
    } else {
      navigate(
        `/our-services/cloud-training/course?categoryId=${categoryId}&categoryName=${categoryName?.toString()}`
      );
    }
  }

  useEffect(() => {
    getTranslatedData()
    // window.scrollTo({ top: 0, behavior: "smooth" })
  }, [activeLanguage]);


  useEffect(() => {
    dispatch(readCourseData("Hands_On_Lab"));
    dispatch(readCourseData("course"));
    dispatch(readUserProfile());
    dispatch(readTestimonialData());
    dispatch(readUpcommingCourse())
    handleLanguage(getDefaultLang())
    dispatch(readLatestCourse())
    dispatch(verifyIsPartner())
    // dispatch(changeLanguage())
  }, [])

  return (
    <>
      <div className="homepage_section1">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="my-2 my-lg-0 w-100 nav-con">
              <ul style={{justifyContent:"center"}} className="navbar-nav d-flex nav-links-con">
                <li className="nav-item active nav-link-item">
                  <a
                    className="nav-link text-white mx-2 underline-navbar-tab "
                    href="/"
                  >
                    {pageData?.home}
                  </a>
                </li>
                <li className="nav-item active nav-link-item">
                  <a className="nav-link text-white mx-2" href="about-us">
                    {pageData?.about}
                  </a>
                </li>
                <li className="nav-item active nav-link-item">
                  <a className="nav-link text-white mx-2" href="our-services">
                    {pageData?.ourServices}
                  </a>
                </li>
                <li className="nav-item active nav-link-item">
                  <a className="nav-link text-white mx-2" href="promotion">
                    {pageData?.promotions}
                  </a>
                </li>
                <VerifyPartner>
                <li className="nav-item active nav-link-item">
                  <a className="nav-link text-white mx-2" href="partners">
                    {pageData?.partners}
                  </a>
                </li>
                </VerifyPartner>
                <li className="nav-item active nav-link-item">
                  <a className="nav-link text-white mx-2" href="contact-us">
                    {pageData?.contactUs}
                  </a>
                </li>
                <li className="nav-item active d-flex nav-link-item">
                  <a
                    className="nav-link text-white mx-2 d-flex"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="bi bi-globe"></i>
                  </a>
                  <a
                    className="nav-link text-white mx-2 d-flex"
                    href="/cart"

                  >
                   <div>
                   <ShopCart />
                   <div className="badge">{noCartCourses}</div>
                   </div>
                  </a>
                </li>
                {/* <li className="nav-item active ">
                  <a className="nav-link" href="cart">
                    <img className="cart-icon-homapge" src={cartIcon} />
                  </a>
                </li> */}
              </ul>
              {/* for desktop view */}
              <ul style={{width:"fit-content"}} className="navbar-nav home-nav   ">
                {Object.keys(userProfile)?.length > 0 ? (
                  <HeaderProfile userProfileName={userProfile.user_name} />
                ) : (
                  <>
                    {/* <li className="nav-item active">
                      <a
                        className="nav-link signin-button-cra w-100 px-4"
                        href="/signin"
                      >
                        {pageData?.signIn}
                      </a>
                    </li> */}
                    <li style={{width:"129px"}} className="nav-item active">
                      <a
                        className="nav-link signup-button-cra w-100 px-4"
                        href="/signup"
                      >
                        {pageData?.signUp}
                      </a>
                    </li>
                  </>
                )}
              </ul>

              {/* for mobile view */}
              <ul className="navbar-nav home-nav1   ">
                {Object.keys(userProfile)?.length > 0 ? (
                  <HeaderProfile userProfileName={userProfile.user_name} />
                ) : (
                  <>
                    <li className="nav-item active">
                      <a
                        className="nav-link signup-button-cra w-100  "
                        href="/signin"
                      >
                        {pageData?.signIn}
                      </a>
                    </li>
                    <li className="nav-item active">
                      <a
                        className="nav-link signup-button-cra w-100  "
                        href="/signup"
                      >
                        {pageData?.signUp}
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>

        <div className="homepage_section1_main container-fluid row  ">
          <div className="homepage_section1_conatiner pe-5 col-lg-6 p-0 d-flex flex-column">
            <p className="homepage_section1_heading">
              {pageData?.achieveMore1}
              <br />
              {pageData?.achieveMore3}
            </p>
            <p className="homepage_section1_para mt-3">
              {pageData?.achieveMore2}
            </p>
            <div onClick={() =>navigate("/our-services/cloud-training")} className="homepage_learnMore2">
              <p  className="leran-more-p p-0">{pageData?.learnMore}</p>
              <img src={arrowUp} />
            </div>
          </div>
          <div className="homepage_section1_conatiner2 col-lg-6">
            <img src={HomepageImg1} className="" />
          </div>
        </div>
      </div>

      <div className="homepage_section2 homepage_section-for-top">
        <div className="hd-coro-btn">
          <p className="h2">{pageData?.latestCourse}</p>
          <div className="carousel-nav-btns">
            <div onClick={handleCarouselPrev} className="carousel-btn-prev">
              <PrevCarouselArrow />
            </div>
            <div onClick={handleCarouselNext} className="carousel-btn-next">
              <NextCarouselArrow />
            </div>
          </div>
        </div>

        <PrimaryCategories
          handleCourseCategories={handleCourseCategories}
          courseType="course"
        />

        <CourseCarousel corPosProp={carouselPos}>
          {lastestCoure.length > 0 ? (
            lastestCoure?.map((el, idx) => {
              return (
                <CourseCard
                  index={idx + 1}
                  cardData={el}
                  style={{
                    minHeight: "370px",
                    width: "298px",
                    marginRight: "20px",
                  }}
                />
              );
            })
          ) : (
            <div>{pageData?.notFound}</div>
          )}
        </CourseCarousel>
        <ProgressBarCourse now={courseProgress} />
      </div>

      <div className="homepage_section2">
        <div className="hd-coro-btn">
          <p className="h2">{pageData?.upcommingCourse}</p>
          <div className="carousel-nav-btns">
            <div
              onClick={handleCarouselUpCoursePrev}
              className="carousel-btn-prev"
            >
              <PrevCarouselArrow />
            </div>
            <div
              onClick={handleCarouseUpcourselNext}
              className="carousel-btn-next"
            >
              <NextCarouselArrow />
            </div>
          </div>
        </div>

        <CourseCarousel corPosProp={upCourseCarouselPos}>
          {upcommingCourses.length > 0 ? (
            upcommingCourses?.map((el, idx) => {
              return (
                <CourseCard
                  index={idx + 1}
                  cardData={el}
                  courseState="upcomming"
                  style={{
                    minHeight: "370px",
                    width: "298px",
                    marginRight: "20px",
                  }}
                />
              );
            })
          ) : (
            <div>{pageData?.notFound}</div>
          )}
        </CourseCarousel>
        <ProgressBarCourse now={upcomingCourseProgress} />
      </div>

      <div className="homepage_section2 homepage_section3 mt-5">
        <div className="d-flex justify-content-center mt-2 mb-4 ">
          <p className="h2">{pageData?.highlights}</p>
        </div>
        <div className="row homepage_section3_bg1">
          <div className="col-6 section3Card col-md-3 p-3">
            <div className="card p-4">
              <img className="keyImg keyImg1" src={KeyImg1} />
              <p className=" card_title mt-3">
                {pageData?.cardTitle1}
              </p>
              <p className="course-description">
                {pageData?.cardDesc1}
              </p>
            </div>
          </div>
          <div className="col-6 section3Card col-md-3 p-3">
            <div className="card p-4 p-0">
              <img className="keyImg keyImg2" src={KeyImg2} />
              <p className=" card_title mt-3">
                {pageData?.cardTitle2}

              </p>
              <p className="course-description">
                {pageData?.cardDesc2}
              </p>
            </div>
          </div>
          <div className="col-6 section3Card col-md-3 p-3 ">
            <div className="card p-4">
              <img className="keyImg keyImg3" src={KeyImg3} />
              <p className=" card_title mt-3">  {pageData?.cardTitle3}</p>
              <p className="course-description">
                {pageData?.cardDesc3}              </p>
            </div>
          </div>
          <div className="col-6 section3Card col-md-3 p-3">
            <div className="card p-4">
              <img className="keyImg keyImg4" src={KeyImg4} />
              <p className=" card_title mt-3">
                {pageData?.cardTitle4}
              </p>
              <p className="course-description ">
                {pageData?.cardDesc4}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="homepage_section2">
        <div className="row">
          <div className="col-md-6 d-md-flex justify-content-end p-4 m-auto">
            <div className="d-flex flex-column justify-content-center w-75">
              <div className="color-blue text-uppercase">{pageData?.wellEquiped}</div>
              <h3>{pageData?.handsOnlab}</h3>
              <div className="text-muted">
                {pageData?.handsText}
              </div>
              <Link
                className="hands-on-labslinks"
                to="/our-services/hands-on-labs"
              >
                <div className="homepage_learnMore1">
                  <p className=" leran-more-p p-0">{pageData?.learnMore}</p>
                  <img src={arrowUp} />
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-6 p-4 m-auto">
            <div className="d-flex justify-content-center">
              <img src={HandsOnLab} className="w-75 h-75" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 d-flex d-md-none p-4 m-auto">
            <div className="d-flex flex-column justify-content-center w-75">
              <div className="color-blue text-uppercase">{pageData?.Competitive}</div>
              <h3>{pageData?.challngeExper}</h3>
              <div className="text-muted">
                {pageData?.challengeDesc}
              </div>
              <Link
                to="/our-services/challenge-experience"
                className="hands-on-labslinks"
              >
                <div className="homepage_learnMore1">
                  <p className=" leran-more-p p-0">{pageData?.learnMore}</p>
                  <img src={arrowUp} />
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-6 p-4 m-auto">
            <div className="d-flex justify-content-center">
              <img src={Challenge} className="w-75 h-75" />
            </div>
          </div>
          <div className="col-md-6 d-none d-md-flex p-4 m-auto">
            <div className="d-flex flex-column justify-content-center w-75">
              <div className="color-blue text-uppercase">{pageData?.Competitive}</div>
              <h3>{pageData?.challngeExper}</h3>
              <div className="text-muted">  {pageData?.challengeDesc}
              </div>
              <Link
                to="/our-services/challenge-experience"
                className="hands-on-labslinks"
              >
                <div className="homepage_learnMore1">
                  <p className=" leran-more-p p-0">{pageData?.learnMore}</p>
                  <img src={arrowUp} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light m-0">
        <div className="row m-0 ">
          <div className="col-md-3 d-flex flex-column align-items-center py-5">
            <div className="d-flex flex-column justify-content-center px-4">
              <div className="color-blue text-uppercase font-weight-bold mb-2">
                {/* {pageData?.Category} */}
              </div>
              <h3 className="mb-3">
                {pageData?.categDesc}
              </h3>
              <div className="text-muted">
                {pageData?.training}
              </div>
              {/* <img className="back-back-button mt-5" src={back} onClick={"-"} /> */}
            </div>
          </div>
          <div className="col-md-9">
            <div className="row h-100 position-relative section_bg_3 ps-5">
              {categories?.length ? (
                <OwlCarousel
                  className="py-5 px-0"
                  loop={false}
                  margin={20}
                  items={4}
                  autoplay={true}
                  nav={true}
                  navText={[`<i class="bi bi-arrow-left back-back-buttons2"></i>`]}
                  dots={false}
                  responsive={{
                    0: { items: 1.1 },
                    500: { items: 1.5 },
                    800: { items: 2 },
                    992: { items: 2.9 },
                  }}
                >
                  {categories?.map((el) => (
                    <HomepageCategoryCard data={el} handleCategoryRedirect={handleCategoryRedirectCourses} />

                  ))}
                </OwlCarousel>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {/* for desktop versio */}
      <div className="row m-0 py-5 testimoial-ection-md-hide">
        <div className="row testimonial-bg m-0 p-0">
          <div className="col-md-6 p-5 d-md-none">
            <div className="d-flex flex-column justify-content-center h-100 align-items-center testemonialCard">
              <h6 className="w-100 d-flex justify-content-center color-blue text-uppercase font-weight-bold mb-2">
                {pageData?.testimonials}
              </h6>
              <h3 className="w-100 pb-5 d-flex justify-content-center ">
                {pageData?.professors}
              </h3>
              <div className="text-muted d-flex justify-content-center text-center">
                {pageData?.lorem}
              </div>
            </div>
          </div>
          <div className="col-md-6 ps-0 pe-5 ">

            <CourseCarousel corPosProp={testimonialCarPos}  >
              {testimonial?.length > 0 &&
                testimonial.map((item) => (
                  <HomepageTestimonialCard testimonialData={item} />
                ))}
            </CourseCarousel>
          </div>


          <div className="col-md-6 p-5 ">
            <div className="d-none d-md-flex flex-column justify-content-center h-100 w-75 align-items-center testemonialCard">
              <h6 className="w-100 color-blue text-uppercase font-weight-bold mb-2">
                {/* {pageData?.testimonials} */}
              </h6>
              <h3 className="w-100">
                {pageData?.professors1} <br />
                {pageData?.professors2}
              </h3>
              <div className="text-muted">
                {pageData?.lorem}
              </div>
              <div className="backandnextbuttons ">
                <img
                  className="back-back-button me-4"
                  src={back}
                  onClick={handleTestimonialCarPrev}
                />
                <img
                  className="next-back-button"
                  src={next}
                  onClick={handleTestimonialCarNext}
                />
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* for mobile version */}
      <div className="row m-0 testimoial-ection-lg-hide">
        <div className="row m-0 p-0">
          <div className="col-md-6">
            <div  >
              <h6 className="w-100 d-flex justify-content-center color-blue text-uppercase font-weight-bold mb-2">
                {pageData?.testimonials}
              </h6>
              <h3 className="w-100 py-3 d-flex justify-content-center font-weight-bold  ">
                {pageData?.professors1} <br />
                {pageData?.professors2}
              </h3>
              <div className="text-muted d-flex justify-content-center text-center w-80">
                {pageData?.lorem}
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center text-center mt-5">

            {testimonial?.length ? (
              <OwlCarousel
                className='owl-theme '
                lassName="py-5 px-0"
                loop={true}
                margin={20}
                items={2}
                autoplay={true}
                nav={true}
                navText={false}
                dots={false}
                responsive={{
                  0: { items: 1.2 },
                  499: { items:2.5 }
                }}

              >
                {testimonial?.length > 0 &&
                  testimonial.map((item) => (
                    <TestimonialMobileCard testimonialData={item} />
                  ))}
              </OwlCarousel>)
              : (
                ""
              )
            }

          </div>



          <div className="col-md-6 p-5 ">
            <div className="d-none d-md-flex flex-column justify-content-center h-100 w-75 align-items-center testemonialCard">
              <h6 className="w-100 color-blue text-uppercase font-weight-bold mb-2">
                {pageData?.testimonials}
              </h6>
              <h3 className="w-100">
                {pageData?.professors1} <br />
                {pageData?.professors2}
              </h3>
              <div className="text-muted">
                {pageData?.lorem}
              </div>
              <div className="backandnextbuttons ">
                <img
                  className="back-back-button me-4"
                  src={back}
                  onClick={handleTestimonialCarPrev}
                />
                <img
                  className="next-back-button"
                  src={next}
                  onClick={handleTestimonialCarNext}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
         
      {/* <div className="bg-light p-3">
        <div className="d-flex flex-column align-items-center justify-content-center text-center">
          <div className="faqBox">
            <h2 className="lorem-heading">Frequently Asked Questions</h2>
            <p className="text-muted lorem-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>

            <Accordion>
              <Accordion.Item className="mt-4 " eventKey="0">
                <Accordion.Header className="accodian-items-inside"  >Lorem ipsum dolor sit amet, consectetur adipiscing elit. ?</Accordion.Header>
                <Accordion.Body className="desc-accordian">{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."}</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mt-4" eventKey="1">
                <Accordion.Header className="accodian-items-inside">{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. ?"}</Accordion.Header>
                <Accordion.Body className="desc-accordian">{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."}</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mt-4" eventKey="2">
                <Accordion.Header className="accodian-items-inside">{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. ?"}</Accordion.Header>
                <Accordion.Body className="desc-accordian">{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."}</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mt-4 mb-5" eventKey="3">
                <Accordion.Header className="accodian-items-inside">{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. ?"}</Accordion.Header>
                <Accordion.Body className="desc-accordian">{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."}</Accordion.Body>
              </Accordion.Item>
            </Accordion>


          </div>
        </div>
      </div> */}

      <div className="homepage_section2 newsletterBg">
        <div className="row">
          <div className="col-lg-6 p-3 p-lg-5 signup-newslettre-mb-box">
            <div className="row ">
              <h2 className="signup-newsletter d-flex d-lg-block justify-content-center">
                {pageData?.newsletter1}
              </h2>
              <p className="stay-lopp d-flex d-lg-block text-center text-lg-start justify-content-center">
                {pageData?.newsletter2}
              </p>
            </div>
          </div>
          <div className="col-lg-6 p-3 p-lg-5 d-flex justify-content-center align-items-center">
            <div className="row w-100 d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-between bg-white w-75 newsletterInputBox">
                <input
                  type="email"
                  className="ms-2 w-75 color-blue py-1 newsletterInput"
                  placeholder={pageData?.enteremail}
                  value={selectData?.email_address}
                  onChange={(e) =>
                    setSelectedData({
                      ...selectData,
                      email_address: e.target.value,
                    })
                  }
                />
                <button className="newsletterSubmit px-3 pe-2 t-12 d-flex align-items-center justify-content-center">
                  <span
                    className="submitText text-white"
                    onClick={handleEMailSubmit}
                  >
                    {pageData?.submitemail}
                  </span>
                  <i className="submitTextIcon text-white px-2 bi bi-arrow-up-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog ">
          <div className="modal-content tab-english-popup">
            <div class="modal-header  ">
              <div className="px-4">
                <h1
                  class="modal-title language-font-size pb-4"
                  id="exampleModalLabel"
                >
                  {pageData?.suggested}
                </h1>
                <div className=" w-50 p-2 background-white-boxe">
                  <img className="pr-2" src={english} />
                  <span>English</span>
                </div>
              </div>

              <button
                type="button"
                class="btn-close pr-5"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body pb-5">
              <h1
                class="modal-title language-font-size pb-4 px-4"
                id="exampleModalLabel"
              >
                {pageData?.choose}
              </h1>

              <div className="row mx-2   pb-3 ">
                <div className={`${activeLanguage === "en" ? "back-blue-language" : ""} col-md-2 background-white-boxes mr-3`} onClick={() => handleLanguage("en")}>
                  <img className="pr-2" src={english} />
                  <span>English</span>
                </div>
                <div className={`${activeLanguage === "ru" ? "back-blue-language" : ""} col-md-2 background-white-boxes mr-3`} onClick={() => handleLanguage("ru")}>
                  <img className="pr-2" src={japanese} /> <span>Russian</span>
                </div>
                <div className={`${activeLanguage === "es" ? "back-blue-language" : ""} col-md-2 background-white-boxes mr-3`} onClick={() => handleLanguage("es")}>
                  <img className="pr-2" src={spanish} /> <span>Spanish</span>
                </div>
                <div className={`${activeLanguage === "fr" ? "back-blue-language" : ""} col-md-2 background-white-boxes mr-3`} onClick={() => handleLanguage("fr")}>
                  <img className="pr-2" src={portugese} /> <span>French</span>
                </div>
                <div className={`${activeLanguage === "de" ? "back-blue-language" : ""} col-md-2 background-white-boxes mr-3`} onClick={() => handleLanguage("de")}>
                  <img className="pr-2" src={german} /> <span>German</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal end here */}
    </>
  );
}

export default HomePage;
