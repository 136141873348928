import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingApi: "idle",
  apiError: "",
  isAuth: false,
  isPartner: false
};

const authentication = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    handleLoding: (state, { payload }) => {
      state.loadingApi = payload;
    },
    handleApiError: (state, {payload}) => {
        state.apiError = payload
    },
    handleIsAuth: (state, {payload}) => {
      state.isAuth = payload
    },
    handleIsPartner: (state, {payload}) => {
      state.isPartner = payload
    }
  },
});

export const {
  handleLoding,
  handleApiError,
  handleIsAuth,
  handleIsPartner
} = authentication.actions;
export default authentication.reducer;
