import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import http from "../services/axios/axiosInstance";
import { apiUrls } from "../api/apiUrls";
import useLocalStorge from "../hooks/useLocalStorage";
import { getBaseUrl } from "../services/axios/axiosInstance";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState } from "react";

const PaymentGateway = () => {
  const localStorageData = useLocalStorge();
  const initialOptions = {
    "client-id":
      "AbEgxoBifJ703Acxf12EOLrwmS8CBednpMmAsdsp8WLKRS6NKDfNesJ_TpJCOFKi2JgREqeago0McoUp",
    currency: "USD",
    intent: "capture",
  };


  const createOrders = async (data, actions) => {
    let cartData = localStorage.getItem("technoCart");
    let localStorageData = JSON.parse(localStorage.getItem("techcsrf"));
    let accessToken = localStorageData.accessToken;
    let couponCode = localStorage.getItem("couponCode");
    if (!cartData) {
      toast.error("Your Cart Is Empty");
      return;
    }
    let parsedCartData = JSON.parse(cartData);
    let apiData = {
      cartData: parsedCartData,
      couponCode,
    };
    const config = {
      method: "post",
      url: `${apiUrls.paypalOrders}`,
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      data: apiData,
    };
    const response = await http(config);
    console.log(response?.data);

    return response?.data?.id;
  };
  const onApproves = async (data, actions) => {
    console.log(data);
    const response = await fetch(
      `${getBaseUrl()}orders/${data.orderID}/capture`,
      {
        method: "POST",
      }
    );
    const details = await response.json();
    console.log(details);

    const errorDetail = Array.isArray(details.details) && details.details[0];
    if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
      return actions.restart();
    }

    if (errorDetail) {
      let msg = "Sorry, your transaction could not be processed.";
      if (errorDetail.description) msg += "" + errorDetail.description;
      if (details.debug_id) msg += " (" + details.debug_id + ")";
      return alert(msg);
    }

    console.log("Capture result", details, JSON.stringify(details, null, 2));
    const transaction = details.purchase_units[0].payments.captures[0];
    alert(
      "Transaction " +
        transaction.status +
        ": " +
        transaction.id +
        "See console for all available details"
    );
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        createOrder={(data, actions) => createOrders(data, actions)}
        onApprove={(data, actions) => onApproves(data, actions)}
      />
    </PayPalScriptProvider>
  );
};

export default PaymentGateway;


