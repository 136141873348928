import React from 'react';
import "./CloudTrainingCard.css"
import CloudTrainingCardBg from "../../assets/images/CloudTraining/CloudTrainingCardBg.png"
import challengeLogo from "../../assets/images/OurServices/challengeLogo.png"
import { Link } from 'react-router-dom';

const CloudTrainingCard = ({cardData}) => {    
    return (
        <div className="card border-15p shadow-sm h-100 d-flex flex-column mb-4 position-relative">
            <img className="w-100" src={CloudTrainingCardBg} alt="Cardimagecap" />
            <div className='p-4 position-relative'>
                <div className="CloudTrainingCardImg shadow-sm   bg-white position-absolute d-flex border justify-content-center align-items-center" >
                    <img  src={cardData.category_icon} alt="Cardimagecap" />
                </div>
                <h3 className="mt-4">{cardData.category_name}</h3>
                <p className="text-muted  mt-2">{cardData.category_description}</p>
            </div>
            
            <Link to="/our-services/cloud-training/:id" className="CloudTrainingCardLink position-absolute py-2 px-1">
                <span className="text-white "><i className="service_btn_icon bi bi-arrow-up-right mx-2" /></span>
            </Link>
            
        </div>
    );
};

export default CloudTrainingCard;