const MicrosoftLogo = () => {
  return (
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_8_8565)">
          <path d="M35.4062 35.4062H24V24H35.4062V35.4062Z" fill="#F1511B" />
          <path
            d="M47.9998 35.4062H36.5938V24H47.9998V35.4062Z"
            fill="#80CC28"
          />
          <path
            d="M35.4059 47.9999H24V36.5938H35.4059V47.9999Z"
            fill="#00ADEF"
          />
          <path
            d="M47.9998 47.9999H36.5938V36.5938H47.9998V47.9999Z"
            fill="#FBBC09"
          />
        </g>
        <rect
          x="0.8"
          y="0.8"
          width="70.4"
          height="70.4"
          rx="7.2"
          stroke="#D6D6D6"
          stroke-width="1.6"
        />
        <defs>
          <clipPath id="clip0_8_8565">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(24 24)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default MicrosoftLogo;
