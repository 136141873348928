import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  couponCode: "",
  isValid: false,
  apiErr: "",
  loading: "idle",
  couponInfo: {},
};

const coupon = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    handleCouponCode: (state, { payload }) => {
      state.couponCode = payload;
    },
    handleCouponCodeIsValid: (state, { payload }) => {
      state.isValid = payload;
    },
    handleLoading: (state, { payload }) => {
      state.loading = payload;
    },
    handleCouponInfo: (state, { payload }) => {
      state.couponInfo = payload;
    },
  },
});

export const {
  handleCouponCode,
  handleCouponCodeIsValid,
  handleLoading,
  handleCouponInfo,
} = coupon.actions;

export default coupon.reducer;
