export const apiUrls = {
  readCourseData: "course",
  readCourseCategoryData: "categories/course-category",
  manualSignUp: "auth/signup",
  manualSignIn: "auth/signin",
  verifyClientAccessToken: "auth/verifyClientAccessToken",
  verifyPartnersAccessToken: "/auth/verifyPartnersAccessToken",
  readUserProfile: "user-profile",
  readTestimonialData: "testimonial",
  courseCart: "cart",
  destroyCourseCart: "cart/deleteCourse",
  readPartnerCourse: "partnerCourse/partnerCourseApplication",
  partnerCourseApplication: "partnerCourseApplication",
  partnerCourse: "partnerCourse",
  paypalOrders: "orders",
  trainingCalender: "trainingCalender",
  latestCourse: "latestCourse",
  upcommingCourse: "upcommingCourse",
  readParticularCourse: "readCourse",
  verifyEnrolledCourse: "verifyEnrolledCourse",
  verifyCouponCode: "verifyCouponCode",
  resetUserPassword: "technofocus/resetUserPassword",
  forgetUserPassword: "technofocus/forgetUserPassword",
  imageUploadProfile: "azureProfileImageUpload"
};
